import { useTranslation } from 'react-i18next';
import { SortEndHandler } from 'react-sortable-hoc';

import { useAppDispatch } from '../../../../app/hooks';
import {
  ITcmsItemActionsProps,
  LanguageController,
  Loading,
  Modal,
  ModalButton,
  TcmsButton,
  TcmsItemActions,
} from '../../../../components';
import { setDataSettingsSelectedMetrics } from '../../../../features';
import { useWindowSize } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IDataset,
  IDatasetMetric,
  IEntity,
  ILanguageType,
  ISelectOptionWithParam,
  ITcmsItemProps,
} from '../../../../types';
import { createClassNames, moveArrayItem, slugify } from '../../../../utils';
import './DefinedTemplatesPage.styles.scss';
import {
  DefinedTemplatesCheckboxes,
  DefinedTemplatesDataTypes,
  DefinedTemplatesSection,
  EditTemplateModal,
} from './components';
import { useDefinedTemplatesEffects } from './hooks';
import { mapDatasetGroupOptions, transformSelectedMetricsToSelectOptions } from './utils';

const classNames = createClassNames('defined-templates-page');

export const DefinedTemplatesPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    activeLanguage,
    activeSection,
    activeTemplate,
    addTemplate,
    datasetGroupOptions,
    definedDatasets,
    definedTemplatesSectionKeys,
    filteredBySectionedSubgroups,
    filteredMetricsOptions,
    sectionTemplateOptions,
    sectionDataset,
    metrics,
    metricsBySection,
    itemToDelete,
    itemToEdit,
    selectedMetrics,
    setActiveLanguage,
    setActiveSection,
    setActiveTemplate,
    setAddTemplate,
    setItemToDelete,
    setItemToEdit,
    setSectionTemplateOptions,
    setDatasetGroupOptions,
  } = useDefinedTemplatesEffects();
  const { width } = useWindowSize();

  if (!activeSection || !definedDatasets || !sectionDataset || !metricsBySection) {
    return <Loading />;
  }

  const handleTcmsItemClick = (option: ITcmsItemProps) => {
    setActiveTemplate(option);
    setDatasetGroupOptions(
      mapDatasetGroupOptions(activeLanguage, activeSection, definedDatasets, metrics),
    );

    const selectedMetrics = transformSelectedMetricsToSelectOptions(
      definedDatasets,
      metricsBySection,
      activeSection,
      option,
    );
    dispatch(setDataSettingsSelectedMetrics(selectedMetrics));
  };

  const handleOnSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newArray = [...sectionTemplateOptions];
      moveArrayItem(newArray, oldIndex, newIndex);
      setSectionTemplateOptions(newArray);
    }
  };

  const handleDatasetListChange: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newArray = [...datasetGroupOptions];
      moveArrayItem(newArray, oldIndex, newIndex);
      setDatasetGroupOptions(newArray);
    }
  };

  const handleCheckboxItemChange = (option: ISelectOptionWithParam<IDatasetMetric>) => {
    if (selectedMetrics.find(metric => metric.additionalInfo.code === option.value)) {
      dispatch(
        setDataSettingsSelectedMetrics(
          selectedMetrics.filter(metric => metric.additionalInfo.code !== option.value),
        ),
      );
      return;
    }

    dispatch(setDataSettingsSelectedMetrics([...selectedMetrics, option]));
  };

  const handleTemplateDeletion = (dataset?: IDataset) => {
    if (!dataset) return;
    // TODO: Add endpoint call
    setSectionTemplateOptions(prevState => {
      const newArray = [...prevState];
      const index = newArray.findIndex(item => item.value === dataset.id);
      if (index === -1) return newArray;

      newArray.splice(index, 1);
      return newArray;
    });
    setItemToDelete(undefined);
  };

  const handleTemplateEditation = (templateName: ILanguageType<string>, itemToDelete: IDataset) => {
    // TODO: Add endpoint call
    // After endpoint call the whole list should be updated
    setSectionTemplateOptions(prefState => {
      const newArray = [...prefState];
      newArray[Number(itemToDelete.id)] = {
        ...newArray[Number(itemToDelete.id)],
        label: templateName[activeLanguage] as ITranslationKeys,
      };
      return newArray;
    });
    setItemToEdit(undefined);
  };

  const handleAddTemplate = (templateName: ILanguageType<string>) => {
    // TODO: Add endpoint call
    // After endpoint call the whole list should be updated
    setSectionTemplateOptions(prefState => {
      const newArray = [...prefState];
      newArray.push({
        label: templateName[activeLanguage] as ITranslationKeys,
        value: slugify(templateName.en),
      });
      const newDataset: ITcmsItemActionsProps<IDataset> = {
        item: {
          id: slugify(templateName.en),
          name: templateName,
          datasetMetrics: {},
        },
        isItemChecked: false,
        onItemEditClick: setItemToEdit,
        onItemRemoveClick: setItemToDelete,
      };
      newArray[newArray.length - 1].children = <TcmsItemActions {...newDataset} />;
      return newArray;
    });
    setAddTemplate(false);
  };

  const handleTemplateSaveButtonClick = () => {
    console.log('Save button clicked!');
  };

  return (
    <div className={classNames()}>
      <div className={classNames('controls')}>
        <h2>{t(ITranslationKeys.helpCategory)}</h2>
        <div className={classNames('controls__buttons')}>
          {definedTemplatesSectionKeys.map(option => (
            <TcmsButton
              key={option.label}
              label={option.label}
              variant='filled'
              onClick={() => setActiveSection(option.value as IEntity)}
              color={activeSection === option.value ? 'blue' : 'default'}
            />
          ))}
        </div>
      </div>
      <div className={classNames('content')}>
        <div className={classNames('content__flags')}>
          <LanguageController
            activeLanguage={activeLanguage}
            onClick={() => setActiveLanguage(activeLanguage === 'cz' ? 'en' : 'cz')}
          />
        </div>
        <div className={classNames('content__logic')}>
          <DefinedTemplatesSection
            options={sectionTemplateOptions}
            onAddSortableItemClick={() => setAddTemplate(true)}
            onTcmsItemClick={handleTcmsItemClick}
            onListChange={handleOnSortEnd}
            size={width > 1400 || width < 1201 ? 'medium' : 'small'}
            {...{ activeSection, activeTemplate }}
          />
          <DefinedTemplatesDataTypes
            options={datasetGroupOptions}
            metricsBySection={metricsBySection}
            onListChange={handleDatasetListChange}
          />
          <DefinedTemplatesCheckboxes
            subgroupMetrics={filteredBySectionedSubgroups}
            onCheckboxItemChange={handleCheckboxItemChange}
            onSaveButtonClick={handleTemplateSaveButtonClick}
            {...{ activeLanguage, filteredMetricsOptions, selectedMetrics }}
          />
        </div>
      </div>
      <Modal
        open={!!itemToDelete}
        modalButtons={
          <>
            <ModalButton
              label={ITranslationKeys.close}
              fontWeight='normal'
              onClick={() => setItemToDelete(undefined)}
            />
            <ModalButton
              label={ITranslationKeys.delete}
              fontWeight='bold'
              onClick={() => handleTemplateDeletion(itemToDelete)}
            />
          </>
        }
      >
        {t(ITranslationKeys.removeTemplate)}
      </Modal>
      {itemToEdit && (
        <EditTemplateModal
          openModal={true}
          initialTemplateName={itemToEdit.name}
          onClose={() => setItemToEdit(undefined)}
          onSave={templateName => handleTemplateEditation(templateName, itemToEdit)}
        />
      )}
      {addTemplate && (
        <EditTemplateModal
          openModal={true}
          onClose={() => setAddTemplate(false)}
          onSave={templateName => handleAddTemplate(templateName)}
        />
      )}
    </div>
  );
};
