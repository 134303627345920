import { combineReducers } from '@reduxjs/toolkit';

import { definedDatasetsSlice } from './definedDatasets';
import { metricsSlice } from './metrics';
import { reportMetricsSlice } from './reportMetrics';

export * from './definedDatasets';
export * from './metrics';
export * from './reportMetrics';

export const settingsReducers = combineReducers({
  definedDatasets: definedDatasetsSlice.reducer,
  metrics: metricsSlice.reducer,
  reportMetrics: reportMetricsSlice.reducer,
});
