import { useAppSelector } from '../../app/hooks';
import { Loading } from '../../components';
import { selectNavigation } from '../../features';
import { useLoadInitialData } from '../../hooks';

export const DashboardPage = () => {
  const { isLoadingMinimumInitialData } = useAppSelector(selectNavigation);
  useLoadInitialData();

  return (
    <div data-testid='dashboard-page' style={{ flexGrow: 1 }}>
      {isLoadingMinimumInitialData && <Loading />}
    </div>
  );
};
