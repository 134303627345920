import { FC } from 'react';

import { ITranslationKeys } from '../../../../../../i18n/types';
import {
  IFaceoffComponentsParams,
  IPlaygroundSideInfoBox,
  ISelectedPlayerItem,
} from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import { FaceoffsStatBoxCategory } from '../FaceoffStatBoxCategory';

export const bottomBoxValuesMap: IPlaygroundSideInfoBox[] = [
  { label: ITranslationKeys.allFaceoffs, side: 'all' },
  { label: ITranslationKeys.leftSideFaceoffs, side: 'left' },
  { label: ITranslationKeys.rightSideFaceoffs, side: 'right' },
];

export interface IFaceoffsStatBoxCategoryWrapper extends IFaceoffComponentsParams {
  /** Selected player items above the playground */
  selectedPlayerItems: ISelectedPlayerItem[];
}

const classNames = createClassNames('faceoffs-stat-box-category-wrapper');

export const FaceoffsStatBoxCategoryWrapper: FC<IFaceoffsStatBoxCategoryWrapper> = ({
  selectedPlayerItems,
  faceoffPlayerInfoByPositionRecord,
  onBoxClick,
}) => {
  return (
    <div className={classNames()}>
      {bottomBoxValuesMap.map((item, index) => (
        <FaceoffsStatBoxCategory
          key={index + item.label}
          {...item}
          faceoffPlayerInfoByPositionRecord={faceoffPlayerInfoByPositionRecord}
          selectedPlayerItems={selectedPlayerItems}
          onBoxClick={onBoxClick}
        />
      ))}
    </div>
  );
};
