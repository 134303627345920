import { useAppSelector } from '../../../app/hooks';
import {
  CountOfPlayersController,
  PartsController,
  SeasonsController,
  TeamController,
  TimeOnIceController,
} from '../../../components';
import { selectMainFilter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { GamelogIcon, TabsIcon, TrendIcon } from '../../../icons';
import { INavigationContentKeys, IPageConfig } from '../../../types';
import { getNotSelectedTeamValidationObject } from '../../../utils';
import { GamelogContent } from './GamelogContent';
import { TabsContent } from './TabsContent';
import { TrendContent } from './TrendContent';

/** Custom hook returns config for TeamsPage tabs and widgets. */
export const usePageConfig = (): IPageConfig => {
  const { selectedTeam } = useAppSelector(selectMainFilter);

  return {
    baseFilter: (
      <>
        <SeasonsController required />
        <PartsController required />
        <TeamController />
        <CountOfPlayersController />
      </>
    ),
    tabsConfig: [
      {
        id: INavigationContentKeys.tabs,
        name: ITranslationKeys.tabs,
        icon: <TabsIcon />,
        description: ITranslationKeys.tabsPlayersDescription,
        ContentComponent: TabsContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
      },
      {
        id: INavigationContentKeys.gamelog,
        name: ITranslationKeys.gamelog,
        icon: <GamelogIcon />,
        description: ITranslationKeys.gamelogTeamsDescription,
        ContentComponent: GamelogContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController
              rules={{
                validate: getNotSelectedTeamValidationObject(ITranslationKeys.pleaseSelectTeam),
              }}
            />
            <CountOfPlayersController />
          </>
        ),
        additionalInput: <TeamController />,
        disableLoadDataButton: !selectedTeam || selectedTeam.value === 'all',
      },
      {
        id: INavigationContentKeys.trend,
        name: ITranslationKeys.trend,
        icon: <TrendIcon />,
        description: ITranslationKeys.trendTeamsDescription,
        ContentComponent: TrendContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController
              rules={{
                validate: getNotSelectedTeamValidationObject(ITranslationKeys.pleaseSelectTeam),
              }}
            />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
        additionalInput: <TeamController />,
        disableLoadDataButton: !selectedTeam || selectedTeam.value === 'all',
      },
    ],
  };
};
