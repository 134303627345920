import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredFaceoffsSelector,
  filteredGoalkeepersShotsVideoMetricSelector,
  filteredPassesVideoMetricSelector,
  filteredShootoutsSelector,
  filteredShotsVideoMetricSelector,
  filteredZoneEntriesFilterDataSelector,
  filteredZoneExitsFilterDataSelector,
  selectMainFilter,
  selectVideoCenter,
  selectVideomapsFilter,
  setMetricVideoGameIds,
  setVideoRecord,
} from '../../features';
import {
  IFaceoff,
  IMetricVideoLinkKeys,
  INavigationContentKeys,
  INavigationPageKeys,
  IPass,
  ISelectOption,
  IShot,
  IZoneEntity,
} from '../../types';
import {
  createVideoRecordFromArray,
  filterUniqueGameIds,
  mapShootoutToVideoInfo,
} from '../../utils';
import { useLocationPaths } from '../useLocationPaths';

export const useSetVideoRecordAtMetricDataLoad = () => {
  const filteredShots = useAppSelector(filteredShotsVideoMetricSelector);
  const filteredPasses = useAppSelector(filteredPassesVideoMetricSelector);
  const filteredFaceoffs = useAppSelector(filteredFaceoffsSelector);
  const zoneEntries = useAppSelector(filteredZoneEntriesFilterDataSelector);
  const zoneExits = useAppSelector(filteredZoneExitsFilterDataSelector);
  const filteredShootouts = useAppSelector(filteredShootoutsSelector);
  const filteredGoalkeepersShots = useAppSelector(filteredGoalkeepersShotsVideoMetricSelector);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const { entity, entityId, formationIds, filterByProp } = useAppSelector(selectVideomapsFilter);
  const { metricVideoLinkId } = useAppSelector(selectVideoCenter);

  const dispatch = useAppDispatch();

  const { activePage, activeTab } = useLocationPaths();

  const hasFormation =
    (activePage === INavigationPageKeys.players &&
      (activeTab === INavigationContentKeys.gamelog ||
        activeTab === INavigationContentKeys.trend)) ||
    activePage === INavigationPageKeys.formations;

  const hasSelectedGames = activeTab === INavigationContentKeys.gamelog;

  useEffect(() => {
    if (metricVideoLinkId === IMetricVideoLinkKeys.shots) {
      const shots = filterShotsOrEntries(
        filteredShots,
        entity,
        filterByProp,
        entityId,
        formationIds,
        selectedGames,
        hasSelectedGames,
        hasFormation,
      );
      const gameIds = filterUniqueGameIds(shots);
      console.log('shots', shots);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(shots)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.passes) {
      const passes = filterPassesOrExits(
        filteredPasses,
        entity,
        entityId,
        formationIds,
        selectedGames,
        hasSelectedGames,
        hasFormation,
      );
      const gameIds = filterUniqueGameIds(passes);
      console.log('passes', passes);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(passes)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.faceoffs) {
      const faceoffs = filterFaceoffs(
        filteredFaceoffs,
        formationIds,
        selectedGames,
        hasSelectedGames,
        hasFormation,
      );
      const gameIds = filterUniqueGameIds(faceoffs);
      console.log('faceoffs', faceoffs);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(faceoffs)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.entries) {
      const entries = filterShotsOrEntries(
        zoneEntries,
        entity,
        filterByProp,
        entityId,
        formationIds,
        selectedGames,
        hasSelectedGames,
        hasFormation,
      );
      const gameIds = filterUniqueGameIds(entries);
      console.log('entries', entries);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(entries)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.exits) {
      const exits = filterPassesOrExits(
        zoneExits,
        entity,
        entityId,
        formationIds,
        selectedGames,
        hasSelectedGames,
        hasFormation,
      );
      const gameIds = filterUniqueGameIds(exits);
      console.log('exits', exits);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(exits)));
    }

    if (
      metricVideoLinkId === IMetricVideoLinkKeys.shootouts ||
      metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts
    ) {
      const isTeam = entity.value === 'team';
      const isGoalkeeper = metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts;
      const filteredShootoutsByPlayer = isTeam
        ? filteredShootouts
        : filteredShootouts.filter(shootout =>
            isGoalkeeper
              ? shootout.goalkeeper.playerId === entityId
              : shootout.skater.playerId === entityId,
          );
      const filteredShootoutsByGames =
        hasSelectedGames && selectedGames.length > 0
          ? filteredShootoutsByPlayer.filter(shot =>
              selectedGames.some(game => game.value === shot.matchId),
            )
          : filteredShootoutsByPlayer;
      const shootouts = filteredShootoutsByGames.map(shootout => mapShootoutToVideoInfo(shootout));

      const gameIds = filterUniqueGameIds(filteredShootoutsByGames);
      console.log('filteredShootouts', filteredShootoutsByGames);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(shootouts)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShots) {
      const goalkeeperShots =
        hasSelectedGames && selectedGames.length > 0
          ? filteredGoalkeepersShots.filter(shot =>
              selectedGames.some(game => game.value === shot.matchId),
            )
          : filteredGoalkeepersShots;
      const gameIds = filterUniqueGameIds(goalkeeperShots);
      console.log('goalkeeperShots', goalkeeperShots);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(goalkeeperShots)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredShots,
    filteredPasses,
    filteredFaceoffs,
    filteredShootouts,
    zoneEntries,
    zoneExits,
    filteredGoalkeepersShots,
  ]);
};

const filterShotsOrEntries = <T extends IShot | IZoneEntity>(
  items: T[],
  entity: ISelectOption,
  filterByProp: string | undefined,
  entityId: string | undefined,
  formationIds: string[] | undefined,
  selectedGames: ISelectOption[],
  hasSelectedGames: boolean,
  hasFormation: boolean,
) => {
  const filteredByGames = hasSelectedGames ? filterBySelectedGames(items, selectedGames) : items;

  if (hasFormation && formationIds && formationIds.length > 0) {
    return filterShotsOrEntriesByFormationIds(filteredByGames, formationIds, filterByProp);
  }

  const filteredByEntityId = filteredByGames.filter(
    item => item[filterByProp as keyof T] === entityId,
  );

  if (entity.value === 'player') {
    return filteredByEntityId;
  }

  if (filterByProp === 'blocker') {
    if (entity.value === 'opponent') {
      return filteredByEntityId;
    }

    return filteredByGames.filter(item => !!item.blocker);
  }

  return filteredByGames;
};

const filterPassesOrExits = <T extends IPass | IZoneEntity>(
  items: T[],
  entity: ISelectOption,
  entityId: string | undefined,
  formationIds: string[] | undefined,
  selectedGames: ISelectOption[],
  hasSelectedGames: boolean,
  hasFormation: boolean,
) => {
  const filteredByGames = hasSelectedGames ? filterBySelectedGames(items, selectedGames) : items;

  if (hasFormation && formationIds && formationIds.length > 0) {
    return filterByFormationIds(filteredByGames, formationIds);
  }

  if (entity.value === 'player') {
    return items.filter(item => item.playerId === entityId);
  }

  return filteredByGames;
};

const filterFaceoffs = (
  faceoffs: IFaceoff[],
  formationIds: string[] | undefined,
  selectedGames: ISelectOption[],
  hasSelectedGames: boolean,
  hasFormation: boolean,
) => {
  const filteredByGames = hasSelectedGames
    ? filterBySelectedGames(faceoffs, selectedGames)
    : faceoffs;

  if (hasFormation && formationIds && formationIds.length > 0) {
    return filterByFormationIds(filteredByGames, formationIds);
  }

  return filteredByGames;
};

const filterShotsOrEntriesByFormationIds = <T extends IShot | IZoneEntity>(
  items: T[],
  formationIds: string[],
  filterByProp: string | undefined,
) => {
  if (filterByProp === 'blocker') {
    return items.filter(item => item.blocker && formationIds.includes(item.blocker));
  }
  return items.filter(item => formationIds.includes(item.playerId));
};

const filterByFormationIds = <T extends IPass | IZoneEntity | IFaceoff>(
  items: T[],
  formationIds: string[],
) => items.filter(item => formationIds.includes(item.playerId));

const filterBySelectedGames = <T extends IShot | IPass | IZoneEntity | IFaceoff>(
  items: T[],
  selectedGames: ISelectOption[],
) => {
  if (selectedGames.length > 0) {
    return items.filter(item => selectedGames.some(game => game.value === item.matchId));
  }

  return items;
};
