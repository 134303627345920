import {
  controlledZoneCategoryOption,
  dumpInOutResultOptions,
  zoneEntryCategoryOptions,
  zoneEntryTypeOptions,
  zoneExitResultOptions,
  zoneFinishOptions,
  zoneSuccessOptions,
} from '../../constants';
import { IUrlParam, IZonesFilterState } from '../../types';
import { getDefaultPredefinedOptionByValue } from '../common.utils';
import { parseUrlStaticSelectOption, parseUrlStaticSelectOptionArray } from '../url.utils';

export const parseUrlZonesFilterParams = (searchParams: URLSearchParams): IZonesFilterState => {
  const zoneCategory = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.zoneCategory,
    zoneEntryCategoryOptions,
  );
  const zoneSuccess = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.zoneSuccess,
    zoneSuccessOptions,
  );
  const zoneFinish = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.zoneFinish,
    zoneFinishOptions,
  );
  const zoneEntryType = parseUrlStaticSelectOptionArray(
    searchParams,
    IUrlParam.zoneEntryType,
    zoneEntryTypeOptions,
  );
  const zoneExitResult = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.zoneExitResult,
    zoneExitResultOptions,
  );
  const dumpInOutResult = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.dumpInOutResult,
    dumpInOutResultOptions,
  );

  return {
    zoneCategory: zoneCategory || controlledZoneCategoryOption,
    zoneSuccess: zoneSuccess || getDefaultPredefinedOptionByValue(zoneSuccessOptions),
    zoneFinish: zoneFinish || getDefaultPredefinedOptionByValue(zoneFinishOptions),
    zoneEntryType: zoneEntryType || [getDefaultPredefinedOptionByValue(zoneEntryTypeOptions)],
    zoneExitResult: zoneExitResult || getDefaultPredefinedOptionByValue(zoneExitResultOptions),
    dumpInOutResult: dumpInOutResult || getDefaultPredefinedOptionByValue(dumpInOutResultOptions),
  };
};
