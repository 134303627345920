import { FC } from 'react';

import { ITranslationKeys } from '../../../i18n/types';
import { PopupAlertIcon } from '../../../icons';
import { IHelpItem, ILanguageType } from '../../../types';
import { createClassNames } from '../../../utils';
import { Button } from '../../Button';
import { CheckboxWithDescription } from '../../CheckboxWithDescription';
import { ModalItem } from '../ModalItem';
import './ModalContent.styles.scss';

export interface IModalContentProps {
  /** A header label. */
  header: string;
  /** Modal items to be displayed. */
  news: IHelpItem[];
  /** Is the modal opened? */
  checkboxChecked: boolean;
  /** Users language */
  language: keyof ILanguageType<string>;
  /** Callback to invoke when the button for disposing a modal is clicked. */
  handleCheckboxClick: () => void;
  /** Callback to invoke when the button for disposing a modal is clicked. */
  handleClose: () => void;
}

const classNames = createClassNames('modal-content');

/** A component responsible for displaying content to the Modal component. */
export const ModalContent: FC<IModalContentProps> = ({
  header,
  news,
  checkboxChecked,
  language,
  handleCheckboxClick,
  handleClose,
}) => {
  return (
    <div className={classNames()}>
      <PopupAlertIcon />
      <h3>{header}</h3>
      <div className={classNames('news')}>
        {news.map((item, index) => (
          <ModalItem key={index} modalItem={item[language]} />
        ))}
      </div>
      <CheckboxWithDescription
        description={ITranslationKeys.nextTimeDontShow}
        checked={checkboxChecked}
        onClick={handleCheckboxClick}
      />
      <Button primary={false} label={ITranslationKeys.understand} onClick={handleClose} />
    </div>
  );
};
