import {
  IGamesTablesFormations,
  IGamesTablesFormationsDTO,
  IGamesTablesPlayersDTO,
  IPlayerWithTeamIdAndStatsAndLineup,
} from '../../../types';

export const parseGamesTablesPlayersData = (
  data: IGamesTablesPlayersDTO[],
): IPlayerWithTeamIdAndStatsAndLineup[] =>
  data.map(player => ({
    teamId: player.teamUuid,
    id: player.uuid,
    stats: player.stats,
    line: player.line,
    position: player.position,
  }));

export const parseGamesTablesFormationData = (
  data: IGamesTablesFormationsDTO[],
): IGamesTablesFormations[] =>
  data.map(player => ({
    playerIds: player.players,
    teamId: player.teamUuid,
    stats: player.stats,
  }));
