import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  CommonTable,
  DataSettingsModal,
  Loading,
  SelectPlayersBar,
  TableToolbar,
} from '../../../../components';
import {
  filteredMainFilterDataSelector,
  resetSelectedPlayerItems,
  selectDataSettingsFilter,
  selectFormations,
  selectMainFilter,
  selectMetrics,
  selectPlayers,
  selectTableFilter,
  selectTeams,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchPlayerCombinations,
  useHandleOnSubmit,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { IExportRows, IMainFilterForm, IPlayerCombinationsTableData } from '../../../../types';
import {
  createClassNames,
  getPlayerPositionShortcut,
  getPlayerStick,
  handleExportToXLSX,
  isFormValid,
  roundNumberTo2Decimals,
} from '../../../../utils';
import './PlayerCombinationsContent.styles.scss';
import { TogglePlayersSide } from './components';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';
import { useExtraRowHeaderRender } from './useExtraRowHeaderRender';

const classNames = createClassNames('player-combinations-content');

export const PlayerCombinationsContent = () => {
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { isLoading, teammateState, opponentState } = useAppSelector(selectFormations);
  const { metrics } = useAppSelector(selectMetrics);
  const { selectedTeam, selectedPlayerItems } = useAppSelector(selectMainFilter);
  const { playersInChart } = useAppSelector(selectTableFilter);
  const { filteredPlayersOptions } = useAppSelector(filteredMainFilterDataSelector);
  const players = useAppSelector(selectPlayers);
  const teams = useAppSelector(selectTeams);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { formState } = useFormContext<IMainFilterForm>();
  const { errors } = formState;

  const isInitialMountRef = useRef(true);

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const { selectedTeamData, data } = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const extraRowHeaderRender = useExtraRowHeaderRender(selectedTeamData);

  const fetchPlayerCombinations = useFetchPlayerCombinations();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchPlayerCombinations(values);
  }, isInitialMountRef);

  useEffect(() => {
    if (!isInitialMountRef.current) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayerItems]);

  useEffect(() => {
    if (!isInitialMountRef.current) {
      dispatch(resetSelectedPlayerItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  useEffect(() => {
    if (!isInitialMountRef.current && formState.dirtyFields.selectedTeam) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playersInChart]);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  const commonHeader = [
    'Hokej.cz ID',
    t(ITranslationKeys.playerName),
    t(ITranslationKeys.age),
    t(ITranslationKeys.stickGrip),
    t(ITranslationKeys.team),
    t(ITranslationKeys.position),
    'GP',
    'TOI',
  ];
  const tableRowCallback = (row: IPlayerCombinationsTableData): IExportRows => {
    const player = players.byId[row.player.id];
    const team = teams.byId[row.teamId];

    return {
      hokejczId: player.hokejczId ?? '?',
      playerName: row.player.displayName,
      age: player.age,
      stick: t(getPlayerStick(player.stick)).toString(),
      team: team.shortcut,
      position: t(getPlayerPositionShortcut(player.position)).toString(),
      gp: row.gp,
      toi: roundNumberTo2Decimals(row.toi / 60),
    };
  };

  const isLoadingCondition = isLoading || teammateState.isLoading || opponentState.isLoading;

  const renderTableOrInfoBox = () => {
    if (isLoadingCondition) {
      return <Loading />;
    }

    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) {
      return errorInfoBox;
    }

    return (
      <CommonTable<IPlayerCombinationsTableData>
        centerPartRef={centerTablePartRef}
        extraRowHeaderRender={extraRowHeaderRender}
        {...{ data, columns, columnPinning, initialSorting }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='formations-page__player-combinations-content'>
      <SelectPlayersBar
        playersOptions={filteredPlayersOptions}
        disabled={!isFormValid(errors)}
        colorAll='blue'
        onOffColored
        paddingBottom={'2.5rem'}
      />
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        scrollSizePx={130}
        isLoading={isLoadingCondition}
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() =>
              handleExportToXLSX(
                t(ITranslationKeys.playerCombinationsTable),
                commonHeader,
                tableRowCallback,
                data,
              )
            }
          />
        }
        extraElement={<TogglePlayersSide />}
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.playerOnIceData}
        />
      )}
    </div>
  );
};
