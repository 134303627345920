import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  controlledZoneCategoryOption,
  dumpInOutResultOptions,
  zoneEntryTypeOptions,
  zoneExitResultOptions,
  zoneFinishOptions,
  zoneSuccessOptions,
} from '../../../constants';
import { ISelectOption, IZonesFilterState } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';

const initialState: IZonesFilterState = {
  zoneCategory: getDefaultPredefinedOptionByValue([controlledZoneCategoryOption]),
  zoneSuccess: getDefaultPredefinedOptionByValue(zoneSuccessOptions),
  zoneFinish: getDefaultPredefinedOptionByValue(zoneFinishOptions),
  zoneEntryType: [getDefaultPredefinedOptionByValue(zoneEntryTypeOptions)],
  zoneExitResult: getDefaultPredefinedOptionByValue(zoneExitResultOptions),
  dumpInOutResult: getDefaultPredefinedOptionByValue(dumpInOutResultOptions),
  shotPlayerId: undefined,
};

export const zonesFilterSlice = createSlice({
  name: 'zonesFilter',
  initialState,
  reducers: {
    setZoneCategory: (state, action: PayloadAction<ISelectOption>) => {
      state.zoneCategory = action.payload;
    },
    setZoneSuccess: (state, action: PayloadAction<ISelectOption>) => {
      state.zoneSuccess = action.payload;
    },
    setZoneFinish: (state, action: PayloadAction<ISelectOption>) => {
      state.zoneFinish = action.payload;
    },
    setZoneEntryType: (state, action: PayloadAction<ISelectOption[]>) => {
      state.zoneEntryType = action.payload;
    },
    setZoneExitResult: (state, action: PayloadAction<ISelectOption>) => {
      state.zoneExitResult = action.payload;
    },
    setDumpInOutResult: (state, action: PayloadAction<ISelectOption>) => {
      state.dumpInOutResult = action.payload;
    },
    setShotPlayerId: (state, action: PayloadAction<string | undefined>) => {
      state.shotPlayerId = action.payload;
    },
    resetZonesFilter: (_, action: PayloadAction<Partial<IZonesFilterState> | undefined>) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const {
  setZoneCategory,
  setZoneSuccess,
  setZoneFinish,
  setZoneEntryType,
  setZoneExitResult,
  setDumpInOutResult,
  setShotPlayerId,
  resetZonesFilter,
} = zonesFilterSlice.actions;

export const zonesFilterReducer = zonesFilterSlice.reducer;
