import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableElement } from 'react-sortable-hoc';

import { ChevronDownIcon, DragDropIcon } from '../../../icons';
import { ITcmsItemWithDropdownProps } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { DragHandle } from '../../DragHandle';
import './TcmsItemWithDropdown.styles.scss';

const classNames = createClassNames('tcms-item-with-dropdown');

export const TcmsItemWithDropdown: FC<ITcmsItemWithDropdownProps> = ({
  label,
  dropdownContent,
  disabled = false,
  selected = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          disabled,
          selected,
        }),
      })}
      data-testid='tcms-item-with-dropdown'
    >
      <div className={classNames('body')} onClick={onClick}>
        <DragHandle disabled={disabled} icon={<DragDropIcon />} />
        <div className={classNames('body__label')}>{t(label)}</div>
        <div className={classNames('body__content')}>
          <div className={classNames('body__content__dropdown-icon')}>
            <ChevronDownIcon />
          </div>
        </div>
      </div>
      {selected && <div className={classNames('dropdown')}>{dropdownContent}</div>}
    </div>
  );
};

export const TcmsSortableItemWithDropdown = SortableElement<ITcmsItemWithDropdownProps>(
  (props: ITcmsItemWithDropdownProps) => <TcmsItemWithDropdown {...props} />,
);
