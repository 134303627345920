import { useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  GamesSelectList,
  Loading,
  Playground,
  PlaygroundAssistsContent,
  ToggleSelect,
} from '../../../../components';
import {
  entityOptions,
  passTypeOptions,
  shotCategoryOptions,
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationOptions,
} from '../../../../constants';
import {
  filteredPassesDataSelector,
  filteredVideomapsFilterDataSelector,
  selectGames,
  selectMainFilter,
  selectMetricParamsFilter,
  selectPasses,
  selectPlayers,
  selectTeams,
  selectVideomapsFilter,
  setDrawAllPassesTrajectories,
  setPassType,
  setSelectedGames,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchShotsOrPasses,
  useHandleOnChange,
  useHandleOnSubmit,
  useSetUrlSearchParams,
  useShotAndPassEffects,
  useVideoCenter,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { ISelectOption, IUrlParam } from '../../../../types';
import {
  calculateShotsTotalXG,
  changeUrlSearchParamsMultiselectInput,
  convertPassToPlaygroundPass,
  createClassNames,
  filterGoals,
  getGamesOptions,
  getShotsDangerBoxes,
  handleToggleMultiselectChange,
  mapShotsFromPasses,
} from '../../../../utils';
import './PassesContent.styles.scss';

const classNames = createClassNames('videomaps-passes-content');

export const PassesContent = () => {
  const { isLoading } = useAppSelector(selectPasses);
  const { filteredGamesTotalToi } = useAppSelector(filteredVideomapsFilterDataSelector);
  const { filteredPassesGameEntity, filteredPasses } = useAppSelector(filteredPassesDataSelector);
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const { games } = useAppSelector(selectGames);
  const { selectedGames, selectedPlayerItems } = useAppSelector(selectMainFilter);
  const { drawAllPassesTrajectories, entity, shotCategory, passType } =
    useAppSelector(selectVideomapsFilter);
  const { shotDanger, shotLocation, gameActionType } = useAppSelector(selectMetricParamsFilter);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { setUrlSearchParams } = useSetUrlSearchParams();
  const {
    onChangeShotCategory,
    onChangeEntity,
    onChangeShotLocation,
    onChangeShotDanger,
    onChangeGameActionType,
  } = useHandleOnChange();
  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const { fetchGamesAndToiForShotsOrPasses, fetchShots, fetchPasses } = useFetchShotsOrPasses();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    filteredPassesGameEntity,
    ITranslationKeys.passes,
  );

  const isInitialMountRef = useRef(true);

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
    fetchShots(values);
    fetchPasses(values);
  }, isInitialMountRef);

  useShotAndPassEffects(handleOnSubmit, isInitialMountRef, isLoading);

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, 'selectedGames', setSearchParams);
  };

  const handleChangePassType = (option: ISelectOption) => {
    dispatch(setPassType(option));
    setUrlSearchParams(IUrlParam.passType, option.value);
  };

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);
  const isValidFormation =
    Object.values(selectedPlayerItems).filter(player => player.isActive).length > 0;

  if (isLoading) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, 1);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  const passesShots = mapShotsFromPasses(filteredPasses);
  const filteredPlaygroundPasses = filteredPasses
    .filter(pass => !!pass.shot)
    .map(pass => convertPassToPlaygroundPass(pass, teams.byId, players.byId, selectedPlayerItems));

  return (
    <div className={classNames()} data-testid='videomaps-page__passes-content'>
      <div className={classNames('main')}>
        <GamesSelectList
          selected={selectedGames}
          options={gamesOptions}
          gameRecord={games.byId}
          teamRecord={teams.byId}
          shouldCheckFullBody={false}
          gameEntities={filteredPassesGameEntity}
          selectedPlayerItems={selectedPlayerItems}
          onChange={newValue => handleChangeGame(newValue)}
          onItemContentActionClick={gameId => playGameVideos(gameId)}
          onPlaySelectedGamesVideos={playSelectedGamesVideos}
          hasSelectAllOption
        />
        <div className={classNames('main__playground-wrapper')}>
          <Playground
            toi={filteredGamesTotalToi}
            c={filteredPasses.length}
            xG={calculateShotsTotalXG(passesShots)}
            g={filterGoals(passesShots).length}
            boxes={getShotsDangerBoxes(passesShots)}
            boxListHeading={ITranslationKeys.shotsDanger}
            isResponsive
            renderContent={() => (
              <PlaygroundAssistsContent
                assists={filteredPlaygroundPasses}
                hideTrajectories={!drawAllPassesTrajectories}
                onPlayClick={playVideos}
              />
            )}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div className={classNames('main__form-box__trajectories')}>
            <Caption label={ITranslationKeys.trajectories} />
            <Button
              label={ITranslationKeys.showAllTrajectories}
              primary
              variant={drawAllPassesTrajectories ? 'normal' : 'gray'}
              onClick={() => dispatch(setDrawAllPassesTrajectories(!drawAllPassesTrajectories))}
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.whoPasses} />
            <ToggleSelect
              selected={entity}
              options={entityOptions}
              onChange={newOption => onChangeEntity(newOption)}
              disabledOptions={!isValidFormation ? [entityOptions[0]] : undefined}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.passCategory} />
            <ToggleSelect
              selected={shotCategory}
              options={shotCategoryOptions}
              onChange={newOption => onChangeShotCategory(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotAfterPassLocation} />
            <ToggleSelect
              selected={shotLocation}
              options={shotLocationOptions}
              onChange={newOption => onChangeShotLocation(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.passTypes} />
            <ToggleSelect
              selected={passType}
              options={passTypeOptions}
              onChange={newOption => handleChangePassType(newOption)}
              hasOnlyOneColumn
            />
          </div>
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.attackType} />
            <ToggleSelect
              selected={gameActionType}
              options={shotGameActionTypeOptions}
              onChange={newOption => onChangeGameActionType(newOption)}
              hasOnlyOneColumn
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotsDanger} />
            <ToggleSelect
              options={shotDangerOptions}
              selected={shotDanger}
              hasOnlyOneColumn
              onChange={option =>
                onChangeShotDanger(
                  handleToggleMultiselectChange(option, shotDangerOptions, shotDanger),
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
