import { FC, ReactNode } from 'react';

import { COLORS } from '../../../constants';
import { INetZone, INetZoneUnion } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './GoalNet.styles.scss';

export interface IGoalNetProps {
  /** Width of the svg. */
  width?: number;
  /** Height of the svg. */
  height?: number;
  /** Version of color theme of the selected goal net area. */
  variant?: 'red' | 'blue' | 'black';
  /** Active zone. */
  activeZones?: INetZoneUnion[];
  /** When true, user can select zones. */
  isClickable?: boolean;
  /** Content of the GoalNet component. */
  content?: ReactNode;
  /** Disables content pointer events. */
  disableContentPointerEvents?: boolean;
  /** If isClickable parameter is true, then this callback is fired when user clicks on a zone. */
  onZoneChange?: (zone: INetZoneUnion) => void;
}

const classNames = createClassNames('goal-net');

export const GoalNet: FC<IGoalNetProps> = ({
  activeZones = [],
  width = 232,
  height = 155,
  variant = 'red',
  isClickable = false,
  content,
  disableContentPointerEvents,
  onZoneChange,
}) => {
  const handleSelectGoalZone = (zone: INetZoneUnion) => {
    if (isClickable) {
      onZoneChange?.(zone);
    }
  };

  const zones = activeZones.reduce<Record<string, boolean>>((acc, zone) => {
    acc[`active-zone_${zone}`] = true;
    return acc;
  }, {});

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({ isClickable, variant }),
        ...zones,
      })}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      data-testid='goal-net'
    >
      <svg
        width={width}
        height={height}
        viewBox='0 0 240 160'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <defs>
          <linearGradient id='Gradient' x1='0%' x2='0%' y1='0%' y2='100%'>
            <stop offset='0%' stopColor={COLORS.blue[50]}></stop>
            <stop offset='100%' stopColor={COLORS.blue[60]}></stop>
          </linearGradient>
        </defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='branka-copy'>
            <path
              d='M4,160 L0,160 L0,7.044 C0,3.19 3.177,0 7,0 L233,0 C236.823,0 240,3.19 240,7.044 L240,160 L236,160 L236,7.044 C236.129,5.337 234.685,3.887 233,4.025 L7,4.025 C5.315,3.887 3.871,5.337 4,7.044 L4,160 Z'
              id='Path'
              fill={COLORS.red[60]}
            ></path>
            <path
              d='M84,86 L84,120 C84,122.563 84.603,124.986 85.674,127.134 L60.111,160 L8,160 L8,86 L84,86 Z'
              id='Path'
              fill={COLORS.coolGray[10]}
              fillRule='nonzero'
              className='br'
              onClick={() => handleSelectGoalZone(INetZone.br)}
            ></path>
            <rect
              id='Rectangle'
              fill={COLORS.coolGray[10]}
              x='88'
              y='36'
              width='64'
              height='96'
              rx='12'
              className='ce'
              onClick={() => handleSelectGoalZone(INetZone.ce)}
            ></rect>
            <path
              d='M118,8 L118,32 L100,32 C91.269,32 84.17,38.994 84.003,47.685 L84,48 L84,82 L8,82 L8,8 L118,8 Z'
              id='Path'
              fill={COLORS.coolGray[10]}
              fillRule='nonzero'
              className='ur'
              onClick={() => handleSelectGoalZone(INetZone.ur)}
            ></path>
            <path
              d='M152.112,129.455 L175,159 L65,159 L87.888,129.455 C90.8301361,132.870621 95.0793291,134.882976 99.586,134.995 L100,135 L140,135 C144.84,135 149.178,132.85 152.112,129.455 Z'
              id='Path'
              fill={COLORS.coolGray[10]}
              fillRule='nonzero'
              className='fh'
              onClick={() => handleSelectGoalZone(INetZone.fh)}
            ></path>
            <path
              d='M232,8 L232,82 L156,82 L156,48 C156,39.269 149.006,32.17 140.315,32.003 L140,32 L122,32 L122,8 L232,8 Z'
              id='Path'
              fill={COLORS.coolGray[10]}
              fillRule='nonzero'
              className='ul'
              onClick={() => handleSelectGoalZone(INetZone.ul)}
            ></path>
            <path
              d='M231,86 L231,160 L178.889,160 L153.326,127.134 C154.358731,125.063184 154.927605,122.792123 154.993,120.479 L155,120 L155,86 L231,86 Z'
              id='Path'
              fill={COLORS.coolGray[10]}
              fillRule='nonzero'
              className='bl'
              onClick={() => handleSelectGoalZone(INetZone.bl)}
            ></path>
          </g>
        </g>
      </svg>
      {content && (
        <div
          className={classNames('content', {
            ...transformObjectKeysToKebabCase({ disableContentPointerEvents }),
          })}
        >
          {content}
        </div>
      )}
    </div>
  );
};
