import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Button, CardItemList, Loading, PlayerDetails } from '../../../../components';
import {
  filteredMainFilterDataSelector,
  getPlayerCardInfo,
  selectMainFilter,
  selectPlayers,
  setActualLoadedPlayer,
  setComparePlayerItems,
} from '../../../../features';
import {
  useExportAsImage,
  useHandleOnSubmit,
  useLocationPaths,
  useRefetchContent,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { INavigationContentKeys, INavigationPageKeys } from '../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../utils';
import { useGetPlayerCards } from '../shared';
import './PlayerCardContent.styles.scss';

export const useFetchPlayerCard = () => {
  const { selectedTeam, selectedPlayer } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { byId } = useAppSelector(selectPlayers);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fetchPlayerCard = () => {
    const playerId = selectedPlayer?.value;

    if (playerId) {
      const isSelectionValid = filteredParts.length === 1 && selectedTeam && selectedPlayer;
      const loadedPlayerInfo = byId[playerId]?.playerInfos?.find(
        playerInfo =>
          playerInfo.teamId === selectedTeam?.value &&
          playerInfo.competitionId === filteredParts[0].id,
      );
      const playerInfoNotLoadedOrNewPlayerSelected = !loadedPlayerInfo;

      if (isSelectionValid) {
        if (playerInfoNotLoadedOrNewPlayerSelected) {
          dispatch(
            getPlayerCardInfo({
              competitionUuid: filteredParts[0].id,
              teamUuid: selectedTeam.value,
              playerUuid: selectedPlayer.value,
            }),
          );
        } else {
          dispatch(
            setActualLoadedPlayer({
              id: byId[playerId].id,
              teamId: selectedTeam?.value,
            }),
          );
        }
      } else {
        if (filteredParts.length > 1) {
          toast.error(t(ITranslationKeys.pleaseSelectOnlyOneSeason), {
            toastId: 'pleaseSelectOnlyOneSeason',
          });
        }
      }
    }
  };

  return fetchPlayerCard;
};

const classNames = createClassNames('player-card-content');

export const PlayerCardContent = () => {
  const { actualLoadedPlayer, byId, comparePlayerItems, isPlayerInfoLoading } =
    useAppSelector(selectPlayers);
  const { selectedTeam, selectedPlayer } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const { activeTab, navigate, search } = useLocationPaths();
  const { t } = useTranslation();
  const { getPlayerCards } = useGetPlayerCards();

  const isInitialMountRef = useRef(true);
  /* Export dat */
  const exportRef = useRef<HTMLDivElement>(null);
  const { isExporting, exportImage } = useExportAsImage(exportRef, `hokejLogic-karta-hráče`, 'png');

  const fetchPlayerCard = useFetchPlayerCard();
  const handleOnSubmit = useHandleOnSubmit(() => {
    fetchPlayerCard();
  });

  useRefetchContent({
    handleOnSubmit,
    isLoading: isPlayerInfoLoading,
    disableActiveTabRefetch: true,
  });

  useEffect(() => {
    if (!isPlayerInfoLoading) {
      handleOnSubmit();
      isInitialMountRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleCompareWithOtherPlayer = () => {
    navigate(INavigationPageKeys.players, INavigationContentKeys.comparePlayers, search);
    dispatch(
      setComparePlayerItems({
        comparePlayerItems: {
          ...comparePlayerItems[0],
          selectedPlayerCompare: selectedPlayer,
          actualCompareLoadedPlayer: {
            id: actualLoadedPlayer?.id,
            teamId: actualLoadedPlayer?.teamId,
          },
        },
        itemKey: '0',
      }),
    );
  };

  const render = () => {
    if (!actualLoadedPlayer?.id && filteredParts.length === 0) {
      return (
        <div className='content-info-box'>
          {t(ITranslationKeys.pleaseSelectOneSeasonAndOnePart)}
        </div>
      );
    }

    if (!actualLoadedPlayer?.id && filteredParts.length > 1) {
      return (
        <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOnlyOneSeason)}</div>
      );
    }

    if (isPlayerInfoLoading) {
      return <Loading />;
    }

    if (!actualLoadedPlayer?.id && !(selectedTeam && selectedPlayer)) {
      return (
        <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectTeamAndPlayer)}</div>
      );
    }

    if (!actualLoadedPlayer?.id) {
      return <div className='content-info-box'>{t(ITranslationKeys.youCanLoadData)}</div>;
    }

    const playerInfo = byId[actualLoadedPlayer.id].playerInfos?.find(
      playerInfo =>
        playerInfo.teamId === actualLoadedPlayer.teamId &&
        playerInfo.competitionId === filteredParts[0].id,
    );

    return (
      <>
        <PlayerDetails
          player={byId[actualLoadedPlayer.id]}
          playerInfo={playerInfo}
          actions={
            <>
              <Button
                label={ITranslationKeys.compareWithOtherPlayer}
                onClick={handleCompareWithOtherPlayer}
                disabled={isExporting}
              />
              <Button
                label={ITranslationKeys.downloadPlayerCard}
                iconComponent={<DownloadIcon />}
                onClick={exportImage}
                disabled={isExporting}
              />
            </>
          }
        />
        <CardItemList isExporting={isExporting} mode='wrap'>
          {getPlayerCards(playerInfo, actualLoadedPlayer.id)}
        </CardItemList>
      </>
    );
  };

  return (
    <div
      ref={exportRef}
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isLoading: isPlayerInfoLoading,
        }),
      })}
      data-testid='players-page__player-card'
    >
      {render()}
    </div>
  );
};
