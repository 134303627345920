import { createSelector } from '@reduxjs/toolkit';

import { IPass, ISelectOption } from '../../types';
import { createGameEntity, filterByEntity, filterBySelectedGames } from '../../utils';
import {
  selectMainFilter,
  selectMetricParamsFilter,
  selectPasses,
  selectVideomapsFilter,
} from '../selectors';

export const filteredPassesByEntitySelector = createSelector(
  [selectVideomapsFilter, selectPasses],
  ({ entity }, passesState) => {
    const { passes, passesAgainst } = passesState;
    return filterByEntity(entity, passes, passesAgainst);
  },
);

export const filteredPassesByGamesSelector = createSelector(
  [
    selectVideomapsFilter,
    selectMainFilter,
    filteredPassesByEntitySelector,
    (_, customGames?: ISelectOption[]) => customGames,
  ],
  ({ entity }, { selectedGames, selectedPlayerItems }, filteredPassesByEntity, customGames) =>
    filterBySelectedGames(
      entity,
      filteredPassesByEntity,
      selectedPlayerItems,
      selectedGames,
      customGames,
    ),
);

export const filteredPassesShotsByCategorySelector = createSelector(
  [selectVideomapsFilter, filteredPassesByEntitySelector],
  (videomapsFilterState, filteredPassesByEntity) => {
    const { shotCategory } = videomapsFilterState;

    if (shotCategory.value === 'SG')
      return filteredPassesByEntity.filter(
        pass => pass.shot.type === 'S' || pass.shot.type === 'G',
      );
    if (shotCategory.value === 'S')
      return filteredPassesByEntity.filter(pass => pass.shot.type === 'S');
    if (shotCategory.value === 'G')
      return filteredPassesByEntity.filter(pass => pass.shot.type === 'G');
    if (shotCategory.value === 'M')
      return filteredPassesByEntity.filter(pass => pass.shot.type === 'M');
    if (shotCategory.value === 'B')
      return filteredPassesByEntity.filter(pass => pass.shot.type === 'B');
    if (shotCategory.value === 'P')
      return filteredPassesByEntity.filter(pass => pass.shot.type === 'P');

    return filteredPassesByEntity;
  },
);

export const filteredPassesShotsByLocationSelector = createSelector(
  [selectMetricParamsFilter, filteredPassesByEntitySelector],
  (metricParamsFilterState, filteredPassesByEntity) => {
    const { shotLocation } = metricParamsFilterState;

    if (shotLocation.value === 'slot') {
      return filteredPassesByEntity.filter(pass => pass.shot.inSlot);
    }

    return filteredPassesByEntity;
  },
);

export const filteredPassesByTypeSelector = createSelector(
  [selectVideomapsFilter, filteredPassesByEntitySelector],
  (videomapsFilterState, filteredPassesByEntity) => {
    const { passType } = videomapsFilterState;

    if (!passType) {
      return filteredPassesByEntity;
    }

    if (passType.value === 'behindTheNet') {
      return filteredPassesByEntity.filter(pass => pass.behindTheNet);
    }

    if (passType.value === 'crossIce') {
      return filteredPassesByEntity.filter(pass => pass.shot.crossIce);
    }

    if (passType.value === 'oneTimer') {
      return filteredPassesByEntity.filter(pass => pass.shot.oneTimer);
    }

    return filteredPassesByEntity;
  },
);

export const filteredPassesShotsByDangerSelector = createSelector(
  [selectMetricParamsFilter, filteredPassesByEntitySelector],
  (metricParamsFilterState, filteredPassesByEntity) => {
    const { shotDanger } = metricParamsFilterState;
    if (shotDanger.some(danger => danger.value === 'all')) return filteredPassesByEntity;

    return shotDanger.reduce<IPass[]>((acc, danger) => {
      if (danger.value === 'hd')
        return acc.concat(filteredPassesByEntity.filter(pass => pass.shot.shotDanger === 'hd'));
      if (danger.value === 'md')
        return acc.concat(filteredPassesByEntity.filter(pass => pass.shot.shotDanger === 'md'));
      if (danger.value === 'ld')
        return acc.concat(filteredPassesByEntity.filter(pass => pass.shot.shotDanger === 'ld'));
      return acc;
    }, []);
  },
);

export const filteredPassesByGameActionTypeSelector = createSelector(
  [selectMetricParamsFilter, filteredPassesByEntitySelector],
  (metricParamsFilterState, filteredPassesByEntity) => {
    const { gameActionType } = metricParamsFilterState;

    if (gameActionType.value === 'f') {
      return filteredPassesByEntity.filter(pass => pass.shot.forecheck);
    }

    if (gameActionType.value === 'r') {
      return filteredPassesByEntity.filter(pass => pass.shot.rush);
    }

    if (gameActionType.value === 'l') {
      return filteredPassesByEntity.filter(pass => pass.shot.long);
    }

    if (gameActionType.value === 'o') {
      return filteredPassesByEntity.filter(pass => pass.shot.oddManRush);
    }

    if (gameActionType.value === 'a') {
      return filteredPassesByEntity.filter(pass => pass.shot.afterFO);
    }

    return filteredPassesByEntity;
  },
);

export const filteredPassesSelector = createSelector(
  [
    filteredPassesByGamesSelector,
    filteredPassesShotsByCategorySelector,
    filteredPassesShotsByLocationSelector,
    filteredPassesByTypeSelector,
    filteredPassesShotsByDangerSelector,
    filteredPassesByGameActionTypeSelector,
  ],
  (
    filteredPassesByGames,
    filteredPassesShotsByCategory,
    filteredPassesShotsByLocation,
    filteredPassesByType,
    filteredPassesShotsByDanger,
    filteredPassesByGameActionType,
  ) => {
    const filteredPassesByAll = filteredPassesByGames.filter(shot => {
      return (
        filteredPassesShotsByCategory.includes(shot) &&
        filteredPassesShotsByLocation.includes(shot) &&
        filteredPassesByType.includes(shot) &&
        filteredPassesShotsByDanger.includes(shot) &&
        filteredPassesByGameActionType.includes(shot)
      );
    });

    return filteredPassesByAll;
  },
);

export const filteredPassesGameEntitySelector = createSelector(
  [selectMainFilter, filteredPassesSelector],
  ({ selectedGames }, filteredPasses) => createGameEntity(selectedGames, filteredPasses),
);

export const filteredPassesVideoMetricSelector = createSelector(
  [
    filteredPassesShotsByCategorySelector,
    filteredPassesShotsByLocationSelector,
    filteredPassesByTypeSelector,
    filteredPassesShotsByDangerSelector,
    filteredPassesByGameActionTypeSelector,
  ],
  (
    filteredPassesShotsByCategory,
    filteredPassesShotsByLocation,
    filteredPassesByType,
    filteredPassesShotsByDanger,
    filteredPassesByGameActionType,
  ) => {
    const filteredPassesByAll = filteredPassesShotsByCategory.filter(shot => {
      return (
        filteredPassesShotsByLocation.includes(shot) &&
        filteredPassesByType.includes(shot) &&
        filteredPassesShotsByDanger.includes(shot) &&
        filteredPassesByGameActionType.includes(shot)
      );
    });

    return filteredPassesByAll;
  },
);

export const filteredPassesDataSelector = createSelector(
  [filteredPassesSelector, filteredPassesGameEntitySelector],
  (filteredPasses, filteredPassesGameEntity) => {
    return {
      filteredPasses,
      filteredPassesGameEntity,
    };
  },
);
