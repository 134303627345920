import { CellContext, ColumnDef, ColumnHelper } from '@tanstack/react-table';

import {
  BasicColumnCell,
  BasicColumnHeader,
  MetricColumnCell,
  MetricColumnHeader,
  SimilarityColumnCell,
  SimilarityColumnHeader,
} from '../components';
import {
  IMetricWithDatasetMetric,
  IMetricWithRankValuesRecord,
  IMetricWithValueRecord,
  IPercentilesConfig,
  ISimilarityStats,
  IWowyTableData,
} from '../types';

export const createIndexColumn = <TData, TValue>(props: CellContext<TData, TValue>) =>
  `${props.table.getSortedRowModel().flatRows.indexOf(props.row) + 1}.`;

export const prepareWowyTableDataToExport = (data: IWowyTableData[]) => {
  const dataToExport = data.reduce<IWowyTableData[]>((acc, row) => {
    acc.push(row);
    if (row.subRows.length > 0) {
      const firstDepthRows = row.subRows;

      const secondDepthRows: IWowyTableData[] = [];
      firstDepthRows.forEach(firstDepthRow => {
        if (firstDepthRow.subRows.length > 0) {
          secondDepthRows.push(...firstDepthRow.subRows);
        }
      });

      acc.push(...firstDepthRows);
      acc.push(...secondDepthRows);
    }
    return acc;
  }, []);

  return dataToExport;
};

export const renderMetricColumnCell = <
  T extends {
    entityId: string;
    teamId?: string;
    formationIds?: string[];
    gameIds?: string[];
    percentiles?: IMetricWithValueRecord;
    rank?: IMetricWithRankValuesRecord;
  },
>(
  props: CellContext<T, any>,
  stat: IMetricWithDatasetMetric,
  percentilesConfig?: IPercentilesConfig,
) => {
  if (percentilesConfig) {
    const { isPercentilesActive, isPercentilesLoading, isOrder } = percentilesConfig;
    const percentilesValues = props.row.original.percentiles;
    const rankValues = props.row.original.rank;

    return (
      <MetricColumnCell
        {...props}
        value={props.getValue()}
        stat={stat}
        entityId={props.row.original.entityId}
        teamId={props.row.original.teamId}
        formationIds={props.row.original.formationIds}
        gameIds={props.row.original.gameIds}
        rowIndex={props.row.index}
        percentileValue={percentilesValues && percentilesValues[props.column.id]?.value}
        rankValues={rankValues && rankValues[props.column.id]}
        isPercentilesShown={isPercentilesActive && !isPercentilesLoading}
        isOrder={isOrder}
      />
    );
  }

  return (
    <MetricColumnCell
      {...props}
      value={props.getValue()}
      stat={stat}
      entityId={props.row.original.entityId}
      teamId={props.row.original.teamId}
      formationIds={props.row.original.formationIds}
      gameIds={props.row.original.gameIds}
    />
  );
};

const shouldAlignColumnTooltipToRight = (
  columnMetrics: IMetricWithDatasetMetric[],
  array: IMetricWithDatasetMetric[],
  index: number,
  centerTablePartWidth?: number,
) => {
  const columnWidth = centerTablePartWidth
    ? centerTablePartWidth / columnMetrics.length
    : undefined;
  const isLastIndex = index === array.length - 1;
  const shouldAlignToRight =
    columnWidth && columnWidth < 181 ? isLastIndex : columnWidth ? false : isLastIndex;
  return shouldAlignToRight;
};

export const createDynamicStatsColumnsDef = <
  T extends {
    entityId: string;
    teamId?: string;
    stats: IMetricWithValueRecord;
    percentiles?: IMetricWithValueRecord;
    rank?: IMetricWithRankValuesRecord;
  },
>(
  columnMetrics: IMetricWithDatasetMetric[] | undefined,
  columnHelper: ColumnHelper<T>,
  percentilesConfig?: IPercentilesConfig,
  centerTablePartWidth?: number,
) => {
  if (!columnMetrics) return [];

  const dynamicColumns = columnMetrics.map<ColumnDef<T, any>>((stat, index, array) => {
    const alignToRight = shouldAlignColumnTooltipToRight(
      columnMetrics,
      array,
      index,
      centerTablePartWidth,
    );

    return columnHelper.accessor(row => row.stats[stat.id]?.value, {
      id: stat.id,
      header: props => (
        <BasicColumnHeader {...props}>
          <MetricColumnHeader {...props} stat={stat} alignToRight={alignToRight} />
        </BasicColumnHeader>
      ),
      cell: props => (
        <BasicColumnCell {...props}>
          {renderMetricColumnCell(props, stat, percentilesConfig)}
        </BasicColumnCell>
      ),
    });
  });

  return dynamicColumns;
};

export const createDynamicStatsColumnsDefSimilarity = <
  T extends {
    entityId: string;
    teamId?: string;
    gameIds?: string[];
    stats: ISimilarityStats;
    percentiles?: IMetricWithValueRecord;
  },
>(
  columnMetrics: IMetricWithDatasetMetric[] | undefined,
  columnHelper: ColumnHelper<T>,
  centerTablePartWidth?: number,
) => {
  if (!columnMetrics) return [];

  const dynamicColumns = columnMetrics.reduce<ColumnDef<T, any>[]>((acc, stat, index, array) => {
    const alignToRight = shouldAlignColumnTooltipToRight(
      columnMetrics,
      array,
      index,
      centerTablePartWidth,
    );

    const base = columnHelper.accessor(row => row.stats.baseStats[stat.id]?.value, {
      id: stat.id,
      header: props => (
        <BasicColumnHeader {...props}>
          <MetricColumnHeader
            {...props}
            stat={stat}
            alignToRight={alignToRight}
            style={{ width: 'auto' }}
          />
        </BasicColumnHeader>
      ),
      cell: props => (
        <BasicColumnCell {...props}>{renderMetricColumnCell(props, stat)}</BasicColumnCell>
      ),
    });

    const rank = columnHelper.accessor(row => row.stats.rankStats[stat.id]?.value, {
      id: `rank_${stat.id}`,
      header: props => (
        <BasicColumnHeader {...props}>
          <SimilarityColumnHeader />
        </BasicColumnHeader>
      ),
      cell: props => (
        <BasicColumnCell {...props}>
          <SimilarityColumnCell value={props.getValue()} />
        </BasicColumnCell>
      ),
    });

    acc.push(base);
    acc.push(rank);

    return acc;
  }, []);

  return dynamicColumns;
};
