import { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  GamesSelectList,
  GoalNet,
  GoalNetCountContent,
  Loading,
  Playground,
  PlaygroundShotsContent,
  ToggleSelect,
} from '../../../../components';
import {
  baseShotCategoryOptions,
  goalkeeperSavesOptions,
  onNetShotCategoryOption,
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationOptions,
  shotTypeOptions,
  stickGripToggleOptions,
} from '../../../../constants';
import {
  filteredGoalkeepersFilterDataSelector,
  selectGames,
  selectGoalkeepersFilter,
  selectMainFilter,
  selectMetricParamsFilter,
  selectPlayers,
  selectShots,
  selectTeams,
  selectVideomapsFilter,
  setGoalkeeperSaves,
  setGoalkeeperStickGrip,
  setSelectedGames,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchShotsOrPasses,
  useHandleOnChange,
  useHandleOnSubmit,
  useSetUrlSearchParams,
  useShotAndPassEffects,
  useVideoCenter,
  useWindowSize,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { ISelectOption, IUrlParam } from '../../../../types';
import {
  calculateShotsTotalXGA,
  changeUrlSearchParamsMultiselectInput,
  computeResponsivePlaygroundSize,
  convertShotToPlaygroundShot,
  createClassNames,
  filterGoals,
  getGamesOptions,
  getNetZonesShotsCount,
  getShotsDangerBoxes,
  handleToggleMultiselectChange,
  parseUrlSelectedGames,
} from '../../../../utils';
import './ShotMapContent.styles.scss';

const classNames = createClassNames('shot-map-content');

export const ShotMapContent = () => {
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const { games } = useAppSelector(selectGames);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const { shotCategory } = useAppSelector(selectVideomapsFilter);
  const { stickGrip, saves } = useAppSelector(selectGoalkeepersFilter);
  const { shotDanger, shotLocation, shotType, gameActionType, netZones } =
    useAppSelector(selectMetricParamsFilter);
  const { isLoading } = useAppSelector(selectShots);
  const {
    filteredGoalkeepersGamesTotalToi,
    filteredGoalkeepersShotsGameEntity,
    filteredGoalkeepersShots,
    filteredGoalkeepersShotsExceptNetZones,
  } = useAppSelector(filteredGoalkeepersFilterDataSelector);

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);
  const netZonesShotsCount = useMemo(
    () => getNetZonesShotsCount(filteredGoalkeepersShotsExceptNetZones),
    [filteredGoalkeepersShotsExceptNetZones],
  );
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { setUrlSearchParams } = useSetUrlSearchParams();
  const {
    onChangeShotCategory,
    onChangeShotLocation,
    onChangeShotDanger,
    onChangeShotType,
    onChangeGameActionType,
    onChangeNetZones,
  } = useHandleOnChange();
  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const { fetchGamesAndToiForShotsOrPasses, fetchGoalkeepersShots } = useFetchShotsOrPasses();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    filteredGoalkeepersShotsGameEntity,
    ITranslationKeys.shots,
  );

  const isInitialMountRef = useRef(true);
  const windowSize = useWindowSize();

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
    fetchGoalkeepersShots(values);
  }, isInitialMountRef);

  useShotAndPassEffects(handleOnSubmit, isInitialMountRef, isLoading);

  useEffect(
    () => {
      const selectedGames = parseUrlSelectedGames(searchParams, gamesOptions);
      if (!selectedGames && gamesOptions.length > 0) handleChangeGame(gamesOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gamesOptions],
  );

  useEffect(() => {
    if (shotCategory.value === 'all') {
      onChangeShotCategory(onNetShotCategoryOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, 'selectedGames', setSearchParams);
  };

  const handleChangeGoalkeeperStickGrip = (option: ISelectOption) => {
    dispatch(setGoalkeeperStickGrip(option));
    setUrlSearchParams(IUrlParam.goalkeeperStickGrip, option.value);
  };

  const handleChangeGoalkeeperSaves = (option: ISelectOption) => {
    dispatch(setGoalkeeperSaves(option));
    setUrlSearchParams(IUrlParam.goalkeeperSaves, option.value);
  };

  const handleSaveMap = () => {
    // TODO: Dodělat uložení mapy
  };

  const filteredPlaygroundShots = filteredGoalkeepersShots.map(shot => {
    const playgroundSize = computeResponsivePlaygroundSize(windowSize.width);
    return convertShotToPlaygroundShot(shot, teams.byId, players.byId, [], playgroundSize.width);
  });

  if (isLoading) return <Loading />;

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, 1);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  return (
    <div className={classNames()} data-testid='goalkeepers-page__shots-content'>
      <div className={classNames('main')}>
        <GamesSelectList
          selected={selectedGames}
          options={gamesOptions}
          gameRecord={games.byId}
          teamRecord={teams.byId}
          shouldCheckFullBody={false}
          gameEntities={filteredGoalkeepersShotsGameEntity}
          onChange={newValue => handleChangeGame(newValue)}
          onItemContentActionClick={gameId => playGameVideos(gameId)}
          onPlaySelectedGamesVideos={playSelectedGamesVideos}
          hasSelectAllOption
        />
        <div className={classNames('main__playground-wrapper')}>
          <Playground
            toi={filteredGoalkeepersGamesTotalToi}
            c={filteredGoalkeepersShots.length}
            xG={calculateShotsTotalXGA(filteredGoalkeepersShots)}
            g={filterGoals(filteredGoalkeepersShots).length}
            boxes={getShotsDangerBoxes(filteredGoalkeepersShots)}
            boxListHeading={ITranslationKeys.shotsDanger}
            isResponsive
            isGoalkeeper
            renderContent={() => (
              <PlaygroundShotsContent shots={filteredPlaygroundShots} onPlayClick={playVideos} />
            )}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.shotCategory} />
            <ToggleSelect
              selected={shotCategory}
              options={baseShotCategoryOptions}
              onChange={newOption => onChangeShotCategory(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.saves} />
            <ToggleSelect
              selected={saves}
              options={goalkeeperSavesOptions}
              onChange={newOption => handleChangeGoalkeeperSaves(newOption)}
              hasOnlyOneColumn
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotTypes} />
            <ToggleSelect
              selected={shotType}
              options={shotTypeOptions}
              onChange={newOption => onChangeShotType(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotsDanger} />
            <ToggleSelect
              options={shotDangerOptions}
              selected={shotDanger}
              hasOnlyOneColumn
              onChange={option =>
                onChangeShotDanger(
                  handleToggleMultiselectChange(option, shotDangerOptions, shotDanger),
                )
              }
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.opponentsStick} />
            <ToggleSelect
              selected={stickGrip}
              options={stickGripToggleOptions}
              onChange={newOption => handleChangeGoalkeeperStickGrip(newOption)}
              twoColumns
            />
          </div>
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.attackType} />
            <ToggleSelect
              selected={gameActionType}
              options={shotGameActionTypeOptions}
              onChange={newOption => onChangeGameActionType(newOption)}
              hasOnlyOneColumn
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.shotLocation} />
            <ToggleSelect
              selected={shotLocation}
              options={shotLocationOptions}
              onChange={newOption => onChangeShotLocation(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.netZones} />
            <GoalNet
              onZoneChange={newValue => onChangeNetZones(newValue, netZones)}
              activeZones={netZones}
              isClickable
              variant='black'
              content={<GoalNetCountContent netZonesShotsCount={netZonesShotsCount} />}
              disableContentPointerEvents
            />
            <div className={classNames('main__form-box__save-button')}>
              <Button
                label={ITranslationKeys.saveMap}
                iconComponent={<DownloadIcon />}
                onClick={handleSaveMap}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
