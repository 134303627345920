import { FC, ReactNode } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import { ISelectOption } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { TcmsSortableItem } from '../TcmsItem';
import './TcmsList.styles.scss';

export interface ITcmsListProps {
  /** TCMS sortable items to be mapped for user interaction */
  options: ISelectOption[];
  /** Selected item (only when onItemClick callback is provided) */
  selected?: ISelectOption;
  /** Size of the component */
  size?: 'big' | 'medium' | 'small';
  /** Renders elements at the right side of the items components (with some actions) */
  itemChildrenRenderer?: (option: ISelectOption) => ReactNode;
  /** Callback to be invoked when user clicks on a TcmsItem */
  onItemClick?: (option: ISelectOption) => void;
}

const classNames = createClassNames('tcms-list');

export const TcmsList: FC<ITcmsListProps> = ({
  options,
  selected,
  size = 'big',
  itemChildrenRenderer,
  onItemClick,
}) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          size,
        }),
      })}
      data-testid='tcms-list'
    >
      {options.map((option, index) => (
        <TcmsSortableItem
          key={option.value}
          index={index}
          value={option.value}
          selected={option.value === selected?.value}
          onClick={() => onItemClick?.(option)}
          label={option.label}
          children={itemChildrenRenderer?.(option)}
        />
      ))}
    </div>
  );
};

export const TcmsSortableList = SortableContainer<ITcmsListProps>((props: ITcmsListProps) => (
  <TcmsList {...props} />
));
