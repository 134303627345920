import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IUserDatasetsState } from '../../types';
import { transformStringToLanguageType } from '../../utils';
import {
  changeUserDatasetMetrics,
  changeUserDatasetName,
  createUserDataset,
  deleteUserDatasetById,
  getUserDatasetById,
  getUserDatasets,
} from './userDatasetsActions';

const initialState: IUserDatasetsState = {
  byId: {},
  allIds: [],
  isLoading: false,
  isUserDatasetsLoaded: false,
  error: undefined,
};

export const userDatasetsSlice = createSlice({
  name: 'userDatasets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Get list of user datasets
      .addCase(getUserDatasets.pending, state => {
        state.isLoading = true;
        state.error = undefined;
        state.isUserDatasetsLoaded = false;
        state.allIds = [];
      })
      .addCase(getUserDatasets.fulfilled, (state, action) => {
        action.payload.forEach(dataset => {
          state.byId[dataset.id] = dataset;
          state.allIds.push(dataset.id);
        });
        state.isLoading = false;
        state.isUserDatasetsLoaded = true;
      })
      .addCase(getUserDatasets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getUserDatasetById.fulfilled, state => {
        state.isLoading = false;
      })
      // Next action case
      .addCase(createUserDataset.fulfilled, (state, action) => {
        state.byId[action.payload.id] = {
          id: action.payload.id,
          datasetMetrics: action.meta.arg.datasetMetrics,
          entity: action.meta.arg.entity,
          name: transformStringToLanguageType(action.meta.arg.name),
        };
        state.isLoading = false;
      })
      // Next action case
      .addCase(deleteUserDatasetById.fulfilled, (state, action) => {
        const userDataset = state.byId[action.meta.arg];
        if (userDataset) {
          delete state.byId[userDataset.id];
          state.allIds = state.allIds.filter(id => id !== userDataset.id);
        }
        state.isLoading = false;
      })
      // Next action case
      .addCase(changeUserDatasetName.fulfilled, (state, action) => {
        const userDataset = state.byId[action.meta.arg.datasetId];
        if (userDataset) {
          state.byId[userDataset.id].name = transformStringToLanguageType(action.meta.arg.newName);
        }
        state.isLoading = false;
      })
      // Next action case
      .addCase(changeUserDatasetMetrics.fulfilled, (state, action) => {
        const userDataset = state.byId[action.meta.arg.datasetId];
        if (userDataset) {
          state.byId[userDataset.id].datasetMetrics = action.meta.arg.newDatasetMetrics;
        }
        state.isLoading = false;
      })
      // Shared pending and rejected cases
      .addMatcher(
        isAnyOf(
          getUserDatasetById.pending,
          createUserDataset.pending,
          deleteUserDatasetById.pending,
          changeUserDatasetName.pending,
          changeUserDatasetMetrics.pending,
        ),
        state => {
          state.isLoading = true;
          state.error = undefined;
        },
      )
      .addMatcher(
        isAnyOf(
          getUserDatasetById.rejected,
          createUserDataset.rejected,
          deleteUserDatasetById.rejected,
          changeUserDatasetName.rejected,
          changeUserDatasetMetrics.rejected,
        ),
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        },
      );
  },
});

export const userDatasetsReducer = userDatasetsSlice.reducer;
