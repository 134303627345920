import { Control, useFieldArray } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { trendTimePeriodCustomOption } from '../constants';
import {
  addTrendTableDataRow,
  deleteTrendTableDataRow,
  resetTableTimePeriodPlayerStats,
  selectTrend,
  setSelectedTrendMetric,
  setTrendTableDataBy,
  setTrendTableTimePeriodType,
} from '../features';
import { ISelectOption, ITimePeriodTableForm } from '../types';

export const useTrendContent = (control: Control<ITimePeriodTableForm, any>) => {
  const dispatch = useAppDispatch();
  const { tableTimePeriodType, tableDataBy } = useAppSelector(selectTrend);

  const { fields, append, remove } = useFieldArray<ITimePeriodTableForm>({
    control,
    name: 'timePeriodByGames',
  });

  const handleOnChangeTimePeriodType = (option: ISelectOption) => {
    if (option.value === 'custom') {
      dispatch(resetTableTimePeriodPlayerStats());
    }

    if (option.value === 'months' && tableDataBy !== 'calendar') {
      dispatch(setTrendTableDataBy('calendar'));
    }

    if (option.value !== 'months' && tableDataBy === 'calendar') {
      dispatch(setTrendTableDataBy('playerMatches'));
    }

    dispatch(setTrendTableTimePeriodType(option));
  };

  const handleMetricChange = (newValue: ISelectOption) => {
    dispatch(setSelectedTrendMetric(newValue));
  };

  const handleAddRow = () => {
    append({ from: undefined, to: undefined });
    dispatch(addTrendTableDataRow());

    if (tableTimePeriodType && tableTimePeriodType.value !== 'custom') {
      dispatch(setTrendTableTimePeriodType(trendTimePeriodCustomOption));
    }
  };

  const handleDeleteRow = () => {
    remove(fields.length - 1);
    dispatch(deleteTrendTableDataRow());

    if (tableTimePeriodType && tableTimePeriodType.value !== 'custom') {
      dispatch(setTrendTableTimePeriodType(trendTimePeriodCustomOption));
    }
  };

  return {
    handleOnChangeTimePeriodType,
    handleMetricChange,
    handleAddRow,
    handleDeleteRow,
  };
};
