import { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Caption,
  FaceOffPlayground,
  FaceOffPlaygroundContent,
  GamesSelectList,
  Loading,
  ToggleSelect,
} from '../../../../components';
import {
  DISABLED_TABS_ON_OFF,
  faceoffResultOptions,
  faceoffsFollowedOptions,
  stickGripToggleOptions,
} from '../../../../constants';
import {
  filteredFaceoffsDataSelector,
  selectFaceoffs,
  selectFaceoffsFilter,
  selectFormations,
  selectGames,
  selectMainFilter,
  selectTeams,
  setFaceoffFollowedBy,
  setFaceoffResult,
  setFaceoffStickGrip,
  setSelectedGames,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchFaceoffs,
  useFetchShotsOrPasses,
  useGetFaceoffPositions,
  useHandleOnSubmit,
  useSetUrlSearchParams,
  useShotAndPassEffects,
  useVideoCenter,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { INavigationContentKeys, ISelectOption, IUrlParam } from '../../../../types';
import {
  changeUrlSearchParamsMultiselectInput,
  createClassNames,
  getGamesOptions,
} from '../../../../utils';
import './FaceoffsContent.styles.scss';
import { FaceoffsStatBoxCategoryWrapper } from './components';

const classNames = createClassNames('faceoffs-content');

export const FaceoffsContent = () => {
  const teams = useAppSelector(selectTeams);
  const { games } = useAppSelector(selectGames);
  const { teammateState } = useAppSelector(selectFormations);
  const { selectedGames, selectedPlayerItems } = useAppSelector(selectMainFilter);
  const { filteredFaceoffsGameEntity } = useAppSelector(filteredFaceoffsDataSelector);
  const { isLoading } = useAppSelector(selectFaceoffs);
  const { stickGrip, faceoffResult, faceoffFollowedBy } = useAppSelector(selectFaceoffsFilter);

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const { setUrlSearchParams } = useSetUrlSearchParams();
  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const { fetchGamesAndToiForShotsOrPasses } = useFetchShotsOrPasses();
  const { fetchFaceoffs, fetchFaceoffStatsOfPlayers } = useFetchFaceoffs();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    filteredFaceoffsGameEntity,
    ITranslationKeys.faceoffs,
  );
  const faceoffPlayerInfoByPositionRecord = useGetFaceoffPositions(
    DISABLED_TABS_ON_OFF.includes(INavigationContentKeys.faceoffs),
  );

  const isInitialMountRef = useRef(true);

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamesAndToiForShotsOrPasses(values);
    fetchFaceoffs(values);
    fetchFaceoffStatsOfPlayers(values);
  }, isInitialMountRef);

  const handleOnSubmitFaceoffsOnly = useHandleOnSubmit(values => {
    fetchFaceoffs(values);
  }, isInitialMountRef);

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, 'selectedGames', setSearchParams);
  };

  const handleFaceoffResult = (option: ISelectOption) => {
    dispatch(setFaceoffResult(option));
    setUrlSearchParams(IUrlParam.faceoffResult, option.value);
  };

  const handleFaceoffStickGrip = (option: ISelectOption) => {
    dispatch(setFaceoffStickGrip(option));
    setUrlSearchParams(IUrlParam.faceoffStickGrip, option.value);
  };

  const handleFaceoffFollowedBy = (option: ISelectOption) => {
    dispatch(setFaceoffFollowedBy(option));
    setUrlSearchParams(IUrlParam.faceoffFollowedBy, option.value);
  };

  useShotAndPassEffects(handleOnSubmit, isInitialMountRef, isLoading);

  useEffect(() => {
    handleOnSubmitFaceoffsOnly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayerItems]);

  if (isLoading || teammateState.isLoading) return <Loading />;

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, 1);
  if (errorInfoBox) return errorInfoBox;

  return (
    <div className={classNames()} data-testid='goalkeepers-page__shots-content'>
      <div className={classNames('main')}>
        <GamesSelectList
          selected={selectedGames}
          options={gamesOptions}
          gameRecord={games.byId}
          teamRecord={teams.byId}
          shouldCheckFullBody={false}
          selectedPlayerItems={selectedPlayerItems}
          gameEntities={filteredFaceoffsGameEntity}
          onChange={newValue => handleChangeGame(newValue)}
          onItemContentActionClick={gameId => playGameVideos(gameId)}
          onPlaySelectedGamesVideos={playSelectedGamesVideos}
          hasSelectAllOption
        />
        <div className={classNames('main__playground-wrapper')}>
          <FaceOffPlayground
            content={
              <FaceOffPlaygroundContent
                faceoffPlayerInfoByPositionRecord={faceoffPlayerInfoByPositionRecord}
                onBoxClick={playVideos}
              />
            }
          />
          <FaceoffsStatBoxCategoryWrapper
            faceoffPlayerInfoByPositionRecord={faceoffPlayerInfoByPositionRecord}
            selectedPlayerItems={selectedPlayerItems}
            onBoxClick={playVideos}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.faceoffResult} />
            <ToggleSelect
              selected={faceoffResult}
              options={faceoffResultOptions}
              onChange={newOption => handleFaceoffResult(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.opponentsStick} />
            <ToggleSelect
              selected={stickGrip}
              options={stickGripToggleOptions}
              onChange={newOption => handleFaceoffStickGrip(newOption)}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.faceoffFollowedBy} />
            <ToggleSelect
              selected={faceoffFollowedBy}
              options={faceoffsFollowedOptions}
              onChange={newOption => handleFaceoffFollowedBy(newOption)}
              hasOnlyOneColumn
            />
          </div>
        </div>
      </div>
    </div>
  );
};
