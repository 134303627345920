import { FC, useState } from 'react';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
} from 'recharts';

import { ArrowNarrowIcon } from '../../../icons';
import {
  IAnalyticsType,
  IBarMouseEnterData,
  IChartTooltipEntity,
  ILeftRightArrowSettings,
  ILogStackedBarChartData,
  ILogStackedBarChartUsers,
  ITrackingTimeInterval,
  ITrackingUserType,
} from '../../../types';
import { createClassNames } from '../../../utils';
import { BarChartTooltip } from '../BarChartTooltip';
import { getChartGradientDef, getTeamMediaOtherStackedBars } from '../utils';
import { BarTick } from './BarTick';
import './LogStackedBarChart.styles.scss';

export interface ILogStackedBarChartProps {
  /** Data to display. */
  data: ILogStackedBarChartData[];
  /** Heigh of component. */
  height?: string | number;
  /** Enables right and left arrow and has settings object for it. */
  leftRightArrowSettings?: ILeftRightArrowSettings;
  /** Analytics type. */
  analyticsType: IAnalyticsType;
  /** Time interval to display correct tick. */
  timeInterval?: ITrackingTimeInterval;
  /** Number of top players to display in tooltip. */
  tooltipTopPlayersCount?: number;
}

const classNames = createClassNames('log-stacked-bar-chart');

export const LogStackedBarChart: FC<ILogStackedBarChartProps> = ({
  data,
  height = 352,
  analyticsType,
  leftRightArrowSettings,
  timeInterval,
  tooltipTopPlayersCount = 3,
}) => {
  const [mouseEnterData, setMouseEnterData] = useState<IBarMouseEnterData | undefined>();

  return (
    <div className={classNames()}>
      {renderArrows(leftRightArrowSettings)}
      <ResponsiveContainer width='100%' height={height}>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 30,
          }}
          barSize={12}
        >
          {getChartGradientDef()}
          <CartesianGrid vertical={false} horizontal={false} fill='url(#chart-gradient)' />
          <XAxis
            dataKey='xAxisTickValue'
            tickLine={false}
            interval={0}
            axisLine={false}
            padding={{ left: 46, right: 46 }}
            tick={props => <BarTick {...props} timeInterval={timeInterval} />}
          />
          <Tooltip
            cursor={{ fill: 'none' }}
            content={(props: TooltipProps<number, string>) => {
              const payload = props.payload;
              if (!payload?.[0]) {
                return null;
              }

              const payloadUsers = (payload[0].payload as ILogStackedBarChartData).users;
              const dataKey =
                mouseEnterData?.tooltipPayload[0].dataKey ?? decideAlternativeDataKey(payloadUsers);
              const dataPayload = payload?.find(item => item.dataKey === dataKey);

              if (!props.active || !payload?.length || !dataPayload?.payload) {
                return null;
              }

              const { users }: ILogStackedBarChartData = dataPayload.payload;
              const filteredUsers = users[dataKey as keyof ILogStackedBarChartUsers]
                .map<IChartTooltipEntity>(user => ({
                  name: user.name,
                  analyticsCount: user.analytics[analyticsType],
                }))
                .slice(0, tooltipTopPlayersCount);

              const value = dataPayload.value;
              const total = payload.reduce((acc, item) => (acc += item.value ?? 0), 0);

              return (
                <BarChartTooltip
                  users={filteredUsers}
                  displayAlsoTotalValue
                  displayCategoryOther={false}
                  {...{ dataKey, value, total, analyticsType }}
                />
              );
            }}
          />
          {getTeamMediaOtherStackedBars(
            'horizontal',
            data => setMouseEnterData(data),
            () => setMouseEnterData(undefined),
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const renderArrows = (leftRightArrowSettings?: ILeftRightArrowSettings) => {
  if (!leftRightArrowSettings) {
    return null;
  }

  const { enableLeft, enableRight, handleClickLeft, handleClickRight } = leftRightArrowSettings;

  return (
    <>
      <ArrowNarrowIcon
        className={classNames('arrow', { disabled: !enableLeft, left: true })}
        onClick={handleClickLeft}
      />
      <ArrowNarrowIcon
        className={classNames('arrow', { disabled: !enableRight })}
        onClick={handleClickRight}
      />
    </>
  );
};

const decideAlternativeDataKey = (users: ILogStackedBarChartUsers) => {
  if (users.other.length) {
    return ITrackingUserType.other;
  }

  if (!users.team.length) {
    return ITrackingUserType.media;
  }

  if (!users.media.length) {
    return ITrackingUserType.team;
  }

  if (users.media.length < users.team.length) {
    return ITrackingUserType.media;
  }
  return ITrackingUserType.team;
};
