import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Caption, SelectInput, TcmsButton } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { ISelectOption } from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import './HelpManagementSectionPicker.styles.scss';

export interface IHelpManagementSectionPickerProps {
  activeSection: ISelectOption | undefined;
  activeHelpCategoryType: ISelectOption | null;
  activeSubsection: ISelectOption | null;
  helpContentTypeOptions: ISelectOption[];
  subsectionOptions: ISelectOption[];
  onActiveSubsectionChange: (newValue: ISelectOption) => void;
  onHelpCategoryTypeClick: (newValue: ISelectOption) => void;
}

const classNames = createClassNames('help-management-section-picker');

export const HelpManagementSectionPicker: FC<IHelpManagementSectionPickerProps> = ({
  activeSection,
  activeHelpCategoryType,
  activeSubsection,
  helpContentTypeOptions,
  subsectionOptions,
  onActiveSubsectionChange,
  onHelpCategoryTypeClick,
}) => {
  const { t } = useTranslation();

  if (!activeSection)
    return <div className='content-info-box'>{t(ITranslationKeys.noDataForSelected)}</div>;

  return (
    <div className={classNames()}>
      <h3>{t(activeSection.label)}</h3>
      <div>
        <Caption label={ITranslationKeys.sectionEditation} variant='tcms' color='slate' />
        <SelectInput
          onChange={newValue => onActiveSubsectionChange(newValue)}
          selected={activeSubsection ?? undefined}
          options={subsectionOptions}
          placeholder={ITranslationKeys.defaultSelectPlaceholder}
          variant='filter'
        />
      </div>
      <div className={classNames('edit-type-buttons')}>
        <Caption label={ITranslationKeys.helpContentEditation} variant='tcms' color='slate' />
        {helpContentTypeOptions.map((option, index) => (
          <TcmsButton
            key={index}
            label={option.label}
            variant='filled'
            color={activeHelpCategoryType === option ? 'blue' : 'default'}
            disabled={!activeSubsection}
            onClick={() => onHelpCategoryTypeClick(option)}
          />
        ))}
      </div>
    </div>
  );
};
