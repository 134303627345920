import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortEndHandler } from 'react-sortable-hoc';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { Caption, SelectInput, TcmsSortableDropdownList } from '../../../../../../components';
import { netZoneOptions } from '../../../../../../constants';
import {
  dataSettingsFilterAllDataSelector,
  selectDataSettingsFilter,
  setDataSettingsSelectedGroup,
} from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import {
  IDataTypeParameters,
  IMetricCategory,
  INetZoneUnion,
  ISelectOption,
  ITcmsItemWithDropdownProps,
} from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import { transformDataSettingsToSelectOptions } from '../../utils';
import './DefinedTemplatesDataTypes.styles.scss';

export interface IDefinedTemplatesDataTypesProps {
  options: ISelectOption[];
  metricsBySection: IMetricCategory;
  onListChange: SortEndHandler;
}

const classNames = createClassNames('defined-templates-data-types');
const dropdownClassNames = createClassNames('defined-templates-dropdown-content');

export const DefinedTemplatesDataTypes: FC<IDefinedTemplatesDataTypesProps> = ({
  options,
  metricsBySection,
  onListChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    selectedGroup,
    gameActionType,
    shotType,
    shotDanger,
    shotLocation,
    netZone,
    puckGainType,
    entryType,
    exitType,
    enterExitLocation,
  } = useAppSelector(selectDataSettingsFilter);
  const { filteredByParameters } = useAppSelector(state =>
    dataSettingsFilterAllDataSelector(state, metricsBySection),
  );

  if (!options.length) return <div className={classNames()}></div>;

  const eventParameters = transformDataSettingsToSelectOptions(
    gameActionType,
    shotType,
    shotDanger,
    shotLocation,
    netZone,
    puckGainType,
    entryType,
    exitType,
    enterExitLocation,
  );

  const mappedOptions = options.map<ITcmsItemWithDropdownProps>(option => ({
    label: option.label as ITranslationKeys,
    value: option.value,
    dropdownContent: (
      <div className={dropdownClassNames()}>
        {eventParameters.map(dataType => {
          const display =
            selectedGroup &&
            filteredByParameters.some(
              metric =>
                metric.metricGroups[selectedGroup.value].metricParameters[dataType.parameter],
            );
          if (!display) return null;
          const selected = getSelectedValue(dataType, dataType.selectedOption);

          return (
            <div key={dataType.parameter} className={dropdownClassNames('body')}>
              <div className={dropdownClassNames('body__item')}>
                <Caption label={dataType.label} />
                <SelectInput
                  options={dataType.options}
                  selected={selected}
                  onChange={option => handleValueChange(dataType, option)}
                  variant='tcms'
                />
              </div>
            </div>
          );
        })}
      </div>
    ),
  }));

  const handleValueChange = (dataType: IDataTypeParameters, option: ISelectOption) => {
    if (dataType.parameter === 'net_zones') {
      const setFunction = dataType.setFunction as ActionCreatorWithPayload<
        INetZoneUnion | undefined,
        string
      >;
      dispatch(setFunction(option.value === 'all' ? undefined : (option.value as INetZoneUnion)));
      return;
    }

    const setFunction = dataType.setFunction as ActionCreatorWithPayload<ISelectOption, string>;
    dispatch(setFunction(option));
  };

  const handleDropdownItemExpansionClick = (option: ITcmsItemWithDropdownProps) => {
    if (selectedGroup?.value === option.value) {
      dispatch(setDataSettingsSelectedGroup());
      return;
    }

    eventParameters.forEach(dataType => {
      if (dataType.parameter === 'net_zones') {
        handleValueChange(dataType, netZoneOptions[0]);
        return;
      }
      handleValueChange(dataType, dataType.options[0]);
    });
    dispatch(setDataSettingsSelectedGroup(options.find(opt => opt.value === option.value)));
  };

  return (
    <div className={classNames()}>
      <h3>{t(ITranslationKeys.dataTypes)}</h3>
      <TcmsSortableDropdownList
        options={mappedOptions}
        selectedOption={options.find(option => option.value === selectedGroup?.value)}
        onSortEnd={onListChange}
        onDropdownItemExpansionClick={handleDropdownItemExpansionClick}
        helperClass='sortable-helper'
        lockAxis='y'
        useDragHandle
        isSmall
      />
    </div>
  );
};

/** Returns selected option of SelectInput component based on dataType parameter */
const getSelectedValue = (
  dataType: IDataTypeParameters,
  selectedOption: ISelectOption | INetZoneUnion | undefined,
): ISelectOption => {
  if (dataType.parameter !== 'net_zones') {
    return selectedOption as ISelectOption;
  }

  return (
    netZoneOptions.find(netZoneOption => netZoneOption.value === selectedOption) ??
    netZoneOptions[0]
  );
};
