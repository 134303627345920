import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChartSummary,
  LogStackedBarChart,
  Skeleton,
  TimeInterval,
} from '../../../../../../components';
import { useLeftRightOffset } from '../../../../../../hooks';
import { ITranslationKeys } from '../../../../../../i18n/types';
import {
  IAnalyticsType,
  IDateRangeString,
  IRoleLogAggregationDateRecord,
  ITrackingTimeInterval,
} from '../../../../../../types';
import { createClassNames, formatDateByTimeInterval } from '../../../../../../utils';
import { IOverviewComponentsProps } from '../types';
import './RoleLogOverview.styles.scss';
import { useChartData } from './useChartData';

interface IRoleLogOverviewProps extends IOverviewComponentsProps {
  /** Loaded data */
  data: IRoleLogAggregationDateRecord | undefined;
  /** Error */
  error: unknown;
  /** Are there more data to load? */
  hasMoreData: boolean;
  /** Is data fetching? */
  isFetching: boolean;
  /** Time interval to group data by days, weeks, months or seasons. */
  timeInterval: ITrackingTimeInterval;
  /** Number of top players to display in tooltip. */
  tooltipTopPlayersCount: number;
  /** Callback function called on time interval change. */
  onTimeIntervalChange: (timeInterval: ITrackingTimeInterval) => void;
  /** Set page to load next chunk of data. */
  setPage: Dispatch<SetStateAction<number>>;
}

const COUNT_OF_DATA = 7;
const PRELOADED_COUNT = 2 * COUNT_OF_DATA;

const classNames = createClassNames('role-log-overview');

export const RoleLogOverview: FC<IRoleLogOverviewProps> = ({
  data,
  error,
  isFetching,
  submitValues,
  timeInterval,
  tooltipTopPlayersCount,
  onTimeIntervalChange,
  setPage,
  hasMoreData,
}) => {
  const { selectedAnalytics } = submitValues;
  const analyticsType = selectedAnalytics.value as IAnalyticsType;

  const { t } = useTranslation();

  const chartData = useChartData(data, analyticsType);
  const { offset, setOffset, ...leftRightArrowSettings } = useLeftRightOffset(chartData.length, {
    reverse: true,
  });

  useEffect(
    () => {
      if (hasMoreData && offset > 0 && offset + PRELOADED_COUNT > chartData.length) {
        setPage(prevPage => prevPage + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offset],
  );

  useEffect(() => {
    setPage(0);
    setOffset(0);
  }, [timeInterval, setPage, setOffset]);

  if (isFetching) {
    return (
      <div className={classNames()}>
        <Skeleton />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={classNames({ error: true })}>{t(ITranslationKeys.defaultErrorMessage)}</div>
    );
  }

  const sliceFromIndex = chartData.length - offset - COUNT_OF_DATA;
  const slicedData = chartData.slice(
    sliceFromIndex > 0 ? sliceFromIndex : 0,
    chartData.length - offset,
  );
  const total = slicedData.reduce<number>(
    (acc, item) => acc + item.team + item.media + item.other,
    0,
  );
  const dateFromTo: IDateRangeString = {
    from: slicedData[0]?.date,
    to: slicedData[slicedData.length - 1]?.date,
  };

  const dateFormatter = (dateString: string) => formatDateByTimeInterval(dateString, timeInterval);

  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        <ChartSummary
          header={total}
          dateFormatter={dateFormatter}
          {...{ dateFromTo, selectedAnalytics }}
        />
        <TimeInterval timeInterval={timeInterval} onTimeIntervalChange={onTimeIntervalChange} />
      </div>
      <LogStackedBarChart
        data={slicedData}
        height={229}
        analyticsType={analyticsType}
        leftRightArrowSettings={leftRightArrowSettings}
        {...{ timeInterval, tooltipTopPlayersCount }}
      />
    </div>
  );
};
