import { IPlaygroundZoneStats } from '../components';
import { DISABLED_TABS_ON_OFF, SELECT_PLAYERS_INPUT_COLORS } from '../constants';
import { ITranslationKeys } from '../i18n/types';
import {
  IFormation,
  IGameEntity,
  IGoalStatsBoxProps,
  INavigationContentKeys,
  IPlayerRecord,
  IPlaygroundBarBox,
  ISelectOption,
  ISelectedPlayerItem,
  IStatToDisplay,
  IVideoInfo,
  IZoneEntity,
  IZoneEntityOptions,
} from '../types';
import { roundNumberTo2Decimals } from './number.utils';
import { isAtLeastOnePlayerOn } from './players.utils';

export const getMetricsFromBoxes = (
  boxes: IPlaygroundZoneStats[],
  countLabel: string,
  onClick: (array: IVideoInfo[]) => void,
): IGoalStatsBoxProps[] =>
  boxes.map(box => ({
    items: [
      { value: box.count, label: countLabel },
      { value: parseFloat(box.xG).toFixed(2), label: 'xG' },
    ],
    color: box.color ?? 'default',
    onClick: () => onClick(box.videoInfo),
  }));

const getPlaygroundZoneStats = (
  count: number | undefined,
  xG: number | undefined,
  playerRecord: IPlayerRecord,
  index?: number,
  zoneEntites?: IZoneEntity[],
): IPlaygroundZoneStats => {
  const color = index !== undefined ? SELECT_PLAYERS_INPUT_COLORS[index] : 'default';
  if (count === undefined || xG === undefined) {
    return {
      count: '0',
      xG: '0',
      color,
      videoInfo: [],
    };
  }

  const videoInfo: IVideoInfo[] = zoneEntites
    ? zoneEntites.reduce<IVideoInfo[]>((acc, entity) => {
        const player = playerRecord[entity.playerId];
        if (!player) {
          console.warn('[getPlaygroundZoneStats] Player with id', entity.id, 'was not found.');
          return acc;
        }

        acc.push({
          playerId: entity.playerId,
          time: entity.time,
          videoId: entity.videoId,
          videoTime: entity.videoTime,
          matchDate: entity.matchDate,
          matchId: entity.matchId,
        });
        return acc;
      }, [])
    : [];

  return {
    count: count.toString(),
    xG: xG > 0 ? roundNumberTo2Decimals(xG).toString() : '0',
    color,
    videoInfo,
  };
};

const computeZoneEntityXg = (zoneEntites: IZoneEntity[]) => {
  if (!zoneEntites || zoneEntites.length === 0) return 0;
  const isDumpout = zoneEntites[0].isDumpoutOrDumpin;

  if (isDumpout) {
    const xG = zoneEntites.reduce<number>((xgAcc, zoneEntity) => {
      if (zoneEntity.xG) xgAcc += Number(zoneEntity.xG);
      return xgAcc;
    }, 0);

    return xG;
  }

  const xG = zoneEntites.reduce<number>((xgAcc, zoneEntity) => {
    if (zoneEntity.xG) xgAcc += Number(zoneEntity.xG);
    return xgAcc;
  }, 0);

  return xG;
};

const getZonePlaygroundBoxProps = (
  zoneEntites: IZoneEntity[],
  playerRecord: IPlayerRecord,
): IPlaygroundZoneStats => {
  const xG = computeZoneEntityXg(zoneEntites);
  const playgroundZoneAttr = getPlaygroundZoneStats(
    zoneEntites.length,
    xG,
    playerRecord,
    undefined,
    zoneEntites,
  );

  return playgroundZoneAttr;
};

const getPlayerZonePlaygroundBoxProps = (
  zoneEntites: IZoneEntity[],
  selectedPlayers: ISelectedPlayerItem[],
  isValidFormation: boolean,
  playerRecord: IPlayerRecord,
): IPlaygroundZoneStats[] => {
  if (!isValidFormation) return [getZonePlaygroundBoxProps(zoneEntites, playerRecord)];

  const barBoxesForFormation = selectedPlayers.reduce<IPlaygroundZoneStats[]>(
    (acc, selectedPlayer) => {
      const playersZoneEntities = zoneEntites.filter(
        entity => entity.playerId === selectedPlayer.selectedPlayer?.value,
      );
      const xG = computeZoneEntityXg(playersZoneEntities);
      const playgroundZoneAttr = getPlaygroundZoneStats(
        playersZoneEntities.length,
        xG,
        playerRecord,
        Number(selectedPlayer.id),
        playersZoneEntities,
      );

      acc.push(playgroundZoneAttr);
      return acc;
    },
    [],
  );

  return barBoxesForFormation;
};

const getPlayerPlaygroundBoxPropsForSide = (
  zoneEntities: IZoneEntity[] | undefined,
  formation: IFormation[],
  selectedEntity: IZoneEntityOptions,
  filteredPlayers: ISelectedPlayerItem[],
  playerRecord: IPlayerRecord,
): IPlaygroundZoneStats[] => {
  const isValidFormation = formation.length > 0 && selectedEntity === 'formation';

  if (!zoneEntities || zoneEntities.length === 0) {
    if (!isValidFormation) return [getPlaygroundZoneStats(0, 0, playerRecord)];
    return filteredPlayers.map(item => getPlaygroundZoneStats(0, 0, playerRecord, Number(item.id)));
  }

  return getPlayerZonePlaygroundBoxProps(
    zoneEntities,
    filteredPlayers,
    isValidFormation,
    playerRecord,
  );
};

const getTotalCount = (boxes: IPlaygroundZoneStats[][], isValidFormation: boolean): number[] => {
  if (!isValidFormation) {
    const left = boxes[0];
    const center = boxes[1];
    const right = boxes[2];
    if (left.length === 0 || center.length === 0 || right.length === 0) return [0];

    let value = 0;
    value += left[0].count ? Number(left[0].count) : 0;
    value += center[0].count ? Number(center[0].count) : 0;
    value += right[0].count ? Number(right[0].count) : 0;

    return [value];
  }

  const value = boxes.reduce<number[]>((acc, box) => {
    box.forEach((item, index) => {
      const currentValue = Number(item.count);
      if (acc[index] === undefined) acc[index] = 0;
      acc[index] += currentValue;
    });
    return acc;
  }, []);

  return value;
};

const getSummaryBoxXg = (
  zoneEntities: IZoneEntity[],
  formation: IFormation[],
  entity: string,
): number[] => {
  const onPlayers = formation.filter(player => player.on);
  const isPlayerSelected = entity === 'player';
  if (isPlayerSelected) {
    const xG = zoneEntities.reduce<number[]>((acc, zoneEntity) => {
      onPlayers.forEach((player, index) => {
        if (acc[index] === undefined) acc[index] = 0;

        if (zoneEntity.playerId === player.player) {
          acc[index] += zoneEntity.xG ? Number(zoneEntity.xG) : 0;
        }
      });
      return acc;
    }, []);
    return xG;
  }

  const xG = zoneEntities.reduce<number>((acc, zoneEntity) => {
    acc += zoneEntity.xG ? Number(zoneEntity.xG) : 0;
    return acc;
  }, 0);
  return [xG];
};

const getTotalVideoInfoArrays = (
  boxes: IPlaygroundZoneStats[][],
  isValidFormation: boolean,
): IVideoInfo[][] => {
  if (!isValidFormation) {
    const left = boxes[0];
    const center = boxes[1];
    const right = boxes[2];
    if (left.length === 0 || center.length === 0 || right.length === 0) return [];

    const videoInfoArray = [...left[0].videoInfo, ...center[0].videoInfo, ...right[0].videoInfo];
    return [videoInfoArray];
  }

  const videoInfoArray = boxes.reduce<IVideoInfo[][]>((acc, box) => {
    box.forEach((item, index) => {
      const videoInfo = item.videoInfo;
      if (acc[index] === undefined) acc[index] = [];
      acc[index] = [...acc[index], ...videoInfo];
    });
    return acc;
  }, []);

  return videoInfoArray;
};

const getTotalMetric = (
  formation: IFormation[],
  selectedEntity: IZoneEntityOptions,
  selectedPlayerItems: ISelectedPlayerItem[],
  filteredPlayers: ISelectedPlayerItem[],
  boxes: IPlaygroundZoneStats[][],
  zoneEntities: IZoneEntity[],
  entity: string,
  playerRecord: IPlayerRecord,
): IPlaygroundZoneStats[] => {
  const disabledOnOff = DISABLED_TABS_ON_OFF.includes(INavigationContentKeys.zoneExits);
  const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);
  const onPlayers = formation.filter(player => player.on);
  const isValidFormation = formation.length > 0 && isSomePlayerOn && selectedEntity === 'formation';
  const totalXg = getSummaryBoxXg(zoneEntities, onPlayers, entity);
  const totalCount = getTotalCount(boxes, isValidFormation);
  const totalVideoInfo = getTotalVideoInfoArrays(boxes, isValidFormation);

  if (!isValidFormation) {
    const playgroundZoneStats = getPlaygroundZoneStats(totalCount[0], totalXg[0], playerRecord);
    playgroundZoneStats.videoInfo = totalVideoInfo[0];
    return [playgroundZoneStats];
  }

  const totalMetric: IPlaygroundZoneStats[] = filteredPlayers.reduce<IPlaygroundZoneStats[]>(
    (acc, player, index) => {
      const total: IPlaygroundZoneStats = getPlaygroundZoneStats(
        totalCount[index],
        totalXg[index],
        playerRecord,
        Number(player.id),
      );
      total.videoInfo = totalVideoInfo[index];
      acc.push(total);
      return acc;
    },
    [],
  );

  return totalMetric;
};

export const getPlayerPlaygroundBoxProps = (
  zoneEntities: IZoneEntity[],
  formation: IFormation[],
  selectedEntity: IZoneEntityOptions,
  selectedPlayerItems: ISelectedPlayerItem[],
  filteredPlayers: ISelectedPlayerItem[],
  entity: string,
  playerRecord: IPlayerRecord,
) => {
  const leftBoxZoneStats = getPlayerPlaygroundBoxPropsForSide(
    zoneEntities.filter(entity => entity.location === 'left'),
    formation,
    selectedEntity,
    filteredPlayers,
    playerRecord,
  );

  const centerBoxZoneStats = getPlayerPlaygroundBoxPropsForSide(
    zoneEntities.filter(entity => entity.location === 'center'),
    formation,
    selectedEntity,
    filteredPlayers,
    playerRecord,
  );

  const rightBoxZoneStats = getPlayerPlaygroundBoxPropsForSide(
    zoneEntities.filter(entity => entity.location === 'right'),
    formation,
    selectedEntity,
    filteredPlayers,
    playerRecord,
  );

  const totalBoxStats = getTotalMetric(
    formation,
    selectedEntity,
    selectedPlayerItems,
    filteredPlayers,
    [leftBoxZoneStats, centerBoxZoneStats, rightBoxZoneStats],
    zoneEntities,
    entity,
    playerRecord,
  );

  return {
    leftBox: leftBoxZoneStats,
    centerBox: centerBoxZoneStats,
    rightBox: rightBoxZoneStats,
    summaryBox: totalBoxStats,
  };
};

export const filterDumpInOutGoals = (
  zoneEntities: IZoneEntity[],
  formation: IFormation[],
  entity: string,
): number => {
  const onPlayers = formation.filter(player => player.on);
  const isPlayerSelected = entity === 'player';

  if (isPlayerSelected) {
    const goals = zoneEntities.reduce<number>((acc, zoneEntity) => {
      if (
        (zoneEntity.shot?.type === 'G' || zoneEntity.finish === 'goal') &&
        onPlayers.some(player => player.player === zoneEntity.playerId)
      ) {
        acc += 1;
      }

      return acc;
    }, 0);
    return goals;
  }

  const goals = zoneEntities.reduce<number>((acc, zoneEntity) => {
    if (zoneEntity.shot?.type === 'G' || zoneEntity.finish === 'goal') {
      acc += 1;
    }

    return acc;
  }, 0);

  return goals;
};

export const computePlaygroundTotalXg = (
  zoneEntities: IZoneEntity[],
  formation: IFormation[],
  entity: string,
): number => {
  const onPlayers = formation.filter(player => player.on);
  const isPlayerSelected = entity === 'player';
  if (isPlayerSelected) {
    const xG = zoneEntities.reduce<number>((acc, zoneEntity) => {
      if (onPlayers.some(player => player.player === zoneEntity.playerId)) {
        acc += zoneEntity.xG ? Number(zoneEntity.xG) : 0;
      }

      return acc;
    }, 0);
    return xG;
  }

  const xG = zoneEntities.reduce<number>((acc, zoneEntity) => {
    acc += zoneEntity.xG ? Number(zoneEntity.xG) : 0;

    return acc;
  }, 0);
  return xG;
};

export const computePlaygroundTotalShots = (
  zoneEntities: IZoneEntity[],
  formation: IFormation[],
  entity: string,
): number => {
  const onPlayers = formation.filter(player => player.on);
  const isPlayerSelected = entity === 'player';
  if (isPlayerSelected) {
    const shots = zoneEntities.reduce<number>((acc, zoneEntity) => {
      if (
        (zoneEntity.shot || zoneEntity.finish) &&
        onPlayers.some(player => player.player === zoneEntity.playerId)
      ) {
        acc += 1;
      }

      return acc;
    }, 0);
    return shots;
  }

  const shots = zoneEntities.reduce<number>((acc, zoneEntity) => {
    if (zoneEntity.shot || (zoneEntity.finish && zoneEntity.finish !== 'none')) {
      acc += 1;
    }

    return acc;
  }, 0);
  return shots;
};

const computeZoneEntityDanger = (zoneEntities: IZoneEntity[], dangerValue: string) =>
  zoneEntities.reduce<IZoneEntity[]>((acc, zoneEntity) => {
    if (zoneEntity.shotDanger === dangerValue) {
      acc.push(zoneEntity);
    }

    return acc;
  }, []);

const splitZonesByDanger = (zoneEntities: IZoneEntity[]) => {
  const highDangerShots = computeZoneEntityDanger(zoneEntities, 'HD');
  const mediumDangerShots = computeZoneEntityDanger(zoneEntities, 'MD');
  const lowDangerShots = computeZoneEntityDanger(zoneEntities, 'LD');

  return {
    highDangerShots,
    mediumDangerShots,
    lowDangerShots,
  };
};

const computeFilteredEntityRecordsByFormation = (
  zoneEntity: IZoneEntity[],
  formation: IFormation[],
  entity: string,
) => {
  const onPlayers = formation.filter(player => player.on);
  if (entity === 'player') {
    const filteredZoneEntities = zoneEntity.filter(entity =>
      onPlayers.some(player => player.player === entity.playerId),
    );
    return filteredZoneEntities;
  }

  return zoneEntity;
};

const prepareZoneDangerStats = (
  zoneEntity: IZoneEntity[],
  formation: IFormation[],
  entity: string,
): IStatToDisplay[] => {
  const records = computeFilteredEntityRecordsByFormation(zoneEntity, formation, entity);
  const xG = records.reduce<number>((acc, entity) => {
    if (entity.xG) acc += Number(entity.xG);
    return acc;
  }, 0);
  const goals = records.filter(
    record => record.finish === 'goal' || record.shot?.type === 'G',
  ).length;

  return [
    {
      value: records.length.toString(),
      label: ITranslationKeys.count,
    },
    {
      value: xG.toFixed(2),
      label: 'xG',
    },
    {
      value: goals.toString(),
      label: 'G',
    },
  ];
};

export const getZoneDangerBoxes = (
  zoneEntities: IZoneEntity[],
  formation: IFormation[],
  entity: string,
) => {
  const { highDangerShots, mediumDangerShots, lowDangerShots } = splitZonesByDanger(zoneEntities);

  const boxHigh: IPlaygroundBarBox = {
    stats: prepareZoneDangerStats(highDangerShots, formation, entity),
    label: ITranslationKeys.high,
    color: 'green',
  };

  const boxMedium: IPlaygroundBarBox = {
    stats: prepareZoneDangerStats(mediumDangerShots, formation, entity),
    label: ITranslationKeys.medium,
    color: 'orange',
  };

  const boxLow: IPlaygroundBarBox = {
    stats: prepareZoneDangerStats(lowDangerShots, formation, entity),
    label: ITranslationKeys.low,
    color: 'red',
  };

  return {
    boxHigh,
    boxMedium,
    boxLow,
  };
};

export const createZoneGameEntities = (
  selectedGames: ISelectOption[],
  entities: IZoneEntity[],
  playerRecord: IPlayerRecord,
) => {
  const filteredGameEntities: IGameEntity[] = selectedGames.map(game => {
    const videoInfoEntities = entities
      .filter(shot => shot.matchId === game.value)
      .reduce<IVideoInfo[]>((acc, entity) => {
        const player = playerRecord[entity.playerId];
        if (!player) {
          console.warn('[createZoneGameEntities] Player with id', entity.id, 'was not found');
          return acc;
        }

        acc.push({
          videoId: entity.videoId,
          videoTime: entity.videoTime,
          matchId: entity.matchId,
          matchDate: entity.matchDate,
          playerId: entity.playerId,
          awayTeamId: entity.awayTeamId,
          homeTeamId: entity.homeTeamId,
          time: entity.time,
          realTime: entity.realTime,
          score: entity.score,
        });
        return acc;
      }, []);

    return {
      gameId: game.value,
      entities: videoInfoEntities,
    };
  });

  return filteredGameEntities;
};
