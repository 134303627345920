import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  Loading,
  VideoCenterModal,
  VideoCenterSelectList,
  VideoCenterSelectListMetric,
} from '../../components';
import { DEFAULT_MAIN_FILTER_FORM_VALUES, METRIC_VIDEO_LINK_CONFIG_RECORD } from '../../constants';
import {
  closeVideoCenter,
  resetFaceoffsFilter,
  resetGoalkeepersFilter,
  resetMetricParamsFilter,
  resetShootoutFilter,
  resetVideoEditById,
  resetVideomapsFilter,
  resetZones,
  resetZonesFilter,
  selectNavigation,
  selectVideoCenter,
  setVideoEdit,
  videoRecordWithEditsSelector,
} from '../../features';
import {
  useBugReportSidebar,
  useLoadInitialData,
  useLocationPaths,
  useResetFormsOnPageReloadOrChange,
} from '../../hooks';
import { ITranslationKeys } from '../../i18n/types';
import { IMainFilterForm, IMetricVideoLinkKeys, IVideoEdit } from '../../types';
import { getVideoOptions } from '../../utils';

export const DataPagesWrapper = () => {
  const { isLoadingMinimumInitialData } = useAppSelector(selectNavigation);
  const videoCenter = useAppSelector(selectVideoCenter);
  const { videoRecordWithEdits } = useAppSelector(videoRecordWithEditsSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { pathSegments, activePage, activeTab } = useLocationPaths();
  useLoadInitialData();

  const methods = useForm<IMainFilterForm>({
    defaultValues: DEFAULT_MAIN_FILTER_FORM_VALUES,
    mode: 'onBlur',
  });

  useResetFormsOnPageReloadOrChange(methods);

  const handleClose = () => {
    dispatch(closeVideoCenter());
  };

  const handleCloseMetricsVideoCenter = () => {
    handleClose();
    dispatch(resetVideomapsFilter());
    dispatch(resetMetricParamsFilter());
    dispatch(resetFaceoffsFilter());
    dispatch(resetGoalkeepersFilter());
    dispatch(resetShootoutFilter());
    dispatch(resetZonesFilter());

    dispatch(resetZones());
  };

  const handleSetVideoEdit = (edit: IVideoEdit) => {
    dispatch(setVideoEdit(edit));
  };
  const handleResetVideoEdit = (videoUuid: string) => {
    dispatch(resetVideoEditById(videoUuid));
  };

  const getBugReportSidebar = useBugReportSidebar();

  const { metricVideoLinkId, videoRecord, openFromMetric } = videoCenter;

  return (
    <div className='grow-and-flex-column'>
      {isLoadingMinimumInitialData && pathSegments.length < 2 && <Loading />}
      <FormProvider {...methods}>
        <Outlet />
      </FormProvider>
      <VideoCenterModal
        {...videoCenter}
        handleClose={handleClose}
        handleSetVideoEdit={handleSetVideoEdit}
        handleResetVideoEdit={handleResetVideoEdit}
        renderList={params => (
          <VideoCenterSelectList {...params} videosRecord={videoRecordWithEdits} />
        )}
        videoRecord={videoRecordWithEdits}
        originalVideoRecord={videoRecord}
        options={getVideoOptions(videoRecord)}
      />
      <VideoCenterModal
        categoryKey={
          metricVideoLinkId
            ? t(METRIC_VIDEO_LINK_CONFIG_RECORD[metricVideoLinkId]?.translationKey)
            : t(ITranslationKeys.all)
        }
        open={!!openFromMetric}
        isGoalkeeper={
          metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts ||
          metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShots
        }
        handleClose={handleCloseMetricsVideoCenter}
        handleSetVideoEdit={handleSetVideoEdit}
        handleResetVideoEdit={handleResetVideoEdit}
        renderList={params => (
          <VideoCenterSelectListMetric {...params} videosRecord={videoRecordWithEdits} />
        )}
        videoRecord={videoRecordWithEdits}
        originalVideoRecord={videoRecord}
        options={getVideoOptions(videoRecord)}
      />
      {getBugReportSidebar(activePage, activeTab)}
    </div>
  );
};
