import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { createClassNames } from '../../utils';
import './Footer.styles.scss';

export interface IFooterProps {
  /** Version of the application */
  version: string;
  /** Year next to the copyright */
  year: number;
  /** Function called when the Changelog is clicked. */
  onChangelogClick: () => void;
}

const classNames = createClassNames('footer');

/**
 * Footer UI component for displaying information at the bottom of the page.
 */
export const Footer: FC<IFooterProps> = ({ version, year, onChangelogClick }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <p>
        &copy;{year} eSports.cz & BPA a.s.
        <br />
        {t(ITranslationKeys.version)}: {version} | <span onClick={onChangelogClick}>Changelog</span>
      </p>
    </div>
  );
};
