import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ChartSummary, LogBarChart, Skeleton } from '../../../../../../components';
import { analyticsTypeDefaultOption } from '../../../../../../constants';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { useGetLogHokejPlayerQuery } from '../../../../../../services/api/trackingApi';
import { IAnalyticsType } from '../../../../../../types';
import { createClassNames, parseLogHokejPlayerOverviewData } from '../../../../../../utils';
import { IOverviewComponentsProps } from '../types';
import './AccessLogHokejPlayer.styles.scss';

interface IAccessLogHokejPlayerProps extends IOverviewComponentsProps {}

const classNames = createClassNames('access-log-hokej-player');

export const AccessLogHokejPlayer: FC<IAccessLogHokejPlayerProps> = ({
  submitValues,
  tooltipTopPlayersCount,
}) => {
  const { selectedAnalytics, dateFromTo } = submitValues;
  const { data, error, isFetching } = useGetLogHokejPlayerQuery(dateFromTo, {
    selectFromResult: result => ({
      ...result,
      data: parseLogHokejPlayerOverviewData(result.data),
    }),
  });

  const { t } = useTranslation();

  if (isFetching) {
    return (
      <div className={classNames()}>
        <Skeleton />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={classNames({ error: true })}>{t(ITranslationKeys.defaultErrorMessage)}</div>
    );
  }

  const filteredSelectedAnalytics =
    selectedAnalytics.value === IAnalyticsType.screenCount
      ? analyticsTypeDefaultOption
      : selectedAnalytics;
  const analyticsType = filteredSelectedAnalytics.value as IAnalyticsType;

  const total = Object.values(data).reduce<number>(
    (acc, item) => acc + item.analytics[analyticsType],
    0,
  );

  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        <ChartSummary
          header={total}
          dateFromTo={dateFromTo}
          selectedAnalytics={filteredSelectedAnalytics}
          disableLegend
        />
      </div>
      <LogBarChart
        data={Object.values(data)}
        height={229}
        {...{ analyticsType, total, tooltipTopPlayersCount }}
      />
    </div>
  );
};
