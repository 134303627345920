import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Caption, Input, Modal, ModalButton } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { ILanguageType } from '../../../../../../types';
import { createClassNames, transformStringToLanguageType } from '../../../../../../utils';
import './EditTemplateModal.styles.scss';

export interface IEditTemplateModalProps {
  openModal: boolean;
  onClose: () => void;
  onSave: (templateName: ILanguageType<string>) => void;
  initialTemplateName?: ILanguageType<string>;
}

const classNames = createClassNames('edit-template-modal');

export const EditTemplateModal: FC<IEditTemplateModalProps> = ({
  openModal,
  initialTemplateName,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState<ILanguageType<string>>(
    initialTemplateName ?? transformStringToLanguageType(''),
  );
  // TODO: Add another input for english template name after english translation is ready

  return (
    <Modal
      open={openModal}
      modalButtons={
        <>
          <ModalButton
            label={ITranslationKeys.close}
            fontWeight='normal'
            onClick={() => onClose()}
          />
          <ModalButton
            label={ITranslationKeys.save}
            fontWeight='bold'
            onClick={() => onSave(templateName)}
          />
        </>
      }
    >
      <div className={classNames()}>
        <h3>{t(ITranslationKeys.enterNameOfTemplate)}</h3>
        <div className={classNames('input-group')}>
          <div>
            <Caption label={'Česky'} />
            <Input
              onChange={e => setTemplateName({ ...templateName, cz: e.currentTarget.value })}
              value={templateName.cz}
              variant='tcms'
            />
          </div>
          <div>
            <Caption label={'Anglicky'} />
            <Input
              onChange={e => setTemplateName({ ...templateName, en: e.currentTarget.value })}
              value={templateName.en}
              variant='tcms'
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
