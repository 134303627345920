import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { PlayIcon } from '../../../icons';
import { INetZoneUnion } from '../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  secondsToMinutesAndSeconds,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { Button } from '../../Button';
import { GoalNet } from '../../goalNetComponents';
import './PlaygroundShotTooltip.styles.scss';

export interface IPlaygroundShotTooltipPositionProps {
  top: number;
  left: number;
}

export interface IPlaygroundShotTooltipProps {
  /**
   * Home team shortcut.
   */
  homeTeamShortcut: string;
  /**
   * Away team shortcut.
   */
  awayTeamShortcut: string;
  /**
   * Name of the player who shot the puck.
   */
  shotPlayerName: string;
  /**
   * Expected goals metric.
   */
  xG: number;
  /**
   * Full date of the shot.
   */
  date?: Date;
  /**
   * Time of the shot.
   */
  time?: number;
  /**
   * Zone to be displayed. If undefined, the goal has no red elements.
   */
  activeZone?: INetZoneUnion;
  /**
   * Name of the player who passed the puck.
   */
  passPlayerName?: string;
  /**
   * When true, component is shown.
   */
  show?: boolean;
  /**
   * Position of the component.
   */
  position: IPlaygroundShotTooltipPositionProps;
  /**
   * Function called when the play button is clicked.
   */
  onPlayClick: () => void;
}

const classNames = createClassNames('playground-shot-tooltip');

export const PlaygroundShotTooltip: FC<IPlaygroundShotTooltipProps> = ({
  date,
  time,
  homeTeamShortcut,
  awayTeamShortcut,
  shotPlayerName,
  xG,
  activeZone,
  passPlayerName,
  show = false,
  position,
  onPlayClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          show,
          containsPass: !!passPlayerName,
          activeZone: !!activeZone,
        }),
      })}
      data-testid='playground-shot-tooltip'
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      {date && (
        <div className={classNames('tooltip')}>
          {formatDateByLanguage(date, 'P')}, {homeTeamShortcut + ' - ' + awayTeamShortcut}
        </div>
      )}
      {activeZone && (
        <div className={classNames('goal')}>
          <GoalNet width={120} height={80} activeZones={[activeZone]} />
        </div>
      )}
      {time && (
        <div className={classNames('time')}>
          <strong>{t(ITranslationKeys.time)}:</strong> {secondsToMinutesAndSeconds(time)}
        </div>
      )}
      {passPlayerName ? (
        <>
          <div className={classNames('pass')}>
            <strong>{t(ITranslationKeys.pass)}:</strong> {passPlayerName}
          </div>
          <div className={classNames('shot')}>
            <strong>{t(ITranslationKeys.shot)}:</strong> {shotPlayerName}
          </div>
          <div className={classNames('xg')}>
            <strong>xG:</strong> {xG.toFixed(2)}
          </div>
        </>
      ) : (
        <>
          <div className={classNames('xg')}>
            <strong>xG:</strong> {xG.toFixed(2)}
          </div>
          <div className={classNames('shot')}>
            <strong>{t(ITranslationKeys.shot)}:</strong> {shotPlayerName}
          </div>
        </>
      )}
      <div className={classNames('button')}>
        <Button
          primary={false}
          label={ITranslationKeys.play}
          iconComponent={<PlayIcon />}
          iconPosition={'right'}
          onClick={onPlayClick}
        />
      </div>
    </div>
  );
};
