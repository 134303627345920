import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../../services/axiosInstance';
import { IHelpCategory, IHelpItem, IHelpItemBase } from '../../../types';
import { defaultCatchErrorCallback } from '../../../utils';
import { normalizeHelpItemBase, parseHelpItemBase, parseHelpItemData } from '../parseHelp';

export interface IPutHelpScreen {
  id: string;
  body: IHelpItemBase;
  category: IHelpCategory;
  isNew?: boolean;
}

export const getAllHelpCategoryItems = createAsyncThunk<IHelpItem[], IHelpCategory>(
  'help/getAllHelpCategoryItems',
  async category => {
    const helpItems = await axiosInstance
      .get(baseCategoryUrl(category))
      .then(response => parseHelpItemData(response.data))
      .catch(defaultCatchErrorCallback);

    return helpItems;
  },
);

export const getHelpCategoryItem = createAsyncThunk<IHelpItemBase, Omit<IPutHelpScreen, 'body'>>(
  'help/getHelpCategoryItem',
  async ({ id, category }) => {
    const baseUrl = baseCategoryUrl(category);
    const helpItem = await axiosInstance
      .get(`${baseUrl}/${id}`)
      .then(response => parseHelpItemBase(response.data))
      .catch(defaultCatchErrorCallback);

    return helpItem;
  },
);

export const putHelpCategoryItem = createAsyncThunk<IPutHelpScreen | undefined, IPutHelpScreen>(
  'help/putHelpCategoryItem',
  async ({ id, body, category }) => {
    const baseUrl = baseCategoryUrl(category);
    const status = await axiosInstance
      .put(`${baseUrl}/${id}`, normalizeHelpItemBase(body))
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status === 204 ? { id, body, category } : undefined;
  },
);

export const deleteHelpCategoryItem = createAsyncThunk<number, Omit<IPutHelpScreen, 'body'>>(
  'help/deleteHelpCategoryItem',
  async ({ id, category }) => {
    const baseUrl = baseCategoryUrl(category);
    const status = await axiosInstance
      .delete(`${baseUrl}/${id}`)
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);

const baseCategoryUrl = (category: IHelpCategory) => {
  if (category === 'descriptions') return '/help-screen';
  if (category === 'tips') return '/help-tip';
  if (category === 'functions') return '/help-function';
  return '/help-news';
};
