import { SUPPORTED_LANGUAGES } from '../../constants';
import { IHelpItem, IHelpItemBase, IHelpItemBaseDTO, IHelpItemDTO } from '../../types';

export const parseHelpItemBase = (data: IHelpItemBaseDTO): IHelpItemBase =>
  SUPPORTED_LANGUAGES.reduce<IHelpItemBase>((acc, language) => {
    acc[language] = {
      title: data[language].header,
      text: data[language].text,
    };
    return acc;
  }, {} as IHelpItemBase);

export const parseHelpItemData = (data: IHelpItemDTO[]): IHelpItem[] =>
  data.map<IHelpItem>(({ id, ...languages }) => ({
    id,
    ...parseHelpItemBase(languages),
  }));

export const normalizeHelpItemBase = (data: IHelpItemBase): IHelpItemBaseDTO =>
  SUPPORTED_LANGUAGES.reduce<IHelpItemBaseDTO>((acc, language) => {
    acc[language] = {
      header: data[language].title,
      text: data[language].text,
    };
    return acc;
  }, {} as IHelpItemBaseDTO);
