import { UseFormSetValue } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { teamAllOption } from '../constants';
import {
  filteredMainFilterDataSelector,
  resetSelectedPlayerItems,
  selectCompetitionsDetail,
  selectMainFilter,
  setOpponentsAtGamesPage,
  setSelectedGames,
  setSelectedGoalkeeper,
  setSelectedOpponents,
  setSelectedParts,
  setSelectedPlayer,
  setSelectedTeam,
} from '../features';
import { IMainFilterForm, INavigationPageKeys } from '../types';
import { getBasePart, getPartsOptions, isAtLeastOnePlayerOn } from '../utils';
import { useLocationPaths } from './useLocationPaths';

export const useResetOnTeamChange = () => {
  const { filteredTeamsOptions, selectedGame } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedPlayer, selectedGoalkeeper, selectedPlayerItems } =
    useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const { activePage } = useLocationPaths();

  const resetOnTeamChange = (setValue: UseFormSetValue<IMainFilterForm>) => {
    if (filteredTeamsOptions.length > 0) {
      if (selectedPlayer) {
        setValue('selectedPlayer', null, { shouldDirty: true, shouldValidate: true });
        dispatch(setSelectedPlayer(undefined));
      }
      if (selectedGoalkeeper) {
        setValue('selectedGoalkeeper', null, { shouldDirty: true, shouldValidate: true });
        dispatch(setSelectedGoalkeeper(undefined));
      }
      if (selectedGame && activePage === INavigationPageKeys.games) {
        setValue('selectedGames', [], { shouldDirty: true, shouldValidate: true });
        dispatch(setSelectedGames([]));
      }
      if (isAtLeastOnePlayerOn(selectedPlayerItems, true)) {
        dispatch(resetSelectedPlayerItems());
      }
    }
  };

  return resetOnTeamChange;
};

export const useResetOnPartChange = () => {
  const { filteredTeamsOptions } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam, opponentsAtGamesPage, selectedOpponents } =
    useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const resetOnTeamChange = useResetOnTeamChange();

  const resetOnPartChange = (setValue: UseFormSetValue<IMainFilterForm>) => {
    if (filteredTeamsOptions.length > 0) {
      const allOption = filteredTeamsOptions.find(option => option.value === teamAllOption.value);
      const resetValue = allOption ?? filteredTeamsOptions[0];
      if (selectedTeam?.value !== teamAllOption.value) {
        setValue('selectedTeam', resetValue, {
          shouldDirty: true,
          shouldValidate: true,
        });
        dispatch(setSelectedTeam(resetValue));
      }
      if (opponentsAtGamesPage?.value !== teamAllOption.value) {
        setValue('opponentsAtGamesPage', resetValue, { shouldDirty: true });
        dispatch(setOpponentsAtGamesPage(resetValue));
      }
      if (selectedOpponents.length > 0) {
        setValue('selectedOpponents', [], {
          shouldDirty: true,
          shouldValidate: true,
        });
        dispatch(setSelectedOpponents([]));
      }
    }

    resetOnTeamChange(setValue);
  };

  return resetOnPartChange;
};

export const useResetOnSeasonChange = () => {
  const competitionsDetail = useAppSelector(selectCompetitionsDetail);
  const dispatch = useAppDispatch();

  const resetOnPartChange = useResetOnPartChange();

  const resetOnSeasonChange = (setValue: UseFormSetValue<IMainFilterForm>) => {
    const partOptions = getPartsOptions(competitionsDetail.byId);

    if (partOptions.length > 0) {
      setValue('selectedParts', [getBasePart(partOptions)], {
        shouldDirty: true,
        shouldValidate: true,
      });
      dispatch(setSelectedParts([getBasePart(partOptions)]));
    }

    resetOnPartChange(setValue);
  };

  return resetOnSeasonChange;
};
