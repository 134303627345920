import { useSearchParams } from 'react-router-dom';

import { IUrlParam } from '../../types';

/** Set url search params. It does not set default value to url search params. */
export const useSetUrlSearchParams = () => {
  const [, setSearchParams] = useSearchParams();

  const setUrlSearchParams = (name: IUrlParam, value: string, defaultValue: string = 'all') => {
    setSearchParams(params => {
      if (value === defaultValue || value === '') {
        if (params.has(name)) {
          params.delete(name);
        }
        return params;
      }
      params.set(name, value);
      return params;
    });
  };

  return { setUrlSearchParams };
};
