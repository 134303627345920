import { MutableRefObject, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { DISABLED_TABS_ON_OFF, entityOptions } from '../constants';
import {
  selectGames,
  selectMainFilter,
  selectVideomapsFilter,
  selectZones,
  selectZonesFilter,
  setSelectedGames,
} from '../features';
import { useHandleOnChange, useRefetchContent } from '../hooks';
import { IFormation, IHandleOnSubmitConfig, INavigationContentKeys, ISelectOption } from '../types';
import { getGamesOptions, isAtLeastOnePlayerOn, parseUrlSelectedGames } from '../utils';

const disabledOnOff = DISABLED_TABS_ON_OFF.includes(INavigationContentKeys.zoneExits);

export const useZoneEffects = (
  handleOnSubmit: (config?: IHandleOnSubmitConfig) => Promise<void>,
  isInitialMountRef: MutableRefObject<boolean>,
  formation: IFormation[],
  isLoading?: boolean,
) => {
  const { entity } = useAppSelector(selectVideomapsFilter);
  const { selectedGames, selectedPlayerItems } = useAppSelector(selectMainFilter);
  const { games } = useAppSelector(selectGames);
  const { zones } = useAppSelector(selectZones);
  const { zoneCategory } = useAppSelector(selectZonesFilter);
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const { onChangeEntity } = useHandleOnChange();

  const isSelectedGamesComparisonSatisfied = (
    arr1: ISelectOption[],
    arr2: ISelectOption[] | null,
  ) => {
    if (arr2 === null) return true;
    return (
      arr2.length !== arr1.length ||
      arr1.some((element, index) => element?.value !== arr2[index]?.value)
    );
  };

  useEffect(() => {
    if (selectedGames && selectedGames.length > 0) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGames, zoneCategory, formation]);

  useEffect(() => {
    if (!isInitialMountRef.current) {
      const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);
      const optionsToSelect = isSomePlayerOn ? entityOptions[0] : entityOptions[1];

      onChangeEntity(optionsToSelect);
    }

    if (
      formation.length > 0 &&
      formation.every(element => !element.on) &&
      entity.value === 'player'
    ) {
      onChangeEntity(entityOptions[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formation, zones]);

  useEffect(() => {
    if (Object.keys(games.byId).length > 0) {
      const gamesOptions = getGamesOptions(games.byId);
      const selectedGamesUrl = parseUrlSelectedGames(searchParams, gamesOptions);
      const optionsToSelect = selectedGamesUrl || [...gamesOptions];

      const gamesComparisonSatisfied = isSelectedGamesComparisonSatisfied(
        optionsToSelect,
        selectedGames,
      );

      if (gamesComparisonSatisfied) {
        dispatch(setSelectedGames(optionsToSelect));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games.byId]);

  useRefetchContent({ handleOnSubmit, isLoading });
};
