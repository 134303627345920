import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IHelpState } from '../../types';
import {
  deleteHelpCategoryItem,
  getAllHelpCategoryItems,
  getAllHelpScreenConfigs,
  putHelpCategoryItem,
  putHelpScreenConfig,
} from './helpActions';

const initialState: IHelpState = {
  help: {
    descriptions: [],
    tips: [],
    functions: [],
    news: [],
  },
  helpConfig: {},
  isLoading: false,
  isHelpLoaded: false,
  error: undefined,
  showHelpSidebar: false,
};

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    setShowHelpSidebar: (state, action: PayloadAction<boolean>) => {
      state.showHelpSidebar = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllHelpCategoryItems.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getAllHelpCategoryItems.fulfilled, (state, action) => {
        state.help[action.meta.arg] = action.payload;
        state.isLoading = false;
        state.isHelpLoaded = true;
      })
      .addCase(getAllHelpCategoryItems.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getAllHelpScreenConfigs.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getAllHelpScreenConfigs.fulfilled, (state, action) => {
        state.helpConfig = action.payload;
        state.isLoading = false;
        state.isHelpLoaded = true;
      })
      .addCase(getAllHelpScreenConfigs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(putHelpScreenConfig.pending, state => {
        //state.isLoading = true;
        state.error = undefined;
      })
      .addCase(putHelpScreenConfig.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.helpConfig[action.payload.id] = action.payload.body;
        }
        //state.isLoading = false;
      })
      .addCase(putHelpScreenConfig.rejected, (state, action) => {
        //state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(putHelpCategoryItem.pending, state => {
        //state.isLoading = true;
        state.error = undefined;
      })
      .addCase(putHelpCategoryItem.fulfilled, (state, action) => {
        if (!action.payload) return;

        if (action.meta.arg.isNew) {
          state.help[action.payload.category].push({
            id: action.payload.id,
            cz: action.payload.body.cz,
            en: action.payload.body.en,
          });
        } else {
          const index = state.help[action.payload.category]?.findIndex(
            item => item.id === action.payload?.id,
          );

          if (index !== undefined) {
            state.help[action.payload.category][index].cz = action.payload.body.cz;
            state.help[action.payload.category][index].en = action.payload.body.en;
          }
        }
        //state.isLoading = false;
      })
      .addCase(putHelpCategoryItem.rejected, (state, action) => {
        //state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(deleteHelpCategoryItem.pending, state => {
        //state.isLoading = true;
        state.error = undefined;
      })
      .addCase(deleteHelpCategoryItem.fulfilled, (state, action) => {
        const index = state.help[action.meta.arg.category]?.findIndex(
          item => item.id === action.meta.arg.id,
        );

        if (index !== undefined) {
          state.help[action.meta.arg.category].splice(index, 1);
        }
        //state.isLoading = false;
      })
      .addCase(deleteHelpCategoryItem.rejected, (state, action) => {
        //state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setShowHelpSidebar } = helpSlice.actions;

export const helpReducer = helpSlice.reducer;
