import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableElement } from 'react-sortable-hoc';

import { DragDropIcon } from '../../../icons';
import { ITcmsItemProps } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { DragHandle } from '../../DragHandle';
import './TcmsItem.styles.scss';

const classNames = createClassNames('tcms-item');

export const TcmsItem: FC<ITcmsItemProps> = ({
  label,
  children,
  selected = false,
  disabled = false,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          disabled,
          isClickable: !!onClick,
          selected,
        }),
      })}
      data-testid='tcms-item'
    >
      <DragHandle disabled={disabled} icon={<DragDropIcon />} />
      <div className={classNames('label')} onClick={onClick}>
        {t(label)}
      </div>
      <div className={classNames('content')}>{children}</div>
    </div>
  );
};

export const TcmsSortableItem = SortableElement<ITcmsItemProps>((props: ITcmsItemProps) => (
  <TcmsItem {...props} />
));
