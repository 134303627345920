import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IStats, ITrend, ITrendRequestBody } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import {
  parseTimePeriodPlayerStatsData,
  parseTrendFormationData,
  parseTrendPlayerData,
} from './parseTrend';

interface IGetTrendBaseStatsParams {
  competitionsUuids: string[];
  teamUuid: string;
  body?: ITrendRequestBody;
}

interface IGetTimePeriodBaseStatsParams {
  rowIndex: number;
  isLast?: boolean;
}

interface IGetTrendGoalkeeperStatsParams extends IGetTrendBaseStatsParams {
  goalkeeperUuid: string;
}

interface IGetTrendPlayerStatsParams extends IGetTrendBaseStatsParams {
  playerUuid: string;
}
interface IGetTrendFormationStatsParams extends IGetTrendBaseStatsParams {}

interface IGetTimePeriodPlayerStatsParams
  extends IGetTimePeriodBaseStatsParams,
    IGetTrendPlayerStatsParams {}

interface IGetTimePeriodGoalkeeperStatsParams
  extends IGetTimePeriodBaseStatsParams,
    IGetTrendGoalkeeperStatsParams {}
interface IGetTimePeriodFormationStatsParams
  extends IGetTimePeriodBaseStatsParams,
    IGetTrendFormationStatsParams {}

export const getTrendPlayerStats = createAsyncThunk<ITrend[], IGetTrendPlayerStatsParams>(
  'trend/getTrendPlayerStats',
  async ({ competitionsUuids, teamUuid, playerUuid, body }) => {
    const trendPlayerStats: ITrend[] = await axiosInstance
      .post(`/trend/${competitionsUuids}/individual/${teamUuid}/${playerUuid}`, body ?? {})
      .then(response => parseTrendPlayerData(response.data))
      .catch(defaultCatchErrorCallback);

    return trendPlayerStats;
  },
);

export const getTrendGoalkeeperStats = createAsyncThunk<ITrend[], IGetTrendGoalkeeperStatsParams>(
  'trend/getTrendGoalkeeperStats',
  async ({ competitionsUuids, teamUuid, goalkeeperUuid, body }) => {
    const trendPlayerStats: ITrend[] = await axiosInstance
      .post(`/trend/${competitionsUuids}/goalkeeper/${teamUuid}/${goalkeeperUuid}`, body ?? {})
      .then(response => parseTrendPlayerData(response.data))
      .catch(defaultCatchErrorCallback);

    return trendPlayerStats;
  },
);

export const getTrendFormationStats = createAsyncThunk<ITrend[], IGetTrendFormationStatsParams>(
  'trend/getTrendFormationStats',
  async ({ competitionsUuids, teamUuid, body }) => {
    const trendPlayerStats: ITrend[] = await axiosInstance
      .post(`/trend/${competitionsUuids}/formation/${teamUuid}`, body ?? {})
      .then(response => parseTrendFormationData(response.data))
      .catch(defaultCatchErrorCallback);

    return trendPlayerStats;
  },
);

export const getTrendTeamStats = createAsyncThunk<ITrend[], IGetTrendBaseStatsParams>(
  'trend/getTrendTeamStats',
  async ({ competitionsUuids, teamUuid, body }) => {
    const trendPlayerStats: ITrend[] = await axiosInstance
      .post(`/trend/${competitionsUuids}/team/${teamUuid}`, body ?? {})
      .then(response => parseTrendPlayerData(response.data))
      .catch(defaultCatchErrorCallback);

    return trendPlayerStats;
  },
);

export const getTimePeriodPlayerStats = createAsyncThunk<
  IStats | undefined,
  IGetTimePeriodPlayerStatsParams
>('trend/getTimePeriodPlayerStats', async ({ competitionsUuids, body, teamUuid, playerUuid }) => {
  const requestPath = `/individual/${competitionsUuids}/${teamUuid}`;

  const playerStats: IStats | undefined = await axiosInstance
    .post(requestPath, body)
    .then(response => parseTimePeriodPlayerStatsData(response.data, playerUuid))
    .catch(defaultCatchErrorCallback);

  return playerStats;
});

export const getTimePeriodGoalkeeperStats = createAsyncThunk<
  IStats | undefined,
  IGetTimePeriodGoalkeeperStatsParams
>(
  'trend/getTimePeriodGoalkeeperStats',
  async ({ body, competitionsUuids, teamUuid, goalkeeperUuid }) => {
    const requestPath = `/goalkeeper/${competitionsUuids}/${teamUuid}`;

    const goalkeeperStats: IStats | undefined = await axiosInstance
      .post(requestPath, body)
      .then(response => parseTimePeriodPlayerStatsData(response.data, goalkeeperUuid))
      .catch(defaultCatchErrorCallback);

    return goalkeeperStats;
  },
);

export const getTimePeriodFormationStats = createAsyncThunk<
  IStats | undefined,
  IGetTimePeriodFormationStatsParams
>('trend/getTimePeriodFormationStats', async ({ competitionsUuids, body, teamUuid }) => {
  const requestPath = `/formation/${competitionsUuids}/${teamUuid}`;

  const playerStats: IStats | undefined = await axiosInstance
    .post(requestPath, body)
    .then(response => response.data)
    .catch(defaultCatchErrorCallback);

  return playerStats;
});
