import { CSSProperties, FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { SELECT_PLAYERS_INPUT_COLORS } from '../../../constants';
import { selectMainFilter, setSelectedPlayerItems } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import {
  IMainFilterForm,
  IMultiParamsVoid,
  IOnOffColors,
  IPlayerColors,
  ISelectOption,
  ISelectedPlayerItem,
  IUrlParam,
} from '../../../types';
import { createClassNames } from '../../../utils';
import { SelectInput } from '../../SelectInput';
import './SelectPlayersBar.styles.scss';

export interface ISelectPlayersBarProps {
  /** Available players select options. */
  playersOptions: ISelectOption[];
  /** Disables select inputs. */
  disabled?: boolean;
  /** Sets same color to each input, otherwise component uses different colors for each input. */
  colorAll?: IPlayerColors | IOnOffColors;
  /** If true, input has on/off button for activate or deactivate input. */
  hasOnOff?: boolean;
  /** Tells if the input should be colored to green when it's on or to red when it's off. */
  onOffColored?: boolean;
  /** Bottom padding for the component. */
  paddingBottom?: CSSProperties['paddingBottom'];
}

const classNames = createClassNames('select-players-bar');

/** Select players controller for videomaps. */
export const SelectPlayersBar: FC<ISelectPlayersBarProps> = ({
  playersOptions,
  disabled,
  colorAll,
  hasOnOff = true,
  onOffColored,
  paddingBottom,
}) => {
  const { selectedPlayerItems } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const { control } = useFormContext<IMainFilterForm>();
  const { fields } = useFieldArray({
    control,
    name: 'selectedPlayerItems',
  });

  const [, setSearchParams] = useSearchParams();

  const handleChangePlayer = (
    option: ISelectOption,
    onChange: IMultiParamsVoid,
    index: number,
    isOnOffClicked?: boolean,
  ) => {
    const getIsActiveValue = (): boolean | undefined => {
      if (!isOnOffClicked) return true;

      return !selectedPlayerItems[index].isActive;
    };

    const isActive = option ? getIsActiveValue() : undefined;

    const selectedPlayerItem: ISelectedPlayerItem = {
      ...selectedPlayerItems[index],
      selectedPlayer: option,
      isActive,
    };

    onChange(selectedPlayerItem);
    dispatch(
      setSelectedPlayerItems({
        selectedPlayerItem,
        index,
      }),
    );
    updatePlayerSearchParams(selectedPlayerItems, selectedPlayerItem, index, setSearchParams);
  };

  const options = playersOptions.filter(
    option => !selectedPlayerItems.some(item => item.selectedPlayer?.value === option.value),
  );

  return (
    <div className={classNames()} style={{ paddingBottom }}>
      {fields.map((field, index) => (
        <Controller
          key={field.id}
          name={`selectedPlayerItems.${index}`}
          control={control}
          render={({ field: { value, onChange } }) => {
            const selected = playersOptions.find(
              option => value.selectedPlayer?.value === option.value,
            );

            return (
              <SelectInput
                key={field.id}
                onChange={newValue => handleChangePlayer(newValue, onChange, index)}
                selected={selected}
                options={options}
                placeholder={ITranslationKeys.selectPlayer}
                variant='filter'
                color={colorAll ?? SELECT_PLAYERS_INPUT_COLORS[index]}
                isOn={value.isActive}
                onIsOnChange={value => handleChangePlayer(value, onChange, index, true)}
                disabled={disabled}
                onOffColored={onOffColored}
                hasOnOff={hasOnOff}
                isClearable
                scrollable
              />
            );
          }}
        />
      ))}
    </div>
  );
};

const updatePlayerSearchParams = (
  selectedPlayerItems: ISelectedPlayerItem[],
  newItem: ISelectedPlayerItem,
  itemIndex: number,
  setSearchParams: SetURLSearchParams,
) => {
  const newSelectedPlayerItems = [...selectedPlayerItems];
  newSelectedPlayerItems.splice(itemIndex, 1, newItem);

  const selectedPlayersToSearch = newSelectedPlayerItems
    .map(({ selectedPlayer, isActive }) =>
      selectedPlayer?.value ? `${selectedPlayer.value}_${!!isActive}` : undefined,
    )
    .filter((item): item is string => !!item);

  setSearchParams(params => {
    if (selectedPlayersToSearch.length > 0) {
      params.set(IUrlParam.selectedPlayerItems, selectedPlayersToSearch.join(','));
    } else {
      params.delete(IUrlParam.selectedPlayerItems);
    }

    return params;
  });
};
