import { IUserDataset, IUserDatasetContent, IUserDatasetDTO } from '../../types';

export const parseUserDatasetData = ({ id, name, content }: IUserDatasetDTO): IUserDataset => {
  const { datasetMetrics, entity }: IUserDatasetContent = content !== '' ? JSON.parse(content) : {};

  return {
    id,
    name: {
      cz: name,
      en: name,
    },
    datasetMetrics,
    entity,
  };
};

export const parseGetUserDatasetsData = (data: IUserDatasetDTO[]): IUserDataset[] =>
  data.map<IUserDataset>(parseUserDatasetData);
