import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  getAllHelpCategoryItems,
  getAllHelpScreenConfigs,
  getDefinedDatasets,
  getMetrics,
  getReportMetrics,
  getSeasons,
  getUserDatasets,
  selectAuth,
  selectHelp,
  selectSeasons,
  selectSettings,
  selectUserDatasets,
  setIsInitialized,
} from '../features';

/** Hook fetches initial required data that are loaded only at initial app load. */
export const useLoadInitialData = () => {
  const { user } = useAppSelector(selectAuth);
  const { isSeasonsLoaded } = useAppSelector(selectSeasons);
  const { isUserDatasetsLoaded } = useAppSelector(selectUserDatasets);
  const { definedDatasets, metrics, reportMetrics } = useAppSelector(selectSettings);
  const { isHelpLoaded } = useAppSelector(selectHelp);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isSeasonsLoaded) {
      dispatch(getSeasons());
    }

    if (!isUserDatasetsLoaded && user) {
      dispatch(getUserDatasets());
    }

    if (!definedDatasets.isDefinedDatasetsLoaded) {
      dispatch(getDefinedDatasets());
    }

    if (!isHelpLoaded) {
      dispatch(getAllHelpCategoryItems('descriptions'));
      dispatch(getAllHelpCategoryItems('tips'));
      dispatch(getAllHelpCategoryItems('functions'));
      dispatch(getAllHelpCategoryItems('news'));
      dispatch(getAllHelpScreenConfigs());
    }

    if (!metrics.isAllMetricsLoaded) {
      dispatch(getMetrics());
    }

    if (!reportMetrics.isReportMetricsLoaded) {
      dispatch(getReportMetrics());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isSeasonsLoaded &&
      isUserDatasetsLoaded &&
      definedDatasets.isDefinedDatasetsLoaded &&
      isHelpLoaded &&
      metrics.isAllMetricsLoaded &&
      reportMetrics.isReportMetricsLoaded
    ) {
      dispatch(setIsInitialized(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSeasonsLoaded,
    isUserDatasetsLoaded,
    definedDatasets.isDefinedDatasetsLoaded,
    isHelpLoaded,
    metrics.isAllMetricsLoaded,
    reportMetrics.isReportMetricsLoaded,
  ]);
};
