import { FC } from 'react';

import { useGetAccessLogAggregationEcQuery } from '../../../../../services/api/trackingApi';
import { filterAccessLogData } from '../../../../../utils';
import { AccessLogOverview } from './AccessLogOverview';
import { IOverviewComponentsProps } from './types';

interface IAccessLogExtraCoachProps extends IOverviewComponentsProps {}

export const AccessLogExtraCoach: FC<IAccessLogExtraCoachProps> = ({
  submitValues,
  tooltipTopPlayersCount,
}) => {
  const { dateFromTo } = submitValues;
  const { data, error, isFetching } = useGetAccessLogAggregationEcQuery(dateFromTo, {
    selectFromResult: result => ({
      ...result,
      data: filterAccessLogData(result.data, submitValues),
    }),
  });

  return (
    <AccessLogOverview {...{ data, error, isFetching, submitValues, tooltipTopPlayersCount }} />
  );
};
