import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  CommonTable,
  DataSettingsModal,
  Loading,
  SelectInput,
  Skeleton,
  TableToolbar,
  TrendChart,
  TrendToolbar,
} from '../../../../components';
import {
  DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES,
  trendTimePeriodTypeOptions,
} from '../../../../constants';
import {
  selectDataSettingsFilter,
  selectMetrics,
  selectTrend,
  setOpenDataSettings,
  setSelectedTrendMetric,
  setTrendTableDataBy,
  setTrendTableDataRows,
  trendDataSelector,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchTimePeriodPlayerStats,
  useFetchTrendStats,
  useFilteredMetricsForTable,
  useHandleOnSubmit,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
  useTrendContent,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { LoadIcon, MinusBlueIcon, PlusBlueIcon } from '../../../../icons';
import {
  IEntity,
  IMainFilterForm,
  ISelectOption,
  ITimePeriodTableForm,
  ITrendPlayerStatsTableData,
} from '../../../../types';
import { createClassNames, getPlayerShortName, isFormValid } from '../../../../utils';
import './TrendContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

const classNames = createClassNames('goalkeepers-trend-content');

export const TrendContent = () => {
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const {
    isLoading,
    selectedMetric,
    tableTimePeriodType,
    tableDataBy,
    trend,
    tableTimePeriodPlayerStats,
  } = useAppSelector(selectTrend);
  const {
    trendChart: { trendChartData, trendGoalkeeper },
    trendTimePeriodTableFormData: { rowCount, timePeriodForm, tableFormDataBy },
  } = useAppSelector(trendDataSelector);
  const dispatch = useAppDispatch();

  const [showLeagueAverage, setShowLeagueAverage] = useState(true);
  const [showMetric, setShowMetric] = useState(true);

  const { formState } = useFormContext<IMainFilterForm>();
  const { errors } = formState;
  const disableCondition = !isFormValid(errors);

  const { control, handleSubmit, reset } = useForm<ITimePeriodTableForm>({
    defaultValues: DEFAULT_TIME_PERIOD_TABLE_FORM_VALUES,
  });

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const data = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(
    control,
    centerPartWidth,
    IEntity.goalkeepers,
  );

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const getFilteredMetrics = useFilteredMetricsForTable();

  const { filteredMetrics, filteredMetricsOptions } = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.goalkeepers);
    const filteredMetricsOptions =
      filteredMetrics?.map<ISelectOption>(metric => ({
        value: metric.id,
        label: metric.label,
      })) || [];

    return {
      filteredMetrics,
      filteredMetricsOptions,
    };
  }, [getFilteredMetrics]);

  const isInitialMountRef = useRef(true);

  const { handleOnChangeTimePeriodType, handleMetricChange, handleAddRow, handleDeleteRow } =
    useTrendContent(control);
  const fetchTimePeriodPlayerStats = useFetchTimePeriodPlayerStats();
  const { fetchTrendGoalkeeperStats } = useFetchTrendStats();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchTrendGoalkeeperStats(values);
  }, isInitialMountRef);

  const handleOnSubmitTable = useHandleOnSubmit(mainValues => {
    handleSubmit(values => {
      reset(values);
      fetchTimePeriodPlayerStats(mainValues, values, IEntity.goalkeepers);
    })();
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit: async () => {
      handleOnSubmitTable();
      handleOnSubmit();
    },
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  useEffect(() => {
    if (filteredMetrics) {
      dispatch(
        setSelectedTrendMetric({
          value: filteredMetrics[0].id,
          label: filteredMetrics[0].label,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMetrics]);

  useEffect(() => {
    if (tableTimePeriodType && tableTimePeriodType.value !== 'custom') {
      reset(timePeriodForm);
      dispatch(setTrendTableDataRows(rowCount));
      dispatch(setTrendTableDataBy(tableFormDataBy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trend, tableTimePeriodType, tableDataBy]);

  useEffect(() => {
    handleOnSubmitTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trend]);

  if (isLoading) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, trendChartData.length);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  const renderTableOrInfoBox = () => {
    const isTableLoading = Object.values(tableTimePeriodPlayerStats).some(
      item => item.isRowLoading,
    );

    if (isTableLoading) {
      return (
        <div>
          <Skeleton height={38} />
          {Object.values(tableTimePeriodPlayerStats).map(item => (
            <div key={item.rowIndex} className={classNames('skeleton-wrapper')}>
              <Skeleton height={38} />
            </div>
          ))}
        </div>
      );
    }

    return (
      <CommonTable<ITrendPlayerStatsTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
        disableIsSmall
      />
    );
  };

  return (
    <div className={classNames()}>
      <TrendToolbar
        metricOptions={filteredMetricsOptions}
        selectedMetric={selectedMetric}
        selectedPlayerName={trendGoalkeeper ? getPlayerShortName(trendGoalkeeper) : ''}
        disablePlayerField
        onMetricChange={handleMetricChange}
        showMovingAverage={false}
        {...{
          showLeagueAverage,
          showMetric,
          setShowLeagueAverage,
          setShowMetric,
        }}
      />
      <TrendChart
        data={trendChartData}
        metricName={selectedMetric?.label || ''}
        showLeagueAverage={showLeagueAverage}
        showMovingAverage={false}
        showMetric={showMetric}
        // TODO: ligový průměr - odstranit mock data až bude hotový na API, doplnit real data
        // leagueAverage={
        //   // TODO: ligový průměr - odstranit mock data až bude hotový na API
        //   trendChartData.reduce<number>((acc, item) => {
        //     if (item && item.metricValue) {
        //       return acc + item.metricValue;
        //     }
        //     return acc;
        //   }, 0) / trendChartData.length
        // }
      />
      <div className={classNames('table')}>
        <TableToolbar
          centerTablePartRef={centerTablePartRef}
          scrollSizePx={130}
          disablePositionSelection
          extraElement={
            <>
              <div>
                <Caption label={ITranslationKeys.displayTimePeriod} />
                <SelectInput
                  onChange={handleOnChangeTimePeriodType}
                  selected={tableTimePeriodType}
                  options={trendTimePeriodTypeOptions}
                  placeholder={ITranslationKeys.defaultSelectPlaceholder}
                  variant='filter'
                />
              </div>
              <div className={classNames('table__data-selection')}>
                <Caption label={ITranslationKeys.dataSelectionBy} />
                <Button
                  label={ITranslationKeys.goalkeeperMatches}
                  onClick={
                    tableDataBy !== 'playerMatches'
                      ? () => dispatch(setTrendTableDataBy('playerMatches'))
                      : undefined
                  }
                  variant={tableDataBy === 'playerMatches' ? 'normal' : 'gray'}
                  primary
                />
              </div>
              <Button
                label={ITranslationKeys.teamMatches}
                onClick={
                  tableDataBy !== 'teamMatches'
                    ? () => dispatch(setTrendTableDataBy('teamMatches'))
                    : undefined
                }
                variant={tableDataBy === 'teamMatches' ? 'normal' : 'gray'}
                primary
              />
              <Button
                label={ITranslationKeys.calendar}
                onClick={
                  tableDataBy !== 'calendar'
                    ? () => dispatch(setTrendTableDataBy('calendar'))
                    : undefined
                }
                variant={tableDataBy === 'calendar' ? 'normal' : 'gray'}
                primary
              />
            </>
          }
        />
        {renderTableOrInfoBox()}
        <div className={classNames('table__bottom-actions')}>
          <Button
            label={ITranslationKeys.loadData}
            iconComponent={<LoadIcon />}
            onClick={handleOnSubmitTable}
            disabled={disableCondition}
          />
          <div className={classNames('table__bottom-actions__add-remove')}>
            <Button
              label={ITranslationKeys.deleteTimePeriod}
              iconComponent={<MinusBlueIcon />}
              onClick={handleDeleteRow}
            />
            <Button
              label={ITranslationKeys.addNextTimePeriod}
              iconComponent={<PlusBlueIcon />}
              onClick={handleAddRow}
            />
          </div>
        </div>
      </div>
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.goalkeepers}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.goaliesData}
          onIceName={ITranslationKeys.teamsData}
          entity={IEntity.goalkeepers}
        />
      )}
    </div>
  );
};
