import { FC } from 'react';

import { PopupArrowIcon } from '../../../icons';
import { IHelpTextItem } from '../../../types';
import { createClassNames } from '../../../utils';
import './ModalItem.styles.scss';

export interface IModalItemProps {
  /** Modal item to be displayed. */
  modalItem: IHelpTextItem;
}

const classNames = createClassNames('modal-item');

/** A component that shows IModalItem with styles. */
export const ModalItem: FC<IModalItemProps> = ({ modalItem }) => {
  return (
    <div className={classNames()}>
      <div className={classNames('icon')}>
        <PopupArrowIcon />
      </div>
      <div className={classNames('text')}>
        <h4>{modalItem.title}</h4>
        <p dangerouslySetInnerHTML={{ __html: modalItem.text }}></p>
      </div>
    </div>
  );
};
