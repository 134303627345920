import {
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationOptions,
  shotTypeOptions,
} from '../../constants';
import { IMetricParamsFilterState, IUrlParam } from '../../types';
import { getDefaultPredefinedOptionByValue } from '../common.utils';
import {
  parseUrlNetZoneArray,
  parseUrlStaticSelectOption,
  parseUrlStaticSelectOptionArray,
} from '../url.utils';

export const parseUrlMetricParamsFilterParams = (
  searchParams: URLSearchParams,
): Partial<IMetricParamsFilterState> => {
  const shotLocation = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.shotLocation,
    shotLocationOptions,
  );
  const shotDanger = parseUrlStaticSelectOptionArray(
    searchParams,
    IUrlParam.shotDanger,
    shotDangerOptions,
  );
  const shotType = parseUrlStaticSelectOption(searchParams, IUrlParam.shotType, shotTypeOptions);
  const gameActionType = parseUrlStaticSelectOption(
    searchParams,
    IUrlParam.gameActionType,
    shotGameActionTypeOptions,
  );
  const netZones = parseUrlNetZoneArray(searchParams);

  return {
    shotLocation: shotLocation || getDefaultPredefinedOptionByValue(shotLocationOptions),
    shotDanger: shotDanger || [getDefaultPredefinedOptionByValue(shotDangerOptions)],
    shotType: shotType || getDefaultPredefinedOptionByValue(shotTypeOptions),
    gameActionType: gameActionType || getDefaultPredefinedOptionByValue(shotGameActionTypeOptions),
    netZones: netZones || [],
  };
};
