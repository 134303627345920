import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import { IReturnTypeGetPlayerCardInfoAction } from '../../types';
import { defaultCatchErrorCallback } from '../../utils';
import { parsePlayerCardInfoData } from './parsePlayers';

interface IGetCompetitionDetailParams {
  competitionUuid: string;
  teamUuid: string;
  playerUuid: string;
  isCalledFromComparePlayers?: boolean;
}

export const getPlayerCardInfo = createAsyncThunk<
  IReturnTypeGetPlayerCardInfoAction,
  IGetCompetitionDetailParams
>('player/getPlayerCardInfo', async ({ competitionUuid, teamUuid, playerUuid }) => {
  const playerInfo: IReturnTypeGetPlayerCardInfoAction = await axiosInstance
    .get(`/player/${competitionUuid}/${teamUuid}/${playerUuid}`)
    .then(response => parsePlayerCardInfoData(response.data, competitionUuid, teamUuid))
    .catch(defaultCatchErrorCallback);

  return playerInfo;
});
