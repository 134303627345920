import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../app/hooks';
import { Modal, ModalContent } from '../components';
import { selectDataSettingsFilter, selectHelp } from '../features';
import i18n from '../i18n/i18n';
import { ITranslationKeys } from '../i18n/types';
import { IHelpItem, ILanguageType } from '../types';
import { findHelpItemsByContentIds, getTranslationKeyForNavigationKey } from '../utils';
import { useLocationPaths } from './useLocationPaths';

export const useNewsModal = () => {
  const { help, helpConfig } = useAppSelector(selectHelp);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { activePage, activeTab } = useLocationPaths();

  const { t } = useTranslation();

  const [openNews, setOpenNews] = useState(true);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const hiddenNewsIds = localStorage.getItem('hiddenNewsIds')?.split(';') || [];

  useEffect(() => {
    setOpenNews(true);
    setDontShowAgain(false);
  }, [activePage, activeTab]);

  const handleCloseNewsModal = (newsItems: IHelpItem[]) => {
    if (dontShowAgain) {
      const newHiddenNewsIds = [...hiddenNewsIds, ...newsItems.map(item => item.id)];
      localStorage.setItem('hiddenNewsIds', newHiddenNewsIds.join(';'));
    }
    setOpenNews(false);
  };

  const getNewsModal = (pageName: string, tabName: string) => {
    if (!help || !helpConfig) return null;

    const screenId = `${pageName}_${open ? 'data-settings' : tabName}`;
    const screenConfig = helpConfig[screenId];

    if (!screenConfig) return null;

    const news = findHelpItemsByContentIds(help.news, screenConfig.news);

    if (!news) {
      console.error(`[useNewsModal]: News for ${pageName} - ${tabName} not found`);
      return null;
    }

    const language = i18n.language as keyof ILanguageType<string>;
    const visibleNewsItems = news.filter(item => !hiddenNewsIds.includes(item.id));

    if (!visibleNewsItems.length) {
      return null;
    }

    return (
      <Modal open={openNews} size='medium'>
        <ModalContent
          header={`${t(ITranslationKeys.newsAtPage)} ${t(activePage)} - ${t(
            getTranslationKeyForNavigationKey(activeTab),
          )}`}
          news={visibleNewsItems}
          checkboxChecked={dontShowAgain}
          language={language}
          handleCheckboxClick={() => setDontShowAgain(!dontShowAgain)}
          handleClose={() => handleCloseNewsModal(visibleNewsItems)}
        />
      </Modal>
    );
  };

  return getNewsModal;
};
