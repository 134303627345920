import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../../services/axiosInstance';
import {
  IGamesRequestBody,
  IGamesTablesFormations,
  IPlayerWithTeamIdAndStats,
  IPlayerWithTeamIdAndStatsAndLineup,
} from '../../../types';
import { defaultCatchErrorCallback } from '../../../utils';
import { parseGamesTablesFormationData, parseGamesTablesPlayersData } from './parseGamesTables';

interface IBaseGamesParams {
  competitionsUuids: string[];
}

interface IGetPlayersParams extends IBaseGamesParams {
  teamUuid: string;
  matchUuid: string;
  body: IGamesRequestBody;
}

export const getGamesTablesPlayers = createAsyncThunk<
  IPlayerWithTeamIdAndStatsAndLineup[],
  IGetPlayersParams
>('gamesTables/getGamesTablesPlayers', async ({ competitionsUuids, teamUuid, matchUuid, body }) => {
  const data: IPlayerWithTeamIdAndStatsAndLineup[] = await axiosInstance
    .post(`/match/${competitionsUuids}/${matchUuid}/${teamUuid}/player`, body)
    .then(response => parseGamesTablesPlayersData(response.data))
    .catch(defaultCatchErrorCallback);

  return data;
});

export const getGamesTablesGoalkeepers = createAsyncThunk<
  IPlayerWithTeamIdAndStats[],
  IGetPlayersParams
>(
  'gamesTables/getGamesTablesGoalkeepers',
  async ({ competitionsUuids, teamUuid, matchUuid, body }) => {
    const data: IPlayerWithTeamIdAndStats[] = await axiosInstance
      .post(`/match/${competitionsUuids}/${matchUuid}/${teamUuid}/goalkeeper`, body)
      .then(response => parseGamesTablesPlayersData(response.data));

    return data;
  },
);

export const getGamesTablesFormations = createAsyncThunk<
  IGamesTablesFormations[],
  IGetPlayersParams
>(
  'gamesTables/getGamesTablesFormations',
  async ({ competitionsUuids, teamUuid, matchUuid, body }) => {
    const data: IGamesTablesFormations[] = await axiosInstance
      .post(`/match/${competitionsUuids}/${matchUuid}/${teamUuid}/formation`, body)
      .then(response => parseGamesTablesFormationData(response.data))
      .catch(defaultCatchErrorCallback);

    return data;
  },
);
