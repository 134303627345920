import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';

import { Changelog, Footer, Modal, NavigationItem } from '../../../components';
import { useChangelog, useLoadInitialData, useLocationPaths } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { ArrowNarrowIcon, DeviceDesktopAnalyticsIcon } from '../../../icons';
import { changelog } from '../../../jsons';
import { INavigationPageKeys } from '../../../types';
import { createClassNames } from '../../../utils';
import { NAVIGATION_PATHS_CMS, NAVIGATION_PATHS_TRACKING } from '../constants';
import './NavigationBarTcms.styles.scss';

const classNames = createClassNames('navigation-bar-tcms');

export const NavigationBarTcms = () => {
  useLoadInitialData();

  const { isChangelogOpen, lastVersion, openChangelog, closeChangelog } = useChangelog();
  const { activePage, activeTab } = useLocationPaths();
  const { t } = useTranslation();

  return (
    <div className='app-view-tcms' data-testid='app-view-tcms'>
      <div className='navigation-container'>
        <div className={classNames()} data-testid='navigation-bar-tcms'>
          <div className={classNames('item-list')}>
            {activePage === INavigationPageKeys.tracking
              ? NAVIGATION_PATHS_TRACKING.map(path => {
                  const pathTo = '/tracking' + path.to;

                  return (
                    <NavigationItem
                      key={path.label}
                      label={path.label}
                      to={pathTo}
                      isActive={`/${activePage}/${activeTab}` === pathTo}
                      iconComponent={<DeviceDesktopAnalyticsIcon />}
                    />
                  );
                })
              : NAVIGATION_PATHS_CMS.map(path => {
                  const pathTo = '/cms' + path.to;

                  return (
                    <NavigationItem
                      key={path.label}
                      label={path.label}
                      to={pathTo}
                      isActive={`/${activePage}/${activeTab}` === pathTo}
                      iconComponent={path.Icon && <path.Icon />}
                    />
                  );
                })}
          </div>
          <Link to='/dashboard' className={classNames('dashboard-link')}>
            <button>
              {t(ITranslationKeys.backToApp)}
              <ArrowNarrowIcon />
            </button>
          </Link>
        </div>
      </div>
      <Outlet />
      <Footer
        year={new Date().getFullYear()}
        version={lastVersion.id}
        onChangelogClick={openChangelog}
      />
      <Modal open={isChangelogOpen} size='medium'>
        <Changelog changelog={changelog} handleClose={closeChangelog} />
      </Modal>
    </div>
  );
};
