import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { mediaOptions } from '../../../constants';
import { ITranslationKeys } from '../../../i18n/types';
import {
  IAnalyticsType,
  IChartTooltipEntity,
  IScreenAnalyticsType,
  ITrackingUserType,
} from '../../../types';
import { createClassNames } from '../../../utils';
import './BarChartTooltip.styles.scss';

export interface IBarChartTooltipProps {
  /** Analytics type. */
  analyticsType: IAnalyticsType | IScreenAnalyticsType;
  /** Data key for which tooltip should be displayed. */
  dataKey: string;
  /** Total value. */
  total: number;
  /** Users to be displayed as top */
  users: IChartTooltipEntity[];
  /** Should display also total value or only calculated percentage. */
  displayAlsoTotalValue?: boolean;
  /** Should display category "other" */
  displayCategoryOther?: boolean;
  /** Value to be displayed. */
  value?: number;
}

const classNames = createClassNames('bar-chart-tooltip');

/** Tooltip content component for BarChart's Tooltip component. */
export const BarChartTooltip: FC<IBarChartTooltipProps> = ({
  analyticsType,
  dataKey,
  total,
  users,
  value,
  displayAlsoTotalValue = true,
  displayCategoryOther = true,
}) => {
  const { t } = useTranslation();
  const percentage = total > 0 && value ? (value * 100) / total : 0;
  const roundedPercentage = `${Math.round(percentage * 10) / 10}%`;
  const displayValue = displayAlsoTotalValue
    ? `${value} (${roundedPercentage})`
    : roundedPercentage;

  return (
    <div className={classNames({ type: dataKey })}>
      <div className={classNames('header')}>
        {dataKey === ITrackingUserType.other ? t(ITranslationKeys.others) : t(dataKey)}
        <span>{displayValue}</span>
      </div>
      {renderTopUsers(users, analyticsType, dataKey, displayCategoryOther)}
    </div>
  );
};

const renderTopUsers = (
  users: IChartTooltipEntity[],
  analyticsType: IAnalyticsType | IScreenAnalyticsType,
  dataKey: string,
  displayCategoryOther: boolean,
) => {
  if (
    analyticsType === IAnalyticsType.uniqueAccessCount ||
    (dataKey === ITrackingUserType.other && !displayCategoryOther)
  ) {
    return null;
  }

  return (
    <>
      <hr />
      <div className={classNames('top')}>
        {users.map(({ name, analyticsCount }) => {
          const displayName =
            dataKey === ITrackingUserType.media
              ? mediaOptions.find(option => option.value === name)?.label || name
              : name;

          return (
            <div key={name}>
              {displayName} (<span>{analyticsCount}</span>)
            </div>
          );
        })}
      </div>
    </>
  );
};
