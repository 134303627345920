import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { Caption, Input, Wysiwyg } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { TcmsSaveIcon } from '../../../../../../icons';
import { CustomEditor, CustomElement, IHelpTextItem } from '../../../../../../types';
import {
  createClassNames,
  slateToHtml,
  textToSlateValue,
  transformObjectKeysToKebabCase,
} from '../../../../../../utils';
import './HelpItemEdit.styles.scss';

export interface IHelpItemEditProps {
  item: IHelpTextItem;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  updateItem: (item: IHelpTextItem) => void;
  isNew?: boolean;
}
const classNames = createClassNames('help-item-edit');

export const HelpItemEdit: FC<IHelpItemEditProps> = ({
  item,
  isDirty,
  setIsDirty,
  updateItem,
  isNew = false,
}) => {
  const [activeContent, setActiveContent] = useState<CustomElement[]>([]);
  const [activeTitle, setActiveTitle] = useState<string>('');

  const { t } = useTranslation();

  const editorRef = useRef<CustomEditor>(withHistory(withReact(createEditor())));
  const inputRef = useRef<HTMLInputElement | null>(null);

  const originalContent = useMemo(() => textToSlateValue(item.text), [item]);

  useEffect(() => {
    setActiveTitle(item.title);
    setActiveContent(originalContent);
    editorRef.current = withHistory(withReact(createEditor()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    const isTitleEdited = item.title !== activeTitle;
    const isTextEdited = !areArraysEqual(originalContent, activeContent);
    const isItemEdited = isTitleEdited || isTextEdited;

    if (isDirty !== isItemEdited) {
      setIsDirty(isItemEdited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTitle, activeContent]);

  const handleSaveButtonClick = () => {
    if (!activeContent || activeContent.length === 0 || !activeTitle.trim()) return;

    updateItem({
      title: activeTitle,
      text: slateToHtml(activeContent),
    });
  };

  if (!item) return <div className='content-info-box'>{t(ITranslationKeys.noDataForSelected)}</div>;

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isDirty,
        }),
      })}
    >
      <h3>{t(isNew ? ITranslationKeys.itemAddition : ITranslationKeys.itemEditation)}</h3>
      <div className={classNames('title')}>
        <Caption label={ITranslationKeys.caption} variant='tcms' color='slate' />
        <Input
          onChange={event => setActiveTitle(event.target.value)}
          value={activeTitle}
          placeholder={t(ITranslationKeys.enterTitleOfItem).toString()}
          variant='filter'
          ref={inputRef}
        />
      </div>
      <div className={classNames('wysiwyg')}>
        <Caption label='Text' variant='tcms' color='slate' />
        <Wysiwyg
          contentValue={activeContent}
          onContentChange={content => setActiveContent(content)}
          editor={editorRef.current}
        />
        <div
          className={classNames('wysiwyg__save-button')}
          onClick={isDirty ? handleSaveButtonClick : undefined}
        >
          <TcmsSaveIcon />
          {t(ITranslationKeys.save)}
        </div>
      </div>
    </div>
  );
};

const areArraysEqual = (originalArray: CustomElement[], array: CustomElement[]) => {
  if (originalArray.length !== array.length) return false;

  if (JSON.stringify(originalArray) !== JSON.stringify(array)) return false;

  return true;
};
