import { useEffect, useRef } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../app/hooks';
import { Button, GameResult, GoalsOverview, Loading } from '../../../../components';
import {
  countOfPlayersGamesBlockOneOptions,
  countOfPlayersGamesBlockTwoOptions,
  gamePartDefaultOption,
} from '../../../../constants';
import {
  reportConfigsSelector,
  selectGames,
  selectSettings,
  selectTeams,
  selectedGameSelector,
} from '../../../../features';
import {
  useExportAsImage,
  useFetchReport,
  useHandleOnSubmit,
  useHandleOnSubmitSubForm,
  useVideoCenter,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IExportRows,
  IGamesReportBlockTableData,
  IGamesReportForm,
  IMainFilterForm,
  IShot,
} from '../../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  handleExportToXLSX,
  roundNumberTo2Decimals,
} from '../../../../utils';
import './ReportContent.styles.scss';
import { getColumns } from './columns';
import { ReportTableBox, TeamsTableBox } from './components';
import { useDataForTable } from './useDataForTable';

const classNames = createClassNames('report-content');

export const ReportContent = () => {
  const selectedGame = useAppSelector(selectedGameSelector);
  const { games, gamesReport } = useAppSelector(selectGames);
  const { reportMetrics } = useAppSelector(selectSettings).reportMetrics;
  const { goalOverviewConfig, gameResultConfig } = useAppSelector(reportConfigsSelector);
  const teams = useAppSelector(selectTeams);
  const { block1, block2, isBlockOneLoading, isBlockTwoLoading } = gamesReport;

  const isInitialMountRef = useRef(true);

  const { formState } = useFormContext<IMainFilterForm>();

  const methodsBlockOne = useForm<IGamesReportForm>({
    defaultValues: {
      gamePart: gamePartDefaultOption,
      countOfPlayers: countOfPlayersGamesBlockOneOptions[0],
    },
  });

  const methodsBlockTwo = useForm<IGamesReportForm>({
    defaultValues: {
      gamePart: gamePartDefaultOption,
      countOfPlayers: countOfPlayersGamesBlockTwoOptions[0],
    },
  });

  const { t } = useTranslation();
  const { playVideos } = useVideoCenter(undefined, ITranslationKeys.goals);

  const { fetchGameSummary, fetchGameBlockOne, fetchGameBlockTwo } = useFetchReport();

  const handleOnSubmitSummary = useHandleOnSubmit(() => {
    fetchGameSummary();
  }, isInitialMountRef);

  const handleOnSubmitBlockOne = useHandleOnSubmitSubForm(values => {
    fetchGameBlockOne(values);
  }, methodsBlockOne);

  const handleOnSubmitBlockTwo = useHandleOnSubmitSubForm(values => {
    fetchGameBlockTwo(values);
  }, methodsBlockTwo);

  const dataTableOne = useDataForTable(block1, reportMetrics?.reportEvenStrength);
  const dataTableTwo = useDataForTable(block2, reportMetrics?.reportPowerPlay);

  useEffect(() => {
    if (selectedGame) {
      handleOnSubmitSummary();
      handleOnSubmitBlockOne();
      handleOnSubmitBlockTwo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGame]);

  const handlePlayTeamGoalsClick = (team: 'home' | 'away') => {
    const homeTeamGoals = goalOverviewConfig
      .filter(goal => goal.team === team)
      .map(goal => goal.goalData.shot)
      .filter((shot): shot is IShot => !!shot);

    playVideos(homeTeamGoals);
  };

  const handlePlayAllGoalsClick = () => {
    playVideos(
      goalOverviewConfig.map(goal => goal.goalData.shot).filter((shot): shot is IShot => !!shot),
    );
  };

  /* Export dat */
  const refTableOne = useRef<HTMLDivElement>(null);
  const refTableTwo = useRef<HTMLDivElement>(null);
  const exportTableOne = useExportAsImage(refTableOne, `hokejLogic-report-even-strength`, 'png');
  const exportTableTwo = useExportAsImage(refTableTwo, `hokejLogic-report-power-plays`, 'png');

  const commonHeader = [
    'Atribut',
    'Počet domácí',
    '% domácí',
    'Počet hosté',
    '% hosté',
    'Průměr domácí',
    'V lize domácí',
    'Průměr hosté',
    'V lize hosté',
  ];

  const getToiedValueOrNumber = (code: string, value: number) =>
    code.toLowerCase().includes('toi') ? roundNumberTo2Decimals(value / 60) : value;

  const tableRowCallback = (row: IGamesReportBlockTableData): IExportRows => {
    const matchHomeMetricType = getToiedValueOrNumber(
      row.homeTeamMatchStats.code,
      row.homeTeamMatchStats.value,
    );
    const matchAwayMetricType = getToiedValueOrNumber(
      row.awayTeamMatchStats.code,
      row.awayTeamMatchStats.value,
    );
    const matchHomeMetricPercent = row.homeTeamMatchStats.percent ?? '';
    const matchAwayMetricPercent = row.awayTeamMatchStats.percent ?? '';
    const competitionHomeAverage = getToiedValueOrNumber(
      row.homeTeamMatchStats.code,
      row.homeTeamCompetitionStats.value,
    );
    const competitionAwayAverage = getToiedValueOrNumber(
      row.awayTeamMatchStats.code,
      row.awayTeamCompetitionStats.value,
    );
    const competitionHomeIndex = row.homeTeamCompetitionStats.percent ?? '';
    const competitionAwayIndex = row.awayTeamCompetitionStats.percent ?? '';

    return {
      metricName: row.name,
      matchHomeMetricType,
      matchHomeMetricPercent,
      matchAwayMetricType,
      matchAwayMetricPercent,
      competitionHomeAverage,
      competitionHomeIndex,
      competitionAwayAverage,
      competitionAwayIndex,
    };
  };

  if (games.isLoading || !games.isAllGamesLoaded) {
    return <Loading />;
  }

  if (!selectedGame) {
    return (
      <div className='content-info-box'>
        {Object.values(formState.errors).map((error, index) => (
          <div key={`${error.message}_${index}`}>
            {error.message ? t(error.message.toString()) : error.message}
          </div>
        ))}
      </div>
    );
  }

  const homeTeam = teams.byId[selectedGame.homeTeamId];
  const awayTeam = teams.byId[selectedGame.awayTeamId];

  return (
    <div className={classNames()}>
      {gameResultConfig && <GameResult {...gameResultConfig} />}
      <GoalsOverview
        data={goalOverviewConfig}
        isLoading={gamesReport.isLoading}
        onPlayClick={playVideos}
      />
      <div className={classNames('summary-buttons')}>
        <Button
          label={`${t(ITranslationKeys.playGoals)} ${homeTeam.shortcut}`}
          onClick={() => handlePlayTeamGoalsClick('home')}
        />
        <Button label={ITranslationKeys.playAllGoals} onClick={handlePlayAllGoalsClick} />
        <Button
          label={`${t(ITranslationKeys.playGoals)} ${awayTeam.shortcut}`}
          onClick={() => handlePlayTeamGoalsClick('away')}
        />
      </div>
      <hr />
      <div className={classNames('report-title')}>
        <div className={classNames('report-title__main')}>{t(ITranslationKeys.gameReport)}</div>
        <div className={classNames('report-title__sub')}>
          {homeTeam.name} - {awayTeam.name} {formatDateByLanguage(new Date(selectedGame.date), 'P')}
        </div>
      </div>
      <div className={classNames('tables')}>
        <ReportTableBox
          data={dataTableOne}
          columns={getColumns('reportEvenStrength')}
          methods={methodsBlockOne}
          countOfPlayersOptions={countOfPlayersGamesBlockOneOptions}
          isLoading={isBlockOneLoading}
          tableLabel={ITranslationKeys.statsInGame5on5}
          fetchCallbackOnClick={fetchGameBlockOne}
          onExportDataClick={() =>
            handleExportToXLSX(
              'zápasy-report-tabulky-5-na-5',
              commonHeader,
              tableRowCallback,
              dataTableOne,
            )
          }
          ref={refTableOne}
          exportTable={exportTableOne}
        />
        <ReportTableBox
          data={dataTableTwo}
          columns={getColumns('reportPowerPlay')}
          methods={methodsBlockTwo}
          countOfPlayersOptions={countOfPlayersGamesBlockTwoOptions}
          isLoading={isBlockTwoLoading}
          tableLabel={ITranslationKeys.statsInPowerPlay}
          fetchCallbackOnClick={fetchGameBlockTwo}
          onExportDataClick={() =>
            handleExportToXLSX(
              'zápasy-report-tabulka-přesilové-hry',
              commonHeader,
              tableRowCallback,
              dataTableTwo,
            )
          }
          ref={refTableTwo}
          exportTable={exportTableTwo}
        />
        <TeamsTableBox isInitialMountRef={isInitialMountRef} isSelectInputsDropUp />
      </div>
    </div>
  );
};
