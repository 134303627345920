import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  filteredTrendSelector,
  getTimePeriodFormationStats,
  getTimePeriodGoalkeeperStats,
  getTimePeriodPlayerStats,
  selectMainFilter,
  selectTrend,
} from '../../features';
import {
  IEntity,
  IFormation,
  IMainFilterForm,
  ISelectOption,
  ISelectedPlayerItem,
  ITimePeriodTableForm,
  ITrendRequestBody,
} from '../../types';
import { isAtLeastOnePlayerOn } from '../../utils';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

const generateFormation = (
  filteredSelectedPlayers: ISelectedPlayerItem[],
  selectedGoalkeeper: ISelectOption | null | undefined,
  isGoalkeeper: boolean,
): IFormation[] => {
  if (selectedGoalkeeper && isGoalkeeper) {
    return [
      {
        player: selectedGoalkeeper.value,
        on: true,
      },
    ];
  }

  return filteredSelectedPlayers.map(item => ({
    player: item.selectedPlayer?.value || '',
    on: item.isActive ?? false,
  }));
};

export const useFetchTimePeriodPlayerStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam, selectedPlayerItems, selectedGoalkeeper } =
    useAppSelector(selectMainFilter);
  const { tableDataBy } = useAppSelector(selectTrend);
  const filteredTrend = useAppSelector(filteredTrendSelector);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchTimePeriodPlayerStats = (
    data: Partial<IMainFilterForm>,
    trendData: ITimePeriodTableForm,
    entity?: IEntity,
  ) => {
    const competitionsUuids = filteredParts.map(part => part.id);

    const filteredSelectedPlayers = selectedPlayerItems
      ? Object.values(selectedPlayerItems).filter(item => item.selectedPlayer)
      : [];
    const formation = generateFormation(
      filteredSelectedPlayers,
      selectedGoalkeeper,
      entity === IEntity.goalkeepers,
    );

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: ITrendRequestBody = {
      ...requestBodyBase,
      formation: formation.length > 1 ? formation : undefined,
      metrics: normalizeMetrics(undefined, entity),
    };

    const isSomePlayerOn =
      isAtLeastOnePlayerOn(selectedPlayerItems, false) ||
      (entity === IEntity.goalkeepers && selectedGoalkeeper) ||
      entity === IEntity.teams;
    const isSelectionValid =
      filteredParts.length > 0 && selectedTeam && selectedTeam.value !== 'all' && isSomePlayerOn;

    if (isSelectionValid) {
      const teamUuid = selectedTeam.value;
      if (formation.length === 1) {
        const sharedRequestParams = {
          competitionsUuids,
          teamUuid,
          playerUuid: formation[0].player,
        };

        if (tableDataBy === 'calendar') {
          trendData.timePeriodByDate.forEach((rowDate, index, array) => {
            const dataRange =
              rowDate?.from && !rowDate.to ? { ...rowDate, to: rowDate.from } : rowDate;

            if (entity === IEntity.goalkeepers) {
              dispatch(
                getTimePeriodGoalkeeperStats({
                  ...sharedRequestParams,
                  body: { ...requestBody, dateFrom: dataRange?.from, dateTo: dataRange?.to },
                  isLast: index === array.length - 1,
                  rowIndex: index,
                  goalkeeperUuid: formation[0].player,
                }),
              );
            } else {
              dispatch(
                getTimePeriodPlayerStats({
                  ...sharedRequestParams,
                  body: { ...requestBody, dateFrom: dataRange?.from, dateTo: dataRange?.to },
                  isLast: index === array.length - 1,
                  rowIndex: index,
                }),
              );
            }
          });
        } else {
          trendData.timePeriodByGames.forEach((row, index, array) => {
            const matches = filteredTrend
              .map(item => item.gameId)
              .slice(row.from ? row.from - 1 : undefined, row.to);

            if (entity === IEntity.goalkeepers) {
              dispatch(
                getTimePeriodGoalkeeperStats({
                  ...sharedRequestParams,
                  body: { ...requestBody, matches },
                  isLast: index === array.length - 1,
                  rowIndex: index,
                  goalkeeperUuid: formation[0].player,
                }),
              );
            } else {
              dispatch(
                getTimePeriodPlayerStats({
                  ...sharedRequestParams,
                  body: { ...requestBody, matches },
                  isLast: index === array.length - 1,
                  rowIndex: index,
                }),
              );
            }
          });
        }
      } else {
        const sharedRequestParams = {
          competitionsUuids,
          teamUuid,
        };

        if (tableDataBy === 'calendar') {
          trendData.timePeriodByDate.forEach((rowDate, index, array) => {
            dispatch(
              getTimePeriodFormationStats({
                ...sharedRequestParams,
                body: { ...requestBody, dateFrom: rowDate?.from, dateTo: rowDate?.to },
                isLast: index === array.length - 1,
                rowIndex: index,
              }),
            );
          });
        } else {
          trendData.timePeriodByGames.forEach((row, index, array) => {
            const matches = filteredTrend
              .map(item => item.gameId)
              .slice(row.from ? row.from - 1 : undefined, row.to);

            dispatch(
              getTimePeriodFormationStats({
                ...sharedRequestParams,
                body: { ...requestBody, matches },
                isLast: index === array.length - 1,
                rowIndex: index,
              }),
            );
          });
        }
      }
    }
  };

  return fetchTimePeriodPlayerStats;
};
