import { FC } from 'react';

import { useGetAccessLogAggregationHlQuery } from '../../../../../services/api/trackingApi';
import { filterAccessLogData } from '../../../../../utils';
import { AccessLogOverview } from './AccessLogOverview';
import { IOverviewComponentsProps } from './types';

interface IAccessLogHockeyLogicProps extends IOverviewComponentsProps {}

export const AccessLogHockeyLogic: FC<IAccessLogHockeyLogicProps> = ({
  submitValues,
  tooltipTopPlayersCount,
}) => {
  const { dateFromTo } = submitValues;
  const { data, error, isFetching } = useGetAccessLogAggregationHlQuery(dateFromTo, {
    selectFromResult: result => ({
      ...result,
      data: filterAccessLogData(result.data, submitValues),
    }),
  });

  return (
    <AccessLogOverview {...{ data, error, isFetching, submitValues, tooltipTopPlayersCount }} />
  );
};
