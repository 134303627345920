import { useAppSelector } from '../../../app/hooks';
import {
  CountOfPlayersController,
  GoalkeeperController,
  PartsController,
  SeasonsController,
  TeamController,
  TimeOnIceController,
} from '../../../components';
import { REQUIRED_GOALKEEPER } from '../../../constants';
import { selectMainFilter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import {
  CompareIcon,
  GamelogIcon,
  GoalkeepersIcon,
  ShootoutsIcon,
  ShotMapIcon,
  TabsIcon,
  TrendIcon,
  ZonesIcon,
} from '../../../icons';
import { INavigationContentKeys, IPageConfig } from '../../../types';
import { CompareGoalkeepersContent } from './CompareGoalkeepersContent';
import { GamelogContent } from './GamelogContent';
import { GoalkeeperCardContent } from './GoalkeeperCardContent';
import { ShootoutsContent } from './ShootoutsContent';
import { ShotMapContent } from './ShotMapContent';
import { TabsContent } from './TabsContent';
import { TrendContent } from './TrendContent';
import { ZonesContent } from './ZonesContent';

/** Custom hook returns config for IndividualStatsPage tabs and widgets. */
export const usePageConfig = (): IPageConfig => {
  const { selectedGoalkeeper } = useAppSelector(selectMainFilter);

  return {
    baseFilter: (
      <>
        <SeasonsController required />
        <PartsController required />
        <TeamController />
        <CountOfPlayersController />
      </>
    ),
    tabsConfig: [
      {
        id: INavigationContentKeys.tabs,
        name: ITranslationKeys.tabs,
        icon: <TabsIcon />,
        description: ITranslationKeys.tabsPlayersDescription,
        ContentComponent: TabsContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
      },
      {
        id: INavigationContentKeys.gamelog,
        name: ITranslationKeys.gamelog,
        icon: <GamelogIcon />,
        description: ITranslationKeys.gamelogPlayersDescription,
        ContentComponent: GamelogContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <CountOfPlayersController />
            <TimeOnIceController />
            <GoalkeeperController rules={{ required: REQUIRED_GOALKEEPER }} required />
          </>
        ),
        additionalInput: <GoalkeeperController />,
        disableLoadDataButton: !selectedGoalkeeper,
      },
      {
        id: INavigationContentKeys.trend,
        name: ITranslationKeys.trend,
        icon: <TrendIcon />,
        description: ITranslationKeys.trendPlayersDescription,
        ContentComponent: TrendContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <CountOfPlayersController />
            <TimeOnIceController />
            <GoalkeeperController rules={{ required: REQUIRED_GOALKEEPER }} required />
          </>
        ),
        additionalInput: <GoalkeeperController />,
        disableLoadDataButton: !selectedGoalkeeper,
      },
      {
        id: INavigationContentKeys.netZones,
        name: ITranslationKeys.netZones,
        icon: <ZonesIcon />,
        description: ITranslationKeys.netZonesDescription,
        ContentComponent: ZonesContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
      },
      {
        id: INavigationContentKeys.shotmap,
        name: ITranslationKeys.shotmap,
        icon: <ShotMapIcon />,
        description: ITranslationKeys.shotmapDescription,
        ContentComponent: ShotMapContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <CountOfPlayersController />
            <TimeOnIceController />
            <GoalkeeperController rules={{ required: REQUIRED_GOALKEEPER }} required />
          </>
        ),
        additionalInput: <GoalkeeperController />,
        disableLoadDataButton: !selectedGoalkeeper,
      },
      {
        id: INavigationContentKeys.shootouts,
        name: ITranslationKeys.shootouts,
        icon: <ShootoutsIcon />,
        description: ITranslationKeys.shootoutsGoalkeepersDescription,
        ContentComponent: ShootoutsContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController />
            <CountOfPlayersController />
            <TimeOnIceController />
            <GoalkeeperController rules={{ required: REQUIRED_GOALKEEPER }} required />
          </>
        ),
        additionalInput: <GoalkeeperController />,
        disableLoadDataButton: !selectedGoalkeeper,
      },
      {
        id: INavigationContentKeys.goalkeeperCard,
        name: ITranslationKeys.goalkeeperCard,
        icon: <GoalkeepersIcon />,
        description: ITranslationKeys.goalkeeperCardDescription,
        ContentComponent: GoalkeeperCardContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <GoalkeeperController rules={{ required: REQUIRED_GOALKEEPER }} required />
          </>
        ),
        additionalInput: <GoalkeeperController />,
        disableExtendedFilter: true,
        disableLoadDataButton: !selectedGoalkeeper,
      },
      {
        id: INavigationContentKeys.compareGoalkeepers,
        name: ITranslationKeys.compareGoalkeepers,
        icon: <CompareIcon />,
        description: ITranslationKeys.compareGoalkeepersDescription,
        ContentComponent: CompareGoalkeepersContent,
        contentFilter: (
          <>
            <SeasonsController required />
            <PartsController required />
            <TeamController required />
            <CountOfPlayersController />
            <TimeOnIceController />
          </>
        ),
      },
    ],
  };
};
