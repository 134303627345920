import type { RootState } from 'app/store';

export const selectAuth = (state: RootState) => state.auth;
export const selectBugReport = (state: RootState) => state.bugReport;
export const selectCompetitionsDetail = (state: RootState) => state.competitionsDetail;
export const selectDataSettingsFilter = (state: RootState) => state.dataSettingsFilter;
export const selectDefinedDatasets = (state: RootState) => state.settings.definedDatasets;
export const selectFaceoffs = (state: RootState) => state.faceoffs;
export const selectFilters = (state: RootState) => state.filters;
export const selectMetricParamsFilter = (state: RootState) => state.filters.metricParamsFilter;
export const selectVideomapsFilter = (state: RootState) => state.filters.videomapsFilter;
export const selectFaceoffsFilter = (state: RootState) => state.filters.faceoffsFilter;
export const selectGoalkeepersFilter = (state: RootState) => state.filters.goalkeepersFilter;
export const selectShootoutFilter = (state: RootState) => state.filters.shootoutFilter;
export const selectZonesFilter = (state: RootState) => state.filters.zonesFilter;
export const selectFormations = (state: RootState) => state.formations;
export const selectGamelog = (state: RootState) => state.gamelog;
export const selectGames = (state: RootState) => state.games;
export const selectGoalkeepers = (state: RootState) => state.goalkeepers;
export const selectGoalkeepersNetZones = (state: RootState) => state.goalkeepersNetZones;
export const selectHelp = (state: RootState) => state.help;
export const selectIdentity = (state: RootState) => state.identity;
export const selectIndividualStats = (state: RootState) => state.individualStats;
export const selectMainFilter = (state: RootState) => state.mainFilter;
export const selectMetrics = (state: RootState) => state.settings.metrics;
export const selectNavigation = (state: RootState) => state.navigation;
export const selectNavigationNotPersisted = (state: RootState) => state.navigationNotPersisted;
export const selectPasses = (state: RootState) => state.passes;
export const selectPlayers = (state: RootState) => state.players;
export const selectRanks = (state: RootState) => state.ranks;
export const selectSeasons = (state: RootState) => state.seasons;
export const selectSettings = (state: RootState) => state.settings;
export const selectShootout = (state: RootState) => state.shootout;
export const selectShots = (state: RootState) => state.shots;
export const selectSimilarPlayers = (state: RootState) => state.similarPlayers;
export const selectTableFilter = (state: RootState) => state.tableFilter;
export const selectTeams = (state: RootState) => state.teams;
export const selectTeamsFormation = (state: RootState) => state.teamsFormation;
export const selectTrend = (state: RootState) => state.trend;
export const selectUserDatasets = (state: RootState) => state.userDatasets;
export const selectVideoCenter = (state: RootState) => state.videoCenter;
export const selectVideoShifts = (state: RootState) => state.videoShifts;
export const selectWowy = (state: RootState) => state.wowy;
export const selectZones = (state: RootState) => state.zones;
