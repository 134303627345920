import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';

import { screenLogKeysMap } from '../../constants';
import {
  IAccessLog,
  IAccessLogAggregationDTO,
  IAccessLogAggregationEntityRecord,
  IAccessLogDTO,
  IEventScreenLog,
  IEventScreenLogDTO,
  ILogItemsWithTotal,
  IRoleLogAggregationDTO,
  IRoleLogAggregationDateRecord,
  IRoleLogDTO,
  IScreenLog,
  IScreenLogAggregationDTO,
  IScreenLogTabRecord,
  ITrackingUserType,
} from '../../types';
import { filterEnabledRoles } from '../../utils';

const parseAccessLogAggregation = (
  acc: IAccessLogAggregationEntityRecord,
  item: IAccessLogAggregationDTO,
  itemId: string,
  type: ITrackingUserType,
) => {
  const { accessCount, screenCount, ...restItem } = item;

  const id = type === ITrackingUserType.other ? ITrackingUserType.other : itemId;
  const exists = !!acc[id];

  if (exists) {
    acc[id].users.push({
      ...restItem,
      analytics: {
        accessCount,
        screenCount,
      },
    });

    acc[id].analytics.accessCount += accessCount;
    acc[id].analytics.screenCount += screenCount;
    acc[id].analytics.uniqueAccessCount += 1;
  } else {
    acc[id] = {
      id,
      type,
      users: [
        {
          ...restItem,
          analytics: {
            accessCount,
            screenCount,
          },
        },
      ],
      analytics: {
        accessCount,
        screenCount,
        uniqueAccessCount: 1,
      },
    };
  }
};

export const parseAccessLogAggregationData = (data: IAccessLogAggregationDTO[]) =>
  data.reduce<IAccessLogAggregationEntityRecord>((acc, item) => {
    if (item.team) {
      parseAccessLogAggregation(acc, item, item.team, ITrackingUserType.team);
      return acc;
    }

    if (item.media) {
      parseAccessLogAggregation(acc, item, item.media, ITrackingUserType.media);
      return acc;
    }

    parseAccessLogAggregation(acc, item, item.username, ITrackingUserType.other);
    return acc;
  }, {});

export const parseAccessLogData = (
  data: IAccessLogDTO[],
  meta?: FetchBaseQueryMeta,
): ILogItemsWithTotal<IAccessLog> => ({
  items: data.map(({ teamId, ...item }) => ({ ...item, team: teamId })),
  totalCount: Number(meta?.response?.headers.get('X-Total-Count')),
});

export const parseEventScreenLogData = (
  data: IEventScreenLogDTO[],
  meta?: FetchBaseQueryMeta,
): ILogItemsWithTotal<IEventScreenLog> => ({
  items: data.map(item => ({
    ...item,
    teamId: item.team,
  })),
  totalCount: Number(meta?.response?.headers.get('X-Total-Count')),
});

export const parseEventScreenLogCoachData = (
  data: IEventScreenLogDTO[],
  meta?: FetchBaseQueryMeta,
): ILogItemsWithTotal<IEventScreenLog> => ({
  items: data.map(item => ({
    ...item,
    tab: screenLogKeysMap.get(item.tab) || item.tab,
    teamId: item.team,
  })),
  totalCount: Number(meta?.response?.headers.get('X-Total-Count')),
});

const parseRoleLogAggregation = (
  acc: IRoleLogAggregationDateRecord,
  item: IRoleLogDTO,
  type: ITrackingUserType,
  name: string,
) => {
  acc[item.date][type].push({
    type,
    name,
    analytics: {
      accessCount: item.accessCount,
      screenCount: item.screenCount,
      uniqueAccessCount: item.uniqueAccessCount,
    },
  });
};

export const parseRoleLogAggregationData = (data: IRoleLogAggregationDTO[]) =>
  data.reduce<IRoleLogAggregationDateRecord>((acc, user) => {
    const datesAcc = user.aggregation.reduce<IRoleLogAggregationDateRecord>((accAgg, item) => {
      const exists = !!accAgg[item.date];

      if (!exists) {
        accAgg[item.date] = {
          date: item.date,
          team: [],
          media: [],
          other: [],
        };
      }

      if (user.team) {
        parseRoleLogAggregation(accAgg, item, ITrackingUserType.team, user.team);
        return accAgg;
      }

      if (user.media) {
        parseRoleLogAggregation(accAgg, item, ITrackingUserType.media, user.media);
        return accAgg;
      }

      parseRoleLogAggregation(accAgg, item, ITrackingUserType.other, user.role);
      return accAgg;
    }, acc);

    return datesAcc;
  }, {});

export const parseScreenLogAggregationData = (data: IScreenLogAggregationDTO[]) =>
  data.reduce<IScreenLogTabRecord>((acc, { tab, subTab, aggregation }) => {
    if (!subTab) return acc;

    const screenLogs = aggregation
      .map<IScreenLog>(item => {
        const { screenCount, uniqueScreenCount, ...restItem } = item;
        const name = item.team ?? item.media ?? 'other';

        return {
          ...restItem,
          analytics: {
            screenCount,
            uniqueScreenCount,
          },
          name,
        };
      })
      .filter(item => filterEnabledRoles(item));

    const splitAggregation = {
      [ITrackingUserType.team]: screenLogs.filter(item => !!item.team),
      [ITrackingUserType.media]: screenLogs.filter(item => !!item.media),
      [ITrackingUserType.other]: screenLogs.filter(item => !item.team && !item.media),
    };

    const exists = !!acc[tab];

    if (!exists) {
      acc[tab] = {
        name: tab,
        subTabs: {
          [subTab]: {
            name: subTab,
            aggregation: splitAggregation,
          },
        },
      };

      return acc;
    }

    const existsSubTab = !!acc[tab].subTabs[subTab];

    if (!existsSubTab) {
      acc[tab].subTabs[subTab] = {
        name: subTab,
        aggregation: splitAggregation,
      };

      return acc;
    }

    acc[tab].subTabs[subTab].aggregation[ITrackingUserType.media].push(
      ...splitAggregation[ITrackingUserType.media],
    );

    return acc;
  }, {});
