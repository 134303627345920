import { DISABLED_TABS_ON_OFF, SELECT_PLAYERS_INPUT_COLORS } from '../constants';
import {
  IDefaultPlayerColorNames,
  IDefaultPlayerColorNamesWithBlack,
  IFaceoff,
  IFaceoffAllSides,
  IFaceoffPlayerInfo,
  IFaceoffPlayerInfoByPosition,
  IFaceoffPosition,
  IFaceoffPositionKeys,
  IFaceoffPositionRecord,
  IFaceoffsWithAttributes,
  INavigationContentKeys,
  IPlaygroundFaceOffAttributes,
  ISelectOption,
  ISelectedPlayerItem,
  ITeamRecord,
} from '../types';
import { isAtLeastOnePlayerOn } from './players.utils';

const disabledOnOff = DISABLED_TABS_ON_OFF.includes(INavigationContentKeys.faceoffs);

const getCorrectFaceoffColor = (
  playerId: string,
  selectedPlayerItems: ISelectedPlayerItem[],
  isSomePlayerOn: boolean,
) => {
  const selectedPlayerForFaceoff = Object.values(selectedPlayerItems).find(
    item => item.selectedPlayer && item.selectedPlayer.value === playerId,
  );
  const index = selectedPlayerForFaceoff?.id;
  const color: IDefaultPlayerColorNames =
    index !== undefined && isSomePlayerOn ? SELECT_PLAYERS_INPUT_COLORS[index] : 'default';

  return color;
};

const getWinLossCount = (faceoffArray: IFaceoff[]): { won: number; loss: number } => {
  const won = faceoffArray.filter(faceoff => faceoff.won).length;
  const loss = faceoffArray.filter(faceoff => !faceoff.won).length;

  return {
    won,
    loss,
  };
};

const computeFaceoffPercentile = (
  count: number,
  won: number,
  loss: number,
  faceoffResult: string,
) => {
  if (count === 0) return 0;
  const multiplyValue = faceoffResult === 'all' || faceoffResult === 'won' ? won : loss;
  return Math.round((100 / count) * multiplyValue);
};

const computeTotalValuesFromFaceoffs = (faceoffs: IFaceoff[], faceoffResult: string) => {
  const count = faceoffs.length;
  const { won, loss } = getWinLossCount(faceoffs);
  const percentageValue = computeFaceoffPercentile(count, won, loss, faceoffResult);

  return {
    ratio: count.toString(),
    percentile: (percentageValue > -1 ? percentageValue : 0).toString() + '%',
  };
};

export const getFaceoffPosition = (
  positionId: IFaceoffPositionKeys,
  faceoffPlayerInfo: IFaceoffPlayerInfo,
  selectedPlayerItems: ISelectedPlayerItem[],
  faceoffResult: string,
  disableOnOff: boolean,
): IFaceoffPosition => {
  const { playerId, faceoffs } = faceoffPlayerInfo;

  const faceoffsByPosition = faceoffs.filter(faceoff => faceoff.positionId === positionId);
  const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, disableOnOff);

  const color = getCorrectFaceoffColor(playerId, selectedPlayerItems, isSomePlayerOn);
  const { ratio, percentile } = computeTotalValuesFromFaceoffs(faceoffsByPosition, faceoffResult);

  return {
    positionId,
    faceoffs: faceoffsByPosition,
    attributes: {
      ratio,
      percentile,
      color,
    },
  };
};

const getTotalAndSidesFaceoffs = (faceoffPositions: IFaceoffPositionRecord) => {
  const leftArray = [
    ...faceoffPositions.defenseDefenseLeft.faceoffs,
    ...faceoffPositions.defenseNeutralLeft.faceoffs,
    ...faceoffPositions.offenseDefenseLeft.faceoffs,
    ...faceoffPositions.offenseNeutralLeft.faceoffs,
  ];
  const rightArray = [
    ...faceoffPositions.defenseDefenseRight.faceoffs,
    ...faceoffPositions.defenseNeutralRight.faceoffs,
    ...faceoffPositions.offenseDefenseRight.faceoffs,
    ...faceoffPositions.offenseNeutralRight.faceoffs,
  ];
  const allPositionArray = faceoffPositions.center.faceoffs.concat(leftArray).concat(rightArray);

  return {
    allPositionArray,
    leftArray,
    rightArray,
  };
};

const calculateSumOnPlaygroundSide = (
  faceoffPositions: IFaceoffPositionRecord,
  side: IFaceoffAllSides,
  faceoffResult: string,
): {
  faceoffs: IFaceoff[];
  box: Omit<IPlaygroundFaceOffAttributes, 'color'>;
} => {
  const { allPositionArray, leftArray, rightArray } = getTotalAndSidesFaceoffs(faceoffPositions);

  if (side === 'left') {
    return {
      faceoffs: leftArray,
      box: computeTotalValuesFromFaceoffs(leftArray, faceoffResult),
    };
  }

  if (side === 'right') {
    return {
      faceoffs: rightArray,
      box: computeTotalValuesFromFaceoffs(rightArray, faceoffResult),
    };
  }

  return {
    faceoffs: allPositionArray,
    box: computeTotalValuesFromFaceoffs(allPositionArray, faceoffResult),
  };
};

export const getFaceoffsAttributes = (
  faceoffPlayerInfoByPosition: IFaceoffPlayerInfoByPosition,
  side: IFaceoffAllSides,
  faceoffResult: string,
  selectedPlayerItems: ISelectedPlayerItem[],
  teamRecord: ITeamRecord,
  selectedTeam?: ISelectOption | null,
) => {
  const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);

  const color = getCorrectFaceoffColor(
    faceoffPlayerInfoByPosition.playerId,
    selectedPlayerItems,
    isSomePlayerOn,
  );
  const wrapperColor: IDefaultPlayerColorNamesWithBlack = color === 'default' ? 'black' : color;

  const { faceoffs, box } = calculateSumOnPlaygroundSide(
    faceoffPlayerInfoByPosition.faceoffPositions,
    side,
    faceoffResult,
  );

  const faceoffsWithAttributes: IFaceoffsWithAttributes = {
    faceoffs,
    attributes: {
      ...box,
      color,
    },
  };

  const teamShortcut =
    selectedTeam && selectedTeam.value !== 'all' ? teamRecord[selectedTeam.value].shortcut : '';
  const label = isSomePlayerOn ? '' : teamShortcut;

  return {
    faceoffsWithAttributes,
    variant: 'dark',
    fontWeight: '600',
    label,
    wrapperColor,
  };
};
