import { FC, useState } from 'react';

import { MinusActiveIcon, PlusActiveIcon } from '../../../icons';
import { IHelpTextItem } from '../../../types';
import { createClassNames } from '../../../utils';
import './TipsItem.styles.scss';

export interface ITipsItemProps extends IHelpTextItem {}

const classNames = createClassNames('tips-item');

export const TipsItem: FC<ITipsItemProps> = ({ title, text }) => {
  const [active, setActive] = useState<boolean>(false);

  const texts = text.split('<br>');

  return (
    <div className={classNames()}>
      <div className={classNames('header')} onClick={() => setActive(!active)}>
        <h5>{title}</h5>
        {active ? <MinusActiveIcon /> : <PlusActiveIcon />}
      </div>
      <div className={classNames('description', { active })}>
        {texts.map((text, index) => (
          <span key={index} dangerouslySetInnerHTML={{ __html: text }}></span>
        ))}
      </div>
    </div>
  );
};
