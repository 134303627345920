import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Sidebar } from '../components';
import { selectDataSettingsFilter, selectHelp, setShowHelpSidebar } from '../features';
import i18n from '../i18n/i18n';
import { ILanguageType } from '../types';
import { findScreenHelp } from '../utils';

export const useHelpSidebar = () => {
  const { help, helpConfig, showHelpSidebar } = useAppSelector(selectHelp);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const dispatch = useAppDispatch();

  const getHelpSidebar = (pageName: string, tabName: string) => {
    if (!help) return null;

    const language = i18n.language as keyof ILanguageType<string>;

    const { description, tips, functions } = findScreenHelp(
      help,
      helpConfig,
      pageName,
      tabName,
      open,
    );

    if (!description) {
      return null;
    }

    return (
      <Sidebar
        isOpened={showHelpSidebar}
        title={description[language].title}
        description={description[language].text}
        functions={functions}
        tips={tips}
        onHelpClose={() => dispatch(setShowHelpSidebar(false))}
      />
    );
  };

  return getHelpSidebar;
};
