import { IHelp, IHelpItem, IHelpScreenConfigRecord } from '../../types';

export const findHelpItemsByContentIds = (
  helpItems: IHelpItem[],
  contentIds: string[],
): IHelpItem[] =>
  contentIds
    .map(contentId => helpItems.find(item => item.id === contentId))
    .filter((item): item is IHelpItem => !!item);

export const findScreenHelp = (
  help: IHelp,
  helpConfig: IHelpScreenConfigRecord,
  pageName: string,
  tabName: string,
  open: boolean,
) => {
  const screenId = `${pageName}_${open ? 'data-settings' : tabName}`;
  const screenConfig = helpConfig[screenId];

  if (!screenConfig) {
    return {
      description: null,
      tips: [],
      functions: [],
      news: [],
    };
  }

  return {
    description: help.descriptions.find(description => description.id === screenId),
    tips: findHelpItemsByContentIds(help.tips, screenConfig.tips),
    functions: findHelpItemsByContentIds(help.functions, screenConfig.functions),
    news: findHelpItemsByContentIds(help.news, screenConfig.news),
  };
};
