import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Caption, CheckboxWithDescription } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { IDefinedTemplatesCheckboxSectionProps } from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import './DefinedTemplatesCheckboxSection.styles.scss';

const classNames = createClassNames('defined-templates-checkbox-section');

export const DefinedTemplatesCheckboxSection: FC<IDefinedTemplatesCheckboxSectionProps> = ({
  label,
  options,
  filteredMetricsOptions,
  selectedMetrics,
  onItemChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      <Caption label={t(label)} variant='tcms' color='slate' />
      <hr />
      {options.map(option => (
        <div className={classNames('option')} key={option.label + '1'}>
          <Caption label={t(option.label)} variant='tcms' color='slate' />
          <div className={classNames('option__sub-option')}>
            {option.options.map(subOption => {
              const option = filteredMetricsOptions.find(
                option => option.additionalInfo.origin === subOption.value,
              );
              if (!option) return null;

              return (
                <CheckboxWithDescription
                  key={option.value}
                  description={option.label as ITranslationKeys}
                  checked={selectedMetrics.some(
                    selectedMetric => selectedMetric.value === option?.value,
                  )}
                  onClick={() => onItemChange?.(option)}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
