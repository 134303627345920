import {
  Action,
  PreloadedState,
  ThunkAction,
  TypedStartListening,
  combineReducers,
  configureStore,
  createListenerMiddleware,
} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  addAuthListeners,
  addDefinedDatasetsListeners,
  addGoalkeeperListeners,
  addPlayersListeners,
  addSeasonsListeners,
  addUserDatasetsListeners,
  authReducer,
  bugReportReducer,
  competitionsDetailReducer,
  dataSettingsFilterReducer,
  faceoffsReducer,
  filtersReducers,
  formationsReducer,
  gamelogReducer,
  gamesReducers,
  goalkeepersNetZonesReducer,
  goalkeepersReducer,
  helpReducer,
  identityReducer,
  individualStatsReducer,
  mainFilterReducer,
  navigationNotPersistedSliceSliceReducer,
  navigationReducer,
  passesReducer,
  playersReducer,
  ranksReducer,
  seasonsReducer,
  settingsReducers,
  shootoutReducer,
  shotsReducer,
  similarPlayersReducer,
  tableFilterReducer,
  teamsFormationReducer,
  teamsReducer,
  trendReducer,
  userDatasetsReducer,
  videoCenterReducer,
  videoShiftsReducer,
  wowyReducer,
  zonesReducer,
} from '../features';
import { api } from '../services/api';

const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startAppListening = listenerMiddleware.startListening as AppStartListening;

addAuthListeners(startAppListening);
addSeasonsListeners(startAppListening);
addPlayersListeners(startAppListening);
addDefinedDatasetsListeners(startAppListening);
addGoalkeeperListeners(startAppListening);
addUserDatasetsListeners(startAppListening);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'seasons',
    'competitionsDetail',
    'players',
    'teams',
    'goalkeepers',
    'navigation',
  ],
};

const rootReducer = combineReducers({
  auth: authReducer,
  identity: identityReducer,
  seasons: seasonsReducer,
  competitionsDetail: competitionsDetailReducer,
  filters: filtersReducers,
  mainFilter: mainFilterReducer,
  formations: formationsReducer,
  faceoffs: faceoffsReducer,
  gamelog: gamelogReducer,
  games: gamesReducers,
  goalkeepers: goalkeepersReducer,
  goalkeepersNetZones: goalkeepersNetZonesReducer,
  help: helpReducer,
  navigation: navigationReducer,
  navigationNotPersisted: navigationNotPersistedSliceSliceReducer,
  players: playersReducer,
  individualStats: individualStatsReducer,
  passes: passesReducer,
  ranks: ranksReducer,
  shootout: shootoutReducer,
  shots: shotsReducer,
  tableFilter: tableFilterReducer,
  teams: teamsReducer,
  videoCenter: videoCenterReducer,
  videoShifts: videoShiftsReducer,
  dataSettingsFilter: dataSettingsFilterReducer,
  wowy: wowyReducer,
  zones: zonesReducer,
  teamsFormation: teamsFormationReducer,
  trend: trendReducer,
  similarPlayers: similarPlayersReducer,
  settings: settingsReducers,
  userDatasets: userDatasetsReducer,
  bugReport: bugReportReducer,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .prepend(listenerMiddleware.middleware)
        .concat(api.middleware),
  });
};

export const store = setupStore();
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
