import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../../services/axiosInstance';
import { IHelpScreenConfig, IHelpScreenConfigRecord } from '../../../types';
import { defaultCatchErrorCallback } from '../../../utils';

interface IPutHelpScreenConfig {
  id: string;
  body: IHelpScreenConfig;
}

export const getAllHelpScreenConfigs = createAsyncThunk<IHelpScreenConfigRecord>(
  'help/getAllHelpScreenConfigs',
  async () => {
    const helpScreenConfigRecord = await axiosInstance
      .get<IHelpScreenConfigRecord>(`/help-config`)
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return helpScreenConfigRecord;
  },
);

export const getHelpScreenConfig = createAsyncThunk<IHelpScreenConfig, string>(
  'help/getHelpScreenConfig',
  async id => {
    const helpScreenConfig = await axiosInstance
      .get<IHelpScreenConfig>(`/help-config/${id}`)
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return helpScreenConfig;
  },
);

export const putHelpScreenConfig = createAsyncThunk<
  IPutHelpScreenConfig | undefined,
  IPutHelpScreenConfig
>('help/putHelpScreenConfig', async ({ id, body }) => {
  const status = await axiosInstance
    .put(`/help-config/${id}`, body)
    .then(response => response.status)
    .catch(defaultCatchErrorCallback);

  return status === 204 ? { id, body } : undefined;
});

export const deleteHelpScreenConfig = createAsyncThunk<number, string>(
  'help/deleteHelpScreenConfig',
  async id => {
    const status = await axiosInstance
      .delete(`/help-config/${id}`)
      .then(response => response.status)
      .catch(defaultCatchErrorCallback);

    return status;
  },
);
