import { ITranslationKeys } from '../i18n/types';
import {
  IHelpItemBase,
  INavigationContentKeys,
  INavigationDataPageKeys,
  ISelectOption,
} from '../types';

export const EMPTY_HELP_ITEM_BASE: IHelpItemBase = {
  cz: { title: '', text: '' },
  en: { title: '', text: '' },
};

export const helpContentTypeOptions: ISelectOption[] = [
  {
    label: ITranslationKeys.editFunctions,
    value: 'functions',
  },
  {
    label: ITranslationKeys.editNews,
    value: 'news',
  },
  {
    label: ITranslationKeys.editTips,
    value: 'tips',
  },
  {
    label: ITranslationKeys.editOpeningText,
    value: 'descriptions',
  },
];

export const helpManagementSectionKeys: ISelectOption[] = Object.values(
  INavigationDataPageKeys,
).map(key => ({
  value: key,
  label: key,
}));

export const helpManagementSubsectionKeys: ISelectOption[] = [
  ...Object.values(INavigationContentKeys).map(key => ({
    value: key,
    label: key,
  })),
  {
    label: ITranslationKeys.dataTemplates,
    value: 'data-settings',
  },
];

/** Constant with all pages (sections) and their tabs (subsections) */
export const helpSectionsWithSubsectionsRecord: Record<
  INavigationDataPageKeys,
  (INavigationContentKeys | 'data-settings')[]
> = {
  [INavigationDataPageKeys.players]: [
    INavigationContentKeys.tabs,
    INavigationContentKeys.gamelog,
    INavigationContentKeys.trend,
    INavigationContentKeys.playerCard,
    INavigationContentKeys.comparePlayers,
    INavigationContentKeys.similarityPlayers,
    'data-settings',
  ],
  [INavigationDataPageKeys.formations]: [
    INavigationContentKeys.pairs,
    INavigationContentKeys.formations,
    INavigationContentKeys.playerCombinations,
    'data-settings',
  ],
  [INavigationDataPageKeys.videomaps]: [
    INavigationContentKeys.shots,
    INavigationContentKeys.heatmap,
    INavigationContentKeys.passes,
    INavigationContentKeys.faceoffs,
    INavigationContentKeys.zoneEntries,
    INavigationContentKeys.zoneExits,
    INavigationContentKeys.shootouts,
  ],
  [INavigationDataPageKeys.goalkeepers]: [
    INavigationContentKeys.tabs,
    INavigationContentKeys.gamelog,
    INavigationContentKeys.trend,
    INavigationContentKeys.netZones,
    INavigationContentKeys.shotmap,
    INavigationContentKeys.shootouts,
    INavigationContentKeys.goalkeeperCard,
    INavigationContentKeys.compareGoalkeepers,
    'data-settings',
  ],
  [INavigationDataPageKeys.games]: [
    INavigationContentKeys.graphicOverview,
    INavigationContentKeys.report,
    INavigationContentKeys.players,
    INavigationContentKeys.formations,
    INavigationContentKeys.goalkeepers,
    INavigationContentKeys.headToHead,
    'data-settings',
  ],
  [INavigationDataPageKeys.teams]: [
    INavigationContentKeys.tabs,
    INavigationContentKeys.gamelog,
    INavigationContentKeys.trend,
    'data-settings',
  ],
};
