import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectDataSettingsFilter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { IMetric, IMetricParameters, ISelectOption } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';
import { Caption } from '../../Caption';
import { IToggleSelectProps, ToggleSelect } from '../../ToggleSelect';
import { MetricButton } from '../../metricComponents';

export interface IMetricParameterToggleProps
  extends Pick<IToggleSelectProps, 'hasOnlyOneColumn' | 'twoColumns' | 'hasSmallGap'> {
  selected: ISelectOption;
  options: ISelectOption[];
  setFunction: ActionCreatorWithPayload<ISelectOption, string>;
  parameterName: keyof IMetricParameters;
  availableMetrics: IMetric[];
  captionTranslationKey?: ITranslationKeys;
}

/** Component for toggle metric's parameters. */
export const MetricParameterToggle: FC<IMetricParameterToggleProps> = ({
  selected,
  options,
  setFunction,
  parameterName,
  availableMetrics,
  captionTranslationKey,
  hasOnlyOneColumn,
  hasSmallGap,
  twoColumns,
}) => {
  const { selectedGroup } = useAppSelector(selectDataSettingsFilter);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleChange = (value: ISelectOption) => {
    dispatch(setFunction(value));
  };

  const display =
    selectedGroup &&
    availableMetrics.some(
      metric => metric.metricGroups[selectedGroup.value].metricParameters[parameterName],
    );

  useEffect(() => {
    if (!display && selected.value !== 'all') {
      dispatch(setFunction(getDefaultPredefinedOptionByValue(options)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display]);

  if (!display) {
    return null;
  }

  return (
    <div>
      {captionTranslationKey && <Caption label={captionTranslationKey} />}
      <ToggleSelect
        onChange={value => handleChange(value)}
        selected={selected}
        customOptionRenderer={(option, onChange, selected) => {
          const isSelectedArray = Array.isArray(selected);

          const activeOption = isSelectedArray
            ? selected?.some(selected => selected.value === option.value)
            : selected?.value === option.value;

          return (
            <MetricButton
              key={option.value}
              label={t(option.label, { ...option.labelTranslationsOptions })}
              onClick={() => onChange(option)}
              isActive={activeOption}
            />
          );
        }}
        {...{ options, hasOnlyOneColumn, hasSmallGap, twoColumns }}
      />
    </div>
  );
};
