import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortEndHandler } from 'react-sortable-hoc';

import { TcmsSortableList } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { TcmsPlusIcon } from '../../../../../../icons';
import { IEntity, ITcmsItemProps } from '../../../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../../../utils';
import './DefinedTemplatesSection.styles.scss';

export interface IDefinedTemplatesSectionProps {
  activeSection: IEntity | null;
  options: ITcmsItemProps[];
  size: 'big' | 'medium' | 'small';
  onAddSortableItemClick: () => void;
  onListChange: SortEndHandler;
  activeTemplate?: ITcmsItemProps;
  onTcmsItemClick?: (option: ITcmsItemProps) => void;
}

const classNames = createClassNames('defined-templates-section');

export const DefinedTemplatesSection: FC<IDefinedTemplatesSectionProps> = ({
  activeSection,
  activeTemplate,
  size,
  options,
  onAddSortableItemClick,
  onTcmsItemClick,
  onListChange,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({ size }),
      })}
    >
      <h3>{activeSection ? t(activeSection) : ''}</h3>
      <TcmsSortableList
        selected={options.find(option => option.value === activeTemplate?.value)}
        onItemClick={onTcmsItemClick}
        onSortEnd={onListChange}
        helperClass='sortable-helper'
        lockAxis='y'
        useDragHandle
        {...{ options, size }}
      />
      <div className={classNames('add-button')} onClick={onAddSortableItemClick} role='button'>
        <TcmsPlusIcon />
        {t(ITranslationKeys.addAnotherItem)}
      </div>
    </div>
  );
};
