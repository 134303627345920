import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { IDashboardTeam, IHomeAwayTeams } from '../../types';
import { createClassNames } from '../../utils';
import { LogoCircle } from '../LogoCircle';
import { RatioBar } from '../RatioBar';
import './DashboardTeamOverview.styles.scss';

export interface IDashboardTeamOverviewProps extends IHomeAwayTeams<IDashboardTeam> {}

const classNames = createClassNames('dashboard-team-overview');

/** UI component of the dashboard team overview */
export const DashboardTeamOverview: FC<IDashboardTeamOverviewProps> = ({ homeTeam, awayTeam }) => {
  const { t } = useTranslation();

  const getWinTitle = useCallback(() => {
    if (homeTeam.xW < 55 && homeTeam.xW > 45) {
      return t(ITranslationKeys.tightGame);
    }
    if (homeTeam.score > awayTeam.score) {
      return homeTeam.xW > 55 ? t(ITranslationKeys.deservedWin) : t(ITranslationKeys.undeservedWin);
    }
    return homeTeam.xW > 55 ? t(ITranslationKeys.undeservedLoss) : t(ITranslationKeys.deservedLoss);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        <div className={classNames('team', { homeTeam: true })}>
          <div className={classNames('stats')}>
            <p>{homeTeam.score}</p>
            <p>
              xG: <span>{Number(homeTeam.xG.toFixed(2))}</span>
            </p>
          </div>
          <LogoCircle logoPath={homeTeam.logo} />
        </div>
        <div className={classNames('team', { awayTeam: true })}>
          <LogoCircle logoPath={awayTeam.logo} />
          <div className={classNames('stats')}>
            <p className={classNames('score')}>{awayTeam.score}</p>
            <p>
              xG: <span>{Number(awayTeam.xG.toFixed(2))}</span>
            </p>
          </div>
        </div>
      </div>
      <div className={classNames('bars')}>
        <RatioBar
          label={getWinTitle()}
          valueLeft={homeTeam.xW}
          valueRight={awayTeam.xW}
          showPercent={true}
        />
        <RatioBar
          label={t(ITranslationKeys.shots) ?? ''}
          valueLeft={homeTeam.shots}
          valueRight={awayTeam.shots}
          showPercent={false}
        />
      </div>
    </div>
  );
};
