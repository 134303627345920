import { FC } from 'react';

import { IGameEntity, IGameTeams, ISelectedPlayerItem } from '../../../types';
import { createClassNames, formatDateByLanguage } from '../../../utils';
import { GamesSelectItemActions } from '../GamesSelectItemActions';
import './GamesSelectItemContent.styles.scss';

export interface IGamesSelectItemContentProps {
  /** Home and away teams. */
  gameTeams: IGameTeams;
  /** Game date. */
  date: Date;
  /** Is game selected? */
  isSelected: boolean;
  /** Count of entities (shots, passes, etc.) filtered for this game. */
  filteredEntities?: IGameEntity;
  /** Is it content for heatmap tab? */
  isHeatmap?: boolean;
  /** Array of selected players to be separated from filtered entities */
  selectedPlayerItems?: ISelectedPlayerItem[];
  /** Event triggered after click on action part of content (play icon, count circle). */
  onActionClick?: () => void;
}

const classNames = createClassNames('games-select-item-content');

/** UI component for display info about game in CheckboxButton in GamesSelectList components. */
export const GamesSelectItemContent: FC<IGamesSelectItemContentProps> = ({
  gameTeams,
  date,
  isSelected,
  filteredEntities,
  isHeatmap,
  selectedPlayerItems,
  onActionClick,
}) => {
  return (
    <div className={classNames()} data-testid='games-select-item-content'>
      <div className={classNames('info')}>
        <span>
          {gameTeams.homeTeam.shortcut} - {gameTeams.awayTeam.shortcut}
        </span>
        <span className={classNames('info__date')}>{formatDateByLanguage(date, 'P')}</span>
      </div>
      {!isHeatmap && (
        <GamesSelectItemActions
          filteredEntities={filteredEntities}
          isSelected={isSelected}
          selectedPlayerItems={selectedPlayerItems}
          onActionClick={onActionClick}
        />
      )}
    </div>
  );
};
