import { FC, useState } from 'react';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ITranslationKeys } from '../../../i18n/types';
import {
  IBarMouseEnterData,
  IChartTooltipEntity,
  IScreenAnalyticsType,
  IScreenBarChartData,
  IScreenBarChartEntity,
  ISelectOption,
  ITrackingUserType,
} from '../../../types';
import { createClassNames } from '../../../utils';
import { BarChartTooltip } from '../BarChartTooltip';
import { getTeamMediaOtherStackedBars } from '../utils';
import { BarTick } from './BarTick';
import './ScreenBarChart.styles.scss';

export interface IScreenBarChartProps {
  /** Data to display. */
  data: IScreenBarChartData[];
  /** Analytics type */
  selectedAnalytics: ISelectOption;
  /** If true, component has buttons instead of labels. */
  hasButtons?: boolean;
  /** Heigh of component. */
  height?: string | number;
  /** Selected button */
  selectedCategory?: ITranslationKeys | string;
  /** Number of top players to display in tooltip. */
  tooltipTopPlayersCount?: number;
  /** Function to call when a bar is clicked. */
  onClick?: (item: IScreenBarChartData) => void;
}

const layout = 'vertical';

const classNames = createClassNames('screen-bar-chart');

export const ScreenBarChart: FC<IScreenBarChartProps> = ({
  data,
  hasButtons = false,
  height = 322,
  selectedAnalytics,
  selectedCategory,
  tooltipTopPlayersCount = 5,
  onClick,
}) => {
  const [mouseEnterData, setMouseEnterData] = useState<IBarMouseEnterData | undefined>();
  const analyticsType = selectedAnalytics.value as IScreenAnalyticsType;

  return (
    <div className={classNames()}>
      <div className={classNames('gradient-element')}></div>
      <ResponsiveContainer width='100%' height={height} className={classNames('chart')}>
        <BarChart
          layout={layout}
          data={data}
          margin={{
            top: 20,
          }}
          barSize={12}
        >
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis type='number' tickLine={false} axisLine={false} tick={false} />
          <YAxis
            dataKey='label'
            type='category'
            tickLine={false}
            interval={0}
            axisLine={false}
            tick={props => {
              const item = data.find(item => item.label === props.payload.value);
              if (!item)
                return <BarTick {...props} total={0} hasButtons={hasButtons} onClick={onClick} />;

              const total = item.media + item.team + item.other;
              return (
                <BarTick
                  {...props}
                  total={total}
                  hasButtons={hasButtons}
                  isSelected={item.label === selectedCategory}
                  onClick={() => onClick?.(item)}
                />
              );
            }}
          />
          <Tooltip
            cursor={{ fill: 'none' }}
            content={(props: TooltipProps<number, string>) => {
              const { active, payload } = props;
              if (!payload?.[0]?.payload) {
                return null;
              }

              const { teamEntities, mediaEntities, otherEntities }: IScreenBarChartData =
                payload[0].payload;

              const dataKey =
                mouseEnterData?.tooltipPayload[0].dataKey ??
                decideAlternativeDataKey(teamEntities, mediaEntities, otherEntities);
              const dataPayload = payload?.find(item => item.dataKey === dataKey);

              if (!active || !dataPayload) {
                return null;
              }

              const { team, media, other }: IScreenBarChartData = dataPayload.payload;
              const total = media + team + other;
              const value = dataPayload.value;

              const filteredUsers = [
                ...(dataKey === ITrackingUserType.team
                  ? teamEntities
                  : dataKey === ITrackingUserType.media
                  ? mediaEntities
                  : otherEntities),
              ]
                .map<IChartTooltipEntity>(({ name, analytics }) => ({
                  name: name,
                  analyticsCount: analytics[analyticsType],
                }))
                .reduce<IChartTooltipEntity[]>((acc, user) => {
                  const existingUser = acc.find(item => item.name === user.name);
                  if (existingUser) {
                    acc[acc.indexOf(existingUser)].analyticsCount += user.analyticsCount;
                    return acc;
                  }

                  acc.push(user);
                  return acc;
                }, [])
                .sort((a, b) => b.analyticsCount - a.analyticsCount)
                .slice(0, tooltipTopPlayersCount);

              return (
                <BarChartTooltip
                  dataKey={dataKey}
                  users={filteredUsers}
                  displayAlsoTotalValue
                  displayCategoryOther={false}
                  {...{ analyticsType, total, value }}
                />
              );
            }}
            separator=''
          />
          {getTeamMediaOtherStackedBars(
            layout,
            data => setMouseEnterData(data),
            () => setMouseEnterData(undefined),
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const decideAlternativeDataKey = (
  team: IScreenBarChartEntity[],
  media: IScreenBarChartEntity[],
  other: IScreenBarChartEntity[],
) => {
  if (other.length) {
    return ITrackingUserType.other;
  }

  if (!team.length) {
    return ITrackingUserType.media;
  }

  if (!media.length) {
    return ITrackingUserType.team;
  }

  if (media.length < team.length) {
    return ITrackingUserType.media;
  }
  return ITrackingUserType.team;
};
