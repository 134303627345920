import { FC } from 'react';

import { IHelpTextItem } from '../../../types';
import { createClassNames } from '../../../utils';
import './FunctionsItem.styles.scss';

export interface IFunctionsItemProps extends IHelpTextItem {}

const classNames = createClassNames('functions-item');

export const FunctionsItem: FC<IFunctionsItemProps> = ({ title, text }) => {
  const texts = text.split('<br>');

  return (
    <div className={classNames()}>
      <h4 className={classNames('title')}>{title}</h4>
      <div className={classNames('description')}>
        {texts.map((text, index) => (
          <span key={index} dangerouslySetInnerHTML={{ __html: text }}></span>
        ))}
      </div>
    </div>
  );
};
