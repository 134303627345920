import { SetURLSearchParams } from 'react-router-dom';

import {
  ICompetitionDetail,
  ICompetitionsDetailRecord,
  IDateRangeString,
  IGoalkeeperRecord,
  INetZone,
  INetZoneUnion,
  IPlayerRecord,
  ISeasonRecord,
  ISelectOption,
  ISelectedPlayerItem,
  ITeam,
  IUrlFilterParams,
  IUrlParam,
} from '../types';
import {
  filterGoalkeepers,
  filterGoalkeepersToOptions,
  filterPlayers,
  filterPlayersToOptions,
  filterTeamsToOptions,
} from './mainFilter.utils';
import { getPartsOptions } from './seasons.utils';

export const changeUrlSearchParamsMultiselectInput = (
  options: ISelectOption[],
  fieldName: string,
  setSearchParams: SetURLSearchParams,
  paramsToReset?: string[],
) => {
  const newSearchParams = options.map(option => option.value);
  if (newSearchParams.length > 0) {
    setSearchParams(params => {
      params.set(fieldName, newSearchParams.join(','));
      paramsToReset?.forEach(param => params.delete(param));
      return params;
    });
  } else {
    setSearchParams(params => {
      params.delete(fieldName);
      paramsToReset?.forEach(param => params.delete(param));
      return params;
    });
  }
};

export const changeUrlSearchParamsDateRange = (
  dateString: IDateRangeString | undefined,
  fieldName: string,
  setSearchParams: SetURLSearchParams,
) => {
  if (!dateString?.from) {
    setSearchParams(params => {
      params.delete(fieldName);
      return params;
    });
    return;
  }

  const newDate = dateString.to ? `${dateString.from},${dateString.to}` : dateString.from;
  setSearchParams(params => {
    params.set(fieldName, newDate);
    return params;
  });
};

export const normalizeUrlWithFilterParams = (
  pagesUrl: string,
  filterParams?: IUrlFilterParams[],
) => {
  if (filterParams && filterParams.length > 0) {
    const search = filterParams.reduce<string>(
      (acc, param) => `${acc}${param.name}=${param.value}&`,
      '?',
    );
    return `${pagesUrl}${search}`;
  }

  return pagesUrl;
};

export const combineSearchAndFilterParams = (
  searchParams: URLSearchParams,
  filterParams: IUrlFilterParams[] = [],
) => {
  const actualUrlFilterParams = Array.from(searchParams.entries()).map<IUrlFilterParams>(
    ([name, value]) => ({
      name,
      value,
    }),
  );

  const filteredActualUrlFilterParams = actualUrlFilterParams.filter(
    param => !filterParams.some(p => p.name === param.name),
  );

  return filterParams.concat(filteredActualUrlFilterParams);
};

// =========== start of parse url params ===========

export const parseUrlSelectedSeasons = (
  searchParams: URLSearchParams,
  byId: ISeasonRecord,
): ISelectOption[] => {
  const selectedSeasons = searchParams.get(IUrlParam.selectedSeasons);

  if (!selectedSeasons) return [];

  const existingSelectedSeasons = selectedSeasons.split(',').filter(seasonId => !!byId[seasonId]);

  return existingSelectedSeasons.map<ISelectOption>(seasonId => ({
    value: seasonId,
    label: byId[seasonId].name,
  }));
};

export const parseUrlSelectedParts = (
  searchParams: URLSearchParams,
  byId: ICompetitionsDetailRecord,
): ISelectOption[] => {
  const selectedParts = searchParams.get(IUrlParam.selectedParts);

  if (!selectedParts) return [];

  const partOptions = getPartsOptions(byId);

  const existingSelectedParts = selectedParts
    .split(',')
    .filter(part => partOptions.some(option => option.value === part));

  return existingSelectedParts.map<ISelectOption>(part => ({ value: part, label: part }));
};

export const parseUrlSelectedTeam = (
  searchParams: URLSearchParams,
  filteredTeams: ITeam[],
): ISelectOption | null | undefined => {
  const selectedTeam = searchParams.get(IUrlParam.selectedTeam);

  if (!selectedTeam) return undefined;

  const teamsOptions = filterTeamsToOptions(filteredTeams);
  return teamsOptions.find(option => option.value === selectedTeam);
};

export const parseUrlSelectedPlayer = (
  searchParams: URLSearchParams,
  byId: IPlayerRecord,
  filteredParts: ICompetitionDetail[],
  selectedTeamId: string | undefined,
): ISelectOption | null | undefined => {
  const selectedPlayer = searchParams.get(IUrlParam.selectedPlayer);

  if (!selectedPlayer) return undefined;

  const filteredPlayer = filterPlayers(byId, filteredParts, selectedTeamId);
  const playersOptions = filterPlayersToOptions(filteredPlayer);

  return playersOptions.find(option => option.value === selectedPlayer);
};

export const parseUrlSelectedGoalkeeper = (
  searchParams: URLSearchParams,
  byId: IGoalkeeperRecord,
  filteredParts: ICompetitionDetail[],
  selectedTeamId: string | undefined,
): ISelectOption | null | undefined => {
  const selectedGoalkeeper = searchParams.get(IUrlParam.selectedGoalkeeper);

  if (!selectedGoalkeeper) return undefined;

  const filteredGoalkeeper = filterGoalkeepers(byId, filteredParts, selectedTeamId);
  const goalkeepersOptions = filterGoalkeepersToOptions(filteredGoalkeeper);

  return goalkeepersOptions.find(option => option.value === selectedGoalkeeper);
};

export const parseUrlSelectedOpponents = (
  searchParams: URLSearchParams,
  filteredTeams: ITeam[],
  selectedTeamId: string | undefined,
): ISelectOption[] | undefined => {
  const selectedOpponents = searchParams.get(IUrlParam.selectedOpponents);

  if (!selectedOpponents) return undefined;

  const opponentsOptions = filterTeamsToOptions(filteredTeams).filter(
    team => team.value !== selectedTeamId,
  );
  return opponentsOptions.filter(option => selectedOpponents.split(',').includes(option.value));
};

export const parseUrlOpponentsAtGamesPage = (
  searchParams: URLSearchParams,
  filteredTeams: ITeam[],
  selectedTeamId: string | undefined,
): ISelectOption | null | undefined => {
  const opponentsAtGamesPage = searchParams.get(IUrlParam.opponentsAtGamesPage);

  if (!opponentsAtGamesPage) return undefined;

  const opponentsAtGamesPageOptions = filterTeamsToOptions(filteredTeams).filter(
    team => team.value !== selectedTeamId,
  );
  return opponentsAtGamesPageOptions.find(option => option.value === opponentsAtGamesPage);
};

export const parseUrlStaticSelectOption = (
  searchParams: URLSearchParams,
  paramName: string,
  options: ISelectOption[],
): ISelectOption | undefined => {
  const paramValue = searchParams.get(paramName);

  if (!paramValue) return undefined;

  return options.find(option => option.value === paramValue);
};

export const parseUrlStaticSelectOptionArray = (
  searchParams: URLSearchParams,
  paramName: string,
  options: ISelectOption[],
): ISelectOption[] | undefined => {
  const paramValue = searchParams.get(paramName);

  if (!paramValue) return undefined;

  return options.filter(option => paramValue.split(',').includes(option.value));
};

export const parseUrlNumberValue = (
  searchParams: URLSearchParams,
  paramName: string,
): number | string => {
  const paramValue = searchParams.get(paramName);

  if (!paramValue) return '';

  const number = Number(paramValue);

  return !isNaN(number) ? number : '';
};

export const parseUrlDateRange = (
  searchParams: URLSearchParams,
  paramName: string,
): IDateRangeString | undefined => {
  const paramValue = searchParams.get(paramName);

  if (!paramValue) return undefined;

  const fromTo = paramValue.split(',');

  const dateRange: IDateRangeString = {
    from: fromTo[0],
    to: fromTo[1],
  };

  return dateRange;
};

export const parseUrlSelectedPlayerItems = (
  searchParams: URLSearchParams,
  byId: IPlayerRecord,
  filteredParts: ICompetitionDetail[],
  selectedTeamId: string | undefined,
): ISelectedPlayerItem[] | undefined => {
  const paramValue = searchParams.get(IUrlParam.selectedPlayerItems);

  if (!paramValue) return undefined;

  const selectedPlayers = paramValue.split(',');

  const filteredPlayers = filterPlayers(byId, filteredParts, selectedTeamId);
  const playersOptions = filterPlayersToOptions(filteredPlayers);

  const selectedPlayerItems = selectedPlayers.map<ISelectedPlayerItem>((player, index) => {
    const [id, isActiveString] = player.split('_');

    const selectedPlayerOption = playersOptions.find(option => option.value === id);
    const isActive = isActiveString !== undefined ? isActiveString === 'true' : true;

    return {
      id: index.toString(),
      selectedPlayer: selectedPlayerOption,
      isActive: selectedPlayerOption ? isActive : undefined,
    };
  });

  return selectedPlayerItems;
};

export const parseUrlSelectedGames = (
  searchParams: URLSearchParams,
  gamesOptions: ISelectOption[],
): ISelectOption[] | undefined => {
  const selectedGames = searchParams.get(IUrlParam.selectedGames);

  if (!selectedGames) return undefined;

  const existingSelectedGames = selectedGames
    .split(',')
    .filter(game => gamesOptions.some(option => option.value === game));

  return existingSelectedGames.length > 0
    ? existingSelectedGames.map<ISelectOption>(game => ({ value: game, label: game }))
    : undefined;
};

export const parseUrlNetZoneArray = (
  searchParams: URLSearchParams,
): INetZoneUnion[] | undefined => {
  const paramValue = searchParams.get(IUrlParam.netZones);

  if (!paramValue) return undefined;

  return Object.keys(INetZone).filter(zone =>
    paramValue.split(',').includes(zone),
  ) as INetZoneUnion[];
};

// =========== end of parse url params ===========

export const normalizeUrlSelectOptionParamValue = (
  name: IUrlParam,
  value: string | undefined,
  defaultValue: string | null = 'all',
): IUrlFilterParams | undefined => (value && defaultValue !== value ? { name, value } : undefined);
