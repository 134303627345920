import { FC } from 'react';

import { useAppSelector } from '../../../../../../app/hooks';
import { Sticker } from '../../../../../../components';
import { selectPlayers } from '../../../../../../features';
import { ISelectedPlayerItem, ISimilarPlayerNameRecord } from '../../../../../../types';
import { createClassNames, getPlayerShortName } from '../../../../../../utils';
import './OnOffFormationsPlayers.styles.scss';

export interface IOnOffFormationsPlayersProps {
  selectedPlayerItems: ISelectedPlayerItem[];
  similarPlayerNames: ISimilarPlayerNameRecord;
}

const classNames = createClassNames('on-off-formations-players');

export const OnOffFormationsPlayers: FC<IOnOffFormationsPlayersProps> = ({
  selectedPlayerItems,
  similarPlayerNames,
}) => {
  const players = useAppSelector(selectPlayers);

  return (
    <div className={classNames()}>
      {Object.values(selectedPlayerItems).map(playerItem => {
        if (playerItem.selectedPlayer?.value === undefined) return null;
        const playerId = playerItem.selectedPlayer?.value;
        const playerInfo = players.byId[playerId];
        const playerShortName = getPlayerShortName(playerInfo, similarPlayerNames);

        return (
          <Sticker
            key={playerId}
            label={playerShortName}
            fontWeight='bold'
            color={playerItem.isActive ? 'on' : 'off'}
          />
        );
      })}
    </div>
  );
};
