import { useMultiselect } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { PlayIcon } from '../../../icons';
import {
  IGameEntity,
  IGameRecord,
  ISelectedPlayerItem,
  ITeamRecord,
  IUseMultiselectConfig,
} from '../../../types';
import { createClassNames } from '../../../utils';
import { Button } from '../../Button';
import { CheckboxButton, ICheckboxButtonProps } from '../../CheckboxButton';
import { GamesSelectItemContent } from '../GamesSelectItemContent';
import './GamesSelectList.styles.scss';

export type IGamesSelectListProps<TOption = undefined> = IUseMultiselectConfig<TOption> &
  Pick<ICheckboxButtonProps, 'color' | 'shouldCheckFullBody'> & {
    /** Object with loaded game data. */
    gameRecord: IGameRecord;
    /** Object with loaded team data. */
    teamRecord: ITeamRecord;
    /** Game entities (shots, assists, etc.) collection with game id. */
    gameEntities?: IGameEntity[];
    /** Is it content for heatmap tab? */
    isHeatmap?: boolean;
    /** Array of player ids to be separated from filtered entities */
    selectedPlayerItems?: ISelectedPlayerItem[];
    /** Event triggered after click on action part of item content (play icon, count circle). */
    onItemContentActionClick?: (gameId: string) => void;
    /** Function that opens video center modal with game entities */
    onPlaySelectedGamesVideos?: () => void;
  };

const classNames = createClassNames('games-select-list');

/** Renders games list and allows to multi select games, select all games or open video center. */
export const GamesSelectList = <TOption extends any = undefined>({
  options,
  selected,
  onChange,
  hasSelectAllOption,
  color,
  shouldCheckFullBody,
  gameRecord,
  teamRecord,
  gameEntities,
  selectedPlayerItems,
  isHeatmap,
  onItemContentActionClick,
  onPlaySelectedGamesVideos,
}: IGamesSelectListProps<TOption>) => {
  const { selectedAll, onChangeToggle, onChangeToggleSelectAll } = useMultiselect<TOption>({
    options,
    selected,
    onChange,
    hasSelectAllOption,
  });

  return (
    <div className={classNames()} data-testid='games-select-list'>
      {hasSelectAllOption && (
        <Button
          label={!selectedAll ? ITranslationKeys.selectAllGames : ITranslationKeys.deselectAllGames}
          primary
          variant={!selectedAll ? 'gray' : 'red'}
          onClick={onChangeToggleSelectAll}
          data-testid='games-select-list__select-all-button'
        />
      )}
      <div className={classNames('options-box')}>
        <div className={classNames('options-box__options')}>
          {options.map(option => {
            const isSelected = selected.some(item => item.value === option.value);
            const entity = gameEntities?.find(entity => entity.gameId === option.value);
            const game = gameRecord[option.value];

            return (
              <CheckboxButton
                key={option.value}
                checked={isSelected}
                onClick={() => onChangeToggle(option)}
                shouldContentBeColored
                {...{ color, shouldCheckFullBody }}
              >
                <GamesSelectItemContent
                  gameTeams={{
                    homeTeam: teamRecord[game.homeTeamId],
                    awayTeam: teamRecord[game.awayTeamId],
                  }}
                  date={new Date(game.date)}
                  isSelected={isSelected}
                  filteredEntities={entity}
                  selectedPlayerItems={selectedPlayerItems}
                  onActionClick={
                    onItemContentActionClick
                      ? () => onItemContentActionClick(option.value)
                      : undefined
                  }
                  isHeatmap={isHeatmap}
                />
              </CheckboxButton>
            );
          })}
        </div>
      </div>
      <Button
        label={ITranslationKeys.playSelectedMatches}
        disabled={selected.length === 0}
        iconComponent={<PlayIcon />}
        onClick={onPlaySelectedGamesVideos}
      />
    </div>
  );
};
