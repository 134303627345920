import { FC } from 'react';

import { IDashboardGame } from '../../types';
import {
  createClassNames,
  formatDateByLanguage,
  transformObjectKeysToKebabCase,
} from '../../utils';
import './DashboardGameButton.styles.scss';

export interface IDashboardGameButtonProps extends IDashboardGame {
  /** is game selected */
  isActive?: boolean;
  /** click callback */
  onClick?: () => void;
}

const classNames = createClassNames('dashboard-game-button');

/** UI component used in the dashboard to display game options. */
export const DashboardGameButton: FC<IDashboardGameButtonProps> = ({
  homeTeam,
  awayTeam,
  date,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          positive: homeTeam.score > awayTeam.score,
          isActive,
        }),
      })}
      onClick={onClick}
    >
      <p className={classNames('date')}>{formatDateByLanguage(new Date(date), 'P')}</p>
      <p className={classNames('teams')}>
        {homeTeam.shortcut} - {awayTeam.shortcut}
      </p>
      <p className={classNames('score', { positive: homeTeam.score > awayTeam.score })}>
        {homeTeam.score}:{awayTeam.score}
      </p>
      <div className={classNames('bar')}>
        <div
          className={classNames('bar--inner', { positive: homeTeam.score > awayTeam.score })}
          style={{ width: `${homeTeam.xW}%` }}
        ></div>
      </div>
      <p className={classNames('bar--label')}>
        xW: <span>{homeTeam.xW}%</span>
      </p>
    </div>
  );
};
