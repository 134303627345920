import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IGoalStatsBoxWrapperProps } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { GoalStatsBox } from '../GoalStatsBox/GoalStatsBox';
import './GoalStatsBoxWrapper.styles.scss';

const classNames = createClassNames('goal-stats-box-wrapper');

export const GoalStatsBoxWrapper: FC<IGoalStatsBoxWrapperProps> = ({
  goalStatsBoxItems,
  label,
  color = 'white',
  showHeader = true,
  disableBoxShadow = false,
  isHorizontal = false,
}) => {
  const { t } = useTranslation();
  const tableHeaders = Array.from(
    new Set(
      goalStatsBoxItems.flatMap(item =>
        item.items.filter(item => item.label).map(item => item.label),
      ),
    ),
  );

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          color,
          disableBoxShadow,
          isHorizontal,
        }),
      })}
      data-testid='goal-stats-box-wrapper'
    >
      {label && <span className={classNames('label')}>{label}</span>}
      <div className={classNames('tables')}>
        {goalStatsBoxItems.map((item, index) => (
          <div className={classNames('tables__table')} key={index}>
            {showHeader && ((!isHorizontal && index === 0) || isHorizontal) && (
              <div className={classNames('tables__table__labels')}>
                {tableHeaders.map(th => th && <span key={th}>{t(th)}</span>)}
              </div>
            )}
            <div className={classNames('tables__table__goal-stats-box')}>
              <GoalStatsBox {...item} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
