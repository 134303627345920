import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import {
  dataSettingsFilterAllDataSelector,
  selectDataSettingsFilter,
  selectDefinedDatasets,
  selectMetrics,
  setDataSettingsSelectedMetrics,
} from '../../../../../features';
import { ITranslationKeys } from '../../../../../i18n/types';
import {
  IDataset,
  IEntity,
  ILanguageType,
  ISelectOption,
  ITcmsItemProps,
} from '../../../../../types';
import { getMetricsOptions } from '../../../../../utils';
import {
  defineDatasetByActiveSubsection,
  getMetricsByActiveSection,
  mapDatasetGroupOptions,
  transformSectionDatasetToITcmsItemProps,
  transformSelectedMetricsToSelectOptions,
} from '../utils';

const definedTemplatesSectionKeys: ISelectOption[] = [
  {
    label: ITranslationKeys.playerTemplates,
    value: IEntity.players,
  },
  {
    label: ITranslationKeys.teamTemplates,
    value: IEntity.teams,
  },
  {
    label: ITranslationKeys.goalkeeperTemplates,
    value: IEntity.goalkeepers,
  },
  {
    label: ITranslationKeys.defaultTemplates,
    value: 'defaultTemplates',
  },
];

/**
 * Provides effects for DefinedTemplatesPage
 */
export const useDefinedTemplatesEffects = () => {
  const dispatch = useAppDispatch();

  const [activeSection, setActiveSection] = useState<IEntity>(
    definedTemplatesSectionKeys[0].value as IEntity,
  );
  const [activeLanguage, setActiveLanguage] = useState<keyof ILanguageType<string>>('cz');
  const [sectionTemplateOptions, setSectionTemplateOptions] = useState<ITcmsItemProps[]>([]);
  const [datasetGroupOptions, setDatasetGroupOptions] = useState<ISelectOption[]>([]);
  const [activeTemplate, setActiveTemplate] = useState<ITcmsItemProps>();
  const [itemToDelete, setItemToDelete] = useState<IDataset | undefined>();
  const [itemToEdit, setItemToEdit] = useState<IDataset | undefined>();
  const [addTemplate, setAddTemplate] = useState<boolean>(false);
  const [pageInitialized, setPageInitialized] = useState<boolean>(false);

  const { metrics } = useAppSelector(selectMetrics);
  const metricsBySection = getMetricsByActiveSection(activeSection, metrics);
  const { filteredBySectionedSubgroups, filteredByParameters, metricsPrefix } = useAppSelector(
    state => dataSettingsFilterAllDataSelector(state, metricsBySection),
  );
  const { selectedMetrics } = useAppSelector(selectDataSettingsFilter);
  const { definedDatasets } = useAppSelector(selectDefinedDatasets);

  const sectionDataset = useMemo(
    () => defineDatasetByActiveSubsection(activeSection, definedDatasets),
    [activeSection, definedDatasets],
  );
  const filteredMetricsOptions = useMemo(
    () => getMetricsOptions(filteredByParameters, metricsPrefix, null),
    [filteredByParameters, metricsPrefix],
  );

  // Renders list of templates for active section and sets selected metrics
  useEffect(() => {
    if (!sectionDataset || !metrics) return;

    const sectionDatasetOptions = transformSectionDatasetToITcmsItemProps(
      sectionDataset,
      activeLanguage,
      setItemToEdit,
      setItemToDelete,
    );
    setSectionTemplateOptions(sectionDatasetOptions);
    setDatasetGroupOptions(
      mapDatasetGroupOptions(activeLanguage, activeSection, definedDatasets, metrics),
    );

    if (!definedDatasets || !metricsBySection) return;

    const selectedMetrics = transformSelectedMetricsToSelectOptions(
      definedDatasets,
      metricsBySection,
      activeSection,
      activeTemplate,
    );
    dispatch(setDataSettingsSelectedMetrics(selectedMetrics));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguage, activeSection, sectionDataset, metrics, definedDatasets, metricsBySection]);

  // Selects the first template in list when page is initialized
  // Sets pageInitialized to true to prevent selecting the first template when list sorts
  useEffect(() => {
    if (sectionTemplateOptions.length === 0 || pageInitialized) return;
    setActiveTemplate(sectionTemplateOptions[0]);
    setPageInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionTemplateOptions]);

  // Selects the first template in list when activeSection is changed
  useEffect(() => {
    if (sectionTemplateOptions.length === 0) return;
    setActiveTemplate(sectionTemplateOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  return {
    activeLanguage,
    activeSection,
    addTemplate,
    activeTemplate,
    datasetGroupOptions,
    definedDatasets,
    definedTemplatesSectionKeys,
    filteredBySectionedSubgroups,
    filteredMetricsOptions,
    sectionTemplateOptions,
    itemToDelete,
    itemToEdit,
    metrics,
    sectionDataset,
    metricsBySection,
    selectedMetrics,
    setActiveLanguage,
    setActiveSection,
    setActiveTemplate,
    setAddTemplate,
    setSectionTemplateOptions,
    setDatasetGroupOptions,
    setItemToDelete,
    setItemToEdit,
  };
};
