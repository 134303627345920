import { Editor, Text, Transforms } from 'slate';

import { CustomEditor, CustomElement, CustomText } from '../../types';

// WYSIWYG
// Funkce pro přepínání tučného písma
export const toggleBoldMark = (editor: CustomEditor) => {
  const isActive = isBoldMarkActive(editor);
  Transforms.setNodes(
    editor,
    { bold: isActive ? undefined : true },
    { match: n => Text.isText(n), split: true },
  );
};

export const isBoldMarkActive = (editor: CustomEditor) => {
  const [match] = Array.from(
    Editor.nodes(editor, {
      match: n => Text.isText(n) && n.bold === true,
      universal: true,
    }),
  );

  return !!match;
};

// Funkce pro přepínání kurzívy
export const toggleItalicMark = (editor: CustomEditor) => {
  const isActive = isItalicMarkActive(editor);
  Transforms.setNodes(
    editor,
    { italic: isActive ? undefined : true },
    { match: n => Text.isText(n), split: true },
  );
};

export const isItalicMarkActive = (editor: CustomEditor) => {
  const [match] = Array.from(
    Editor.nodes(editor, {
      match: n => Text.isText(n) && n.italic === true,
      universal: true,
    }),
  );

  return !!match;
};

export const htmlToSlate = (html: string): CustomElement[] => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  const transformNode = (node: ChildNode): CustomText[] => {
    if (node.nodeType === Node.TEXT_NODE) {
      return [{ text: node.nodeValue || '' }];
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      if (element.tagName.toLowerCase() === 'b') {
        return Array.from(element.childNodes)
          .flatMap(transformNode)
          .map(textNode => ({
            ...textNode,
            bold: true,
          }));
      }
    }

    return [];
  };

  const children = tempDiv ? Array.from(tempDiv.childNodes).flatMap(transformNode) : [{ text: '' }];

  return [
    {
      type: 'paragraph',
      children,
    },
  ];
};

export const slateToHtml = (elements: CustomElement[]): string => {
  const transformNode = (node: CustomText): string => {
    if (node.bold) {
      return `<b>${node.text}</b>`;
    }

    return node.text;
  };

  return elements
    .flatMap(element => {
      if (element.type === 'paragraph') {
        const children = element.children.filter(child => child.text !== '');

        if (children.length > 0) {
          return children.map(transformNode).join('');
        }
        return undefined;
      }
      return undefined;
    })
    .filter((item): item is string => !!item)
    .join('<br>');
};

export const contentToSlateValue = (contents: string[]): CustomElement[] =>
  contents.map(content => htmlToSlate(content)).flatMap(value => value);

export const textToSlateValue = (text: string): CustomElement[] =>
  contentToSlateValue(text.split('<br>'));
