import { useState } from 'react';

import { changelog } from '../jsons';

export const useChangelog = () => {
  const [isChangelogOpen, setIsChangelogOpen] = useState(false);
  const lastVersion = Object.values(changelog)[0];

  const openChangelog = () => {
    setIsChangelogOpen(true);
  };

  const closeChangelog = () => {
    setIsChangelogOpen(false);
  };

  return {
    lastVersion,
    isChangelogOpen,
    openChangelog,
    closeChangelog,
  };
};
