import { useMemo } from 'react';

import {
  IAnalyticsType,
  ILogStackedBarChartData,
  ILogStackedBarChartUsers,
  IRoleLogAggregationDateRecord,
} from '../../../../../../types';

export const useChartData = (
  data: IRoleLogAggregationDateRecord | undefined,
  analyticsType: IAnalyticsType,
): ILogStackedBarChartData[] => {
  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    return Object.values(data)
      .map<ILogStackedBarChartData>(item => {
        const { date, team, media, other } = item;

        const teamTotal = team.reduce((acc, item) => acc + item.analytics[analyticsType], 0);
        const mediaTotal = media.reduce((acc, item) => acc + item.analytics[analyticsType], 0);
        const otherTotal = other.reduce((acc, item) => acc + item.analytics[analyticsType], 0);
        const total = teamTotal + mediaTotal + otherTotal;

        const users: ILogStackedBarChartUsers = {
          team: [...team].sort((a, b) => b.analytics[analyticsType] - a.analytics[analyticsType]),
          media: [...media].sort((a, b) => b.analytics[analyticsType] - a.analytics[analyticsType]),
          other: [...other].sort((a, b) => b.analytics[analyticsType] - a.analytics[analyticsType]),
        };

        return {
          date,
          team: teamTotal,
          media: mediaTotal,
          other: otherTotal,
          users,
          xAxisTickValue: `${total};${date}`,
        };
      })
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [data, analyticsType]);

  return chartData;
};
