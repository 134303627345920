import { FC, MouseEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Descendant } from 'slate';
import { Editable, Slate } from 'slate-react';

import { ITranslationKeys } from '../../../i18n/types';
import { WysiwygTextBoldIcon } from '../../../icons';
import { CustomEditor, CustomElement } from '../../../types';
import { createClassNames, toggleBoldMark } from '../../../utils';
import './Wysiwyg.styles.scss';

export interface IWysiwygProps {
  editor: CustomEditor;
  onContentChange: (newValue: CustomElement[]) => void;
  resetSelection?: boolean;
  contentValue?: CustomElement[];
}

const classNames = createClassNames('wysiwyg');

const initialValue: CustomElement[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const Wysiwyg: FC<IWysiwygProps> = ({ editor, contentValue, onContentChange }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setNewContentValue();
    editor.onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentValue]);

  const setNewContentValue = (newContentValue?: CustomElement[]) => {
    const value = newContentValue ?? contentValue;
    if (!value || value.length < 1) {
      editor.children = initialValue;
      return;
    }

    if (value.length > 1) {
      editor.children = value;
      return;
    }

    // This line solves an error when Slate has { children } with [] value normally resulting in error.
    editor.children = value[0].children.length > 0 ? value : initialValue;
  };

  // Function for rendering elements
  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case 'paragraph':
        return <p {...props.attributes}>{props.children}</p>;
      default:
        return <p {...props.attributes}>{props.children}</p>;
    }
  }, []);

  // Function for rendering text nodes
  const renderLeaf = useCallback((props: any) => {
    let { children } = props;

    if (props.leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (props.leaf.italic) {
      children = <em>{children}</em>;
    }

    return <span {...props.attributes}>{children}</span>;
  }, []);

  const handleToolbarButtonClick = (
    event: MouseEvent<HTMLButtonElement>,
    eventFunction: (editor: CustomEditor) => void,
  ) => {
    event.preventDefault();
    eventFunction(editor);
  };

  const handleOnChange = (newValue: Descendant[]) => onContentChange(newValue as CustomElement[]);

  return (
    <div className={classNames()}>
      <Slate editor={editor} initialValue={initialValue} onChange={handleOnChange}>
        <div className={classNames('toolbar')}>
          <button onClick={event => handleToolbarButtonClick(event, toggleBoldMark)}>
            <WysiwygTextBoldIcon />
          </button>
        </div>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={t(ITranslationKeys.enterText).toString()}
        />
      </Slate>
    </div>
  );
};
