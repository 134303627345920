import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { trendTimePeriodTypeOptions } from '../../constants';
import {
  ISelectOption,
  ITimePeriodDataBy,
  ITimePeriodPlayerStatsRecord,
  ITrendState,
} from '../../types';
import { getDefaultPredefinedOptionByValue } from '../../utils';
import {
  getTimePeriodFormationStats,
  getTimePeriodGoalkeeperStats,
  getTimePeriodPlayerStats,
  getTrendFormationStats,
  getTrendGoalkeeperStats,
  getTrendPlayerStats,
  getTrendTeamStats,
} from './trendActions';

const DEFAULT_TIME_PERIOD_PLAYERS_STATS_RECORD: ITimePeriodPlayerStatsRecord = {
  0: {
    rowIndex: 0,
    stats: {},
    isRowLoading: false,
  },
};

const initialState: ITrendState = {
  trend: [],
  trendGoalkeeperId: undefined,
  trendPlayerId: undefined,
  trendTeamId: undefined,
  isLoading: false,
  error: undefined,
  selectedMetric: undefined,
  tableDataBy: 'playerMatches',
  tableTimePeriodType: getDefaultPredefinedOptionByValue(trendTimePeriodTypeOptions, '10games'),
  tableTimePeriodPlayerStats: DEFAULT_TIME_PERIOD_PLAYERS_STATS_RECORD,
};

export const trendSlice = createSlice({
  name: 'trend',
  initialState,
  reducers: {
    setSelectedTrendMetric: (state, action: PayloadAction<ISelectOption | undefined>) => {
      state.selectedMetric = action.payload;
    },
    setTrendTableDataBy: (state, action: PayloadAction<ITimePeriodDataBy | undefined>) => {
      state.tableDataBy = action.payload;
    },
    setTrendTableTimePeriodType: (state, action: PayloadAction<ISelectOption | undefined>) => {
      state.tableTimePeriodType = action.payload;
    },
    setTrendTableDataRows: (state, action: PayloadAction<number>) => {
      const newRows: ITimePeriodPlayerStatsRecord = {};
      const rowCount = action.payload || 1;

      for (let i = 0; i < rowCount; i++) {
        newRows[i] = {
          rowIndex: i,
          stats: {},
          isRowLoading: false,
        };
      }

      state.tableTimePeriodPlayerStats = newRows;
    },
    addTrendTableDataRow: state => {
      const newRowIndex = Object.values(state.tableTimePeriodPlayerStats).length;

      state.tableTimePeriodPlayerStats[newRowIndex] = {
        rowIndex: newRowIndex,
        stats: {},
        isRowLoading: false,
      };
    },
    deleteTrendTableDataRow: state => {
      const deleteRowIndex = Object.values(state.tableTimePeriodPlayerStats).length - 1;

      if (deleteRowIndex > 0) {
        delete state.tableTimePeriodPlayerStats[deleteRowIndex];
      } else {
        state.tableTimePeriodPlayerStats = DEFAULT_TIME_PERIOD_PLAYERS_STATS_RECORD;
      }
    },
    resetTableTimePeriodPlayerStats: state => {
      state.tableTimePeriodPlayerStats = DEFAULT_TIME_PERIOD_PLAYERS_STATS_RECORD;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTrendPlayerStats.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getTrendPlayerStats.fulfilled, (state, action) => {
        state.trend = action.payload;
        state.trendPlayerId = action.meta.arg.playerUuid;
        state.trendTeamId = action.meta.arg.teamUuid;
        state.isLoading = false;
      })
      .addCase(getTrendPlayerStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getTrendGoalkeeperStats.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getTrendGoalkeeperStats.fulfilled, (state, action) => {
        state.trend = action.payload;
        state.trendGoalkeeperId = action.meta.arg.goalkeeperUuid;
        state.trendTeamId = action.meta.arg.teamUuid;
        state.isLoading = false;
      })
      .addCase(getTrendGoalkeeperStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getTrendFormationStats.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getTrendFormationStats.fulfilled, (state, action) => {
        state.trend = action.payload;
        state.trendTeamId = action.meta.arg.teamUuid;
        state.isLoading = false;
      })
      .addCase(getTrendFormationStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getTrendTeamStats.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getTrendTeamStats.fulfilled, (state, action) => {
        state.trend = action.payload;
        state.trendTeamId = action.meta.arg.teamUuid;
        state.isLoading = false;
      })
      .addCase(getTrendTeamStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getTimePeriodFormationStats.fulfilled, (state, action) => {
        const { payload, meta } = action;

        if (!state.tableTimePeriodPlayerStats[meta.arg.rowIndex]) {
          state.tableTimePeriodPlayerStats[meta.arg.rowIndex] = {
            rowIndex: meta.arg.rowIndex,
            stats: {},
            isRowLoading: false,
          };
        } else {
          state.tableTimePeriodPlayerStats[meta.arg.rowIndex].stats = payload || {};
          state.tableTimePeriodPlayerStats[meta.arg.rowIndex].isRowLoading = false;
        }
      })
      // Next action case
      .addMatcher(
        isAnyOf(
          getTimePeriodPlayerStats.pending,
          getTimePeriodGoalkeeperStats.pending,
          getTimePeriodFormationStats.pending,
        ),
        (state, action) => {
          state.tableTimePeriodPlayerStats[action.meta.arg.rowIndex].isRowLoading = true;
          state.error = undefined;
        },
      )
      .addMatcher(
        isAnyOf(getTimePeriodPlayerStats.fulfilled, getTimePeriodGoalkeeperStats.fulfilled),
        (state, action) => {
          const { payload, meta } = action;

          const exists = !!state.tableTimePeriodPlayerStats[meta.arg.rowIndex];

          if (exists) {
            state.tableTimePeriodPlayerStats[meta.arg.rowIndex].stats = payload || {};
            state.tableTimePeriodPlayerStats[meta.arg.rowIndex].isRowLoading = false;
          }
        },
      )
      .addMatcher(
        isAnyOf(
          getTimePeriodPlayerStats.rejected,
          getTimePeriodGoalkeeperStats.rejected,
          getTimePeriodFormationStats.rejected,
        ),
        (state, action) => {
          if (state.tableTimePeriodPlayerStats[action.meta.arg.rowIndex]) {
            state.tableTimePeriodPlayerStats[action.meta.arg.rowIndex].isRowLoading = false;
          }
          state.error = action.error.message;
        },
      );
  },
});

export const {
  setSelectedTrendMetric,
  setTrendTableDataBy,
  setTrendTableTimePeriodType,
  setTrendTableDataRows,
  addTrendTableDataRow,
  deleteTrendTableDataRow,
  resetTableTimePeriodPlayerStats,
} = trendSlice.actions;

export const trendReducer = trendSlice.reducer;
