import { FC } from 'react';

import { createClassNames } from '../../utils';
import './RatioBar.styles.scss';

export interface IRatioBarProps {
  /** Label for the bar */
  label?: string;
  /** Number (not percent) value representing left side of bar*/
  valueLeft: number;
  /**  Number (not percent) value representing right side of bar */
  valueRight: number;
  /** Toggles if values are displayed as fixed percentage or regular number */
  showPercent: boolean;
}

const classNames = createClassNames('ratio-bar');

/**
 * UI component for visualization of 2 values ratio
 */
export const RatioBar: FC<IRatioBarProps> = ({ label, valueLeft, valueRight, showPercent }) => {
  const total = valueLeft + valueRight;

  // Safety check for division by zero
  const percentLeft = total === 0 ? 50 : (valueLeft / total) * 100;
  const percentRight = total === 0 ? 50 : (valueRight / total) * 100;

  return (
    <div className={classNames('container')}>
      {label && <p className={classNames('label')}>{label}</p>}
      <div className={classNames()}>
        <div className={classNames('bar', { left: true })} style={{ width: `${percentLeft}%` }}>
          <p className={classNames('value')}>
            {showPercent ? `${Number(percentLeft.toFixed(2))}%` : valueLeft}
          </p>
        </div>
        <div className={classNames('bar', { right: true })} style={{ width: `${percentRight}%` }}>
          <p className={classNames('value')}>
            {showPercent ? `${Number(percentRight.toFixed(2))}%` : valueRight}
          </p>
        </div>
      </div>
    </div>
  );
};
