import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortEndHandler } from 'react-sortable-hoc';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import {
  ConfirmDialog,
  Modal,
  TcmsItemActions,
  TcmsSortableList,
} from '../../../../../../components';
import {
  deleteHelpCategoryItem,
  putHelpScreenConfig,
  selectHelp,
} from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { TcmsPlusIcon } from '../../../../../../icons';
import {
  IHelpCategory,
  IHelpItem,
  IHelpScreenConfig,
  ILanguageType,
  ISelectOption,
} from '../../../../../../types';
import { createClassNames, moveArrayItem } from '../../../../../../utils';
import './HelpManagementSectionParts.styles.scss';

export interface IHelpManagementSectionPartsProps {
  screenId: string;
  activeHelpCategoryType: ISelectOption | null;
  activeSubsection: ISelectOption | null;
  activeLanguage: keyof ILanguageType<string>;
  selectedListOption: ISelectOption | null;
  onSelectedListOptionChange: (newOption: ISelectOption) => void;
  onAddItemClick: () => void;
}

const classNames = createClassNames('help-management-section-parts');

export const HelpManagementSectionParts: FC<IHelpManagementSectionPartsProps> = ({
  screenId,
  activeHelpCategoryType,
  activeSubsection,
  activeLanguage,
  selectedListOption,
  onSelectedListOptionChange,
  onAddItemClick,
}) => {
  const { help, helpConfig } = useAppSelector(selectHelp);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [optionToDelete, setOptionToDelete] = useState<ISelectOption>();
  const [listOptions, setListOptions] = useState<ISelectOption[]>([]);

  const screenConfig = helpConfig[screenId];
  const screenConfigContentIds =
    activeHelpCategoryType?.value && screenConfig
      ? screenConfig[activeHelpCategoryType.value as keyof IHelpScreenConfig]
      : [];

  useEffect(() => {
    if (activeHelpCategoryType) {
      const list = createListOptions(
        help[activeHelpCategoryType.value as keyof IHelpScreenConfig],
        screenConfigContentIds,
        activeLanguage,
      );
      setListOptions(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHelpCategoryType, activeLanguage, help, helpConfig, screenId, activeSubsection]);

  if (!activeHelpCategoryType) {
    return (
      <div className={classNames()}>
        <div className='content-info-box'>{t(ITranslationKeys.noDataForSelected)}</div>
      </div>
    );
  }

  const handleOnListSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newArray = [...listOptions];

      moveArrayItem(newArray, oldIndex, newIndex);
      setListOptions(newArray);

      const sortedContentIds = newArray
        .map(item => item.value)
        .filter(item => screenConfigContentIds.includes(item));
      const hasSameOrder = screenConfigContentIds.every(
        (value, index) => value === sortedContentIds[index],
      );

      if (hasSameOrder) return;

      dispatch(
        putHelpScreenConfig({
          id: screenId,
          body: {
            ...screenConfig,
            [activeHelpCategoryType.value as keyof IHelpScreenConfig]: sortedContentIds,
          },
        }),
      );
    }
  };

  const handleConfirmDeleteDialog = () => {
    if (!optionToDelete) return;

    const hasAlreadyContentId = screenConfigContentIds.some(
      contentId => contentId === optionToDelete.value,
    );
    if (hasAlreadyContentId) {
      const newContentIds = screenConfigContentIds.filter(
        contentId => contentId !== optionToDelete.value,
      );
      dispatch(
        putHelpScreenConfig({
          id: screenId,
          body: {
            ...screenConfig,
            [activeHelpCategoryType.value as keyof IHelpScreenConfig]: newContentIds,
          },
        }),
      );
    }

    dispatch(
      deleteHelpCategoryItem({
        id: optionToDelete.value,
        category: activeHelpCategoryType.value as IHelpCategory,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(t(ITranslationKeys.itemDeletedSuccessfully), {
          toastId: ITranslationKeys.itemDeletedSuccessfully,
        });
        handleCloseDeleteDialog();
      })
      .catch(error => {
        toast.error(t(ITranslationKeys.itemDeleteFailed), {
          toastId: ITranslationKeys.itemDeleteFailed,
        });
        handleCloseDeleteDialog();
        console.error('[HelpManagementSectionParts]: Delete help content item error:', error);
      });
  };

  const handleOnItemDisableClick = (option: ISelectOption) => {
    const hasAlreadyContentId = screenConfigContentIds.some(
      contentId => contentId === option.value,
    );
    const newContentIds = hasAlreadyContentId
      ? screenConfigContentIds.filter(contentId => contentId !== option.value)
      : [...screenConfigContentIds, option.value];

    dispatch(
      putHelpScreenConfig({
        id: screenId,
        body: {
          ...screenConfig,
          [activeHelpCategoryType.value as keyof IHelpScreenConfig]: newContentIds,
        },
      }),
    );
  };

  const handleCloseDeleteDialog = () => {
    setOptionToDelete(undefined);
  };

  const handleOpenDeleteDialog = (option: ISelectOption) => {
    setOptionToDelete(option);
  };

  return (
    <div className={classNames()}>
      <h3>{activeSubsection ? t(activeSubsection.label) : ''}</h3>
      <TcmsSortableList
        selected={listOptions.find(option => option.value === selectedListOption?.value)}
        options={listOptions}
        onItemClick={onSelectedListOptionChange}
        onSortEnd={handleOnListSortEnd}
        itemChildrenRenderer={option => (
          <TcmsItemActions
            item={option}
            isItemChecked={screenConfigContentIds.includes(option.value)}
            onItemEditClick={onSelectedListOptionChange}
            onItemRemoveClick={handleOpenDeleteDialog}
            onItemDisableClick={handleOnItemDisableClick}
          />
        )}
        helperClass='sortable-helper'
        lockAxis='y'
        useDragHandle
      />
      {activeHelpCategoryType.value !== 'main' && (
        <div className={classNames('add-button')} onClick={onAddItemClick}>
          <TcmsPlusIcon />
          {t(ITranslationKeys.addAnotherItem)}
        </div>
      )}
      <Modal open={!!optionToDelete} size='small'>
        <ConfirmDialog
          onConfirm={handleConfirmDeleteDialog}
          onCancel={handleCloseDeleteDialog}
          message={t(ITranslationKeys.areYouSureDeleteItem)}
          type='danger'
        />
      </Modal>
    </div>
  );
};

const createListOptions = (
  helpItems: IHelpItem[],
  screenConfigContentIds: string[],
  activeLanguage: keyof ILanguageType<string>,
) => {
  if (!helpItems) {
    return [];
  }

  const activeHelpItems = screenConfigContentIds
    .map(id => helpItems.find(item => item.id === id))
    .filter((item): item is IHelpItem => !!item);
  const otherHelpItems = helpItems.filter(item => !screenConfigContentIds.includes(item.id));

  return [...activeHelpItems, ...otherHelpItems].map<ISelectOption>(item => ({
    value: item.id,
    label: item[activeLanguage].title,
  }));
};
