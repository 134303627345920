import { Bar, Rectangle } from 'recharts';

import { COLORS_TCMS } from '../../constants';
import { ITranslationKeys } from '../../i18n/types';
import { IBarMouseEnterData, ITrackingUserType } from '../../types';

interface IRenderRectangleSettings {
  color?: string;
  hasTopRadius?: boolean;
  activeColor?: string;
  layout?: 'horizontal' | 'vertical';
}

export const renderRectangle = (props: any, settings: IRenderRectangleSettings) => {
  const { color, hasTopRadius = true, activeColor, layout = 'horizontal' } = settings;
  const radius = layout === 'vertical' ? [0, 6, 6, 0] : [6, 6, 0, 0];

  return (
    <Rectangle
      {...props}
      radius={hasTopRadius ? radius : [0, 0, 0, 0]}
      fill={activeColor || color}
    />
  );
};

export const getChartGradientDef = () => (
  <defs>
    <linearGradient id='chart-gradient' x1='0' x2='0' y1='0%' y2='100%'>
      <stop offset='0%' stopColor={COLORS_TCMS.white.base} />
      <stop offset='80%' stopColor={COLORS_TCMS.white.base} stopOpacity={0.5} />
      <stop offset='100%' stopColor={COLORS_TCMS.slate[200]} stopOpacity={0.5} />
    </linearGradient>
  </defs>
);

export const getTeamMediaOtherStackedBars = (
  layout: 'horizontal' | 'vertical' = 'horizontal',
  onBarMouseEnter?: (data: IBarMouseEnterData) => void,
  onBarMouseLeave?: (data: IBarMouseEnterData) => void,
) => (
  <>
    <Bar
      dataKey={ITrackingUserType.other}
      name={ITranslationKeys.others}
      stackId='a'
      fill={COLORS_TCMS.cyan[500]}
      onMouseEnter={data => onBarMouseEnter?.(data as IBarMouseEnterData)}
      onMouseLeave={data => onBarMouseLeave?.(data as IBarMouseEnterData)}
      shape={(props: any) =>
        renderRectangle(props, {
          color: COLORS_TCMS.cyan[500],
          hasTopRadius: props.team === 0 && props.media === 0,
          layout,
        })
      }
      activeBar={(props: any) =>
        renderRectangle(props, {
          color: COLORS_TCMS.cyan[600],
          hasTopRadius: props.team === 0 && props.media === 0,
          layout,
        })
      }
    />
    <Bar
      dataKey={ITrackingUserType.media}
      stackId='a'
      fill={COLORS_TCMS.fuchsia[600]}
      onMouseEnter={data => onBarMouseEnter?.(data as IBarMouseEnterData)}
      onMouseLeave={data => onBarMouseLeave?.(data as IBarMouseEnterData)}
      shape={(props: any) =>
        renderRectangle(props, {
          color: COLORS_TCMS.fuchsia[600],
          hasTopRadius: props.team === 0,
          layout,
        })
      }
      activeBar={(props: any) =>
        renderRectangle(props, {
          color: COLORS_TCMS.fuchsia[700],
          hasTopRadius: props.team === 0,
          layout,
        })
      }
    />
    <Bar
      dataKey={ITrackingUserType.team}
      name={ITranslationKeys.teams}
      stackId='a'
      fill={COLORS_TCMS.blue[700]}
      onMouseEnter={data => onBarMouseEnter?.(data as IBarMouseEnterData)}
      onMouseLeave={data => onBarMouseLeave?.(data as IBarMouseEnterData)}
      shape={(props: any) =>
        renderRectangle(props, {
          color: COLORS_TCMS.blue[700],
          layout,
        })
      }
      activeBar={(props: any) =>
        renderRectangle(props, {
          color: COLORS_TCMS.blue[800],
          layout,
        })
      }
    />
  </>
);
