import { CellContext } from '@tanstack/react-table';
import { ChangeEvent, FC } from 'react';
import { DateRange } from 'react-day-picker';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/hooks';
import { trendTimePeriodCustomOption } from '../../../constants';
import { setTrendTableTimePeriodType } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import {
  IEntity,
  IMultiParamsVoid,
  ISelectOption,
  ITimePeriodDataBy,
  ITimePeriodTableForm,
} from '../../../types';
import {
  createClassNames,
  formatNumberValueForForm,
  parseDateToStringDateRange,
  parseStringToDateDateRange,
} from '../../../utils';
import { DateRangePicker } from '../../DateRangePicker';
import { Input } from '../../Input';
import './TimePeriodColumn.styles.scss';

export interface ITimePeriodColumnHeaderProps {
  dataBy: ITimePeriodDataBy | undefined;
  entity?: IEntity;
}

export interface ITimePeriodColumnCellProps<TData> extends CellContext<TData, number> {
  control: Control<ITimePeriodTableForm, any>;
  dataLength: number;
  dataBy: ITimePeriodDataBy | undefined;
  timePeriodType: ISelectOption | undefined;
}

const classNames = createClassNames('time-period-column-header');

export const TimePeriodColumnHeader: FC<ITimePeriodColumnHeaderProps> = ({ dataBy, entity }) => {
  const { t } = useTranslation();

  return <div className={classNames()}>{t(getTimePeriodColumnHeaderLabel(dataBy, entity))}</div>;
};

const classNamesCell = createClassNames('time-period-column-cell');

export const TimePeriodColumnCell = <TData extends unknown>({
  getValue,
  control,
  dataLength,
  dataBy,
  timePeriodType,
}: ITimePeriodColumnCellProps<TData>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const rowIndex = getValue();

  const handleChangeTimePeriodByGames = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: IMultiParamsVoid,
  ) => {
    const { target } = event;
    const newValue = target.valueAsNumber > dataLength ? dataLength : target.valueAsNumber;
    onChange(formatNumberValueForForm(newValue));

    if (timePeriodType && timePeriodType.value !== 'custom') {
      dispatch(setTrendTableTimePeriodType(trendTimePeriodCustomOption));
    }
  };

  const handleChangeDateFromTo = (value: DateRange | undefined, onChange: IMultiParamsVoid) => {
    onChange(parseDateToStringDateRange(value));
  };

  return (
    <div className={classNamesCell()}>
      {dataBy !== 'calendar' ? (
        <div className={classNamesCell('by-games')}>
          <Controller
            name={`timePeriodByGames.${rowIndex}.from`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                type='number'
                variant='filter'
                disableAppearance
                min={0}
                max={dataLength}
                value={formatNumberValueForForm(value)}
                placeholder={t(ITranslationKeys.from) ?? ''}
                onChange={event => handleChangeTimePeriodByGames(event, onChange)}
              />
            )}
          />
          <Controller
            name={`timePeriodByGames.${rowIndex}.to`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                type='number'
                variant='filter'
                disableAppearance
                min={0}
                max={dataLength}
                value={formatNumberValueForForm(value)}
                placeholder={t(ITranslationKeys.to) ?? ''}
                onChange={event => handleChangeTimePeriodByGames(event, onChange)}
              />
            )}
          />
        </div>
      ) : (
        <div className={classNamesCell('by-date')}>
          <Controller
            name={`timePeriodByDate.${rowIndex}`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateRangePicker
                value={parseStringToDateDateRange(value)}
                onChange={(value: DateRange | undefined) => handleChangeDateFromTo(value, onChange)}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

const getTimePeriodColumnHeaderLabel = (
  tableDataBy: ITimePeriodDataBy | undefined,
  entity?: IEntity,
) => {
  if (!tableDataBy) {
    if (entity === IEntity.goalkeepers) return ITranslationKeys.timePeriodByGoalkeeperMatches;
    if (entity === IEntity.teams) return ITranslationKeys.timePeriodByTeamMatches;
    return ITranslationKeys.timePeriodByPlayerMatches;
  }

  if (tableDataBy === 'teamMatches') {
    return ITranslationKeys.timePeriodByTeamMatches;
  }

  if (tableDataBy === 'calendar') {
    return ITranslationKeys.timePeriodByCalendar;
  }

  if (entity === IEntity.goalkeepers) return ITranslationKeys.timePeriodByGoalkeeperMatches;
  if (entity === IEntity.teams) return ITranslationKeys.timePeriodByTeamMatches;
  return ITranslationKeys.timePeriodByPlayerMatches;
};
